import { format } from 'date-fns';
import { Workbook } from 'exceljs';
import React, { useEffect } from 'react';
import * as fs from 'file-saver';
import { useMutation } from 'react-query';
import axios from 'axios';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { ButtonCustom } from '../../../../common/button/button';

const ButtonGenerateExcelRib = ({ airline, list }) => {
  const title = (char) => {
    if (airline) {
      return `Demandes de RIB CARPA - ${airline} - ${format(new Date(), `dd${char}MM${char}yyyy`)}`;
    }
    return `Demandes de RIB CARPA - ${format(new Date(), `dd${char}MM${char}yyyy`)}`;
  };
  const postIdClaimRequest = async (claimIds) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/claim/getByIds`,
      {
        claimIds: claimIds.map((c) => c._id),
      },
    );
    return data;
  };

  const { mutate: getClaims, data: claims } = useMutation(postIdClaimRequest);

  const DataExcelRib = (data) => data.map((e) => ({
    customer: `${e.passengers.find((p) => p.principal).lastName} ${e.passengers.find((p) => p.principal).firstName}`,
    opponent: e.ca_name_operated,
    nature: 'Responsabilité',
    amount: e.compensation_total_ca,
    transmitter: e.ca_name_operated,
    quality: 'Adversaire',
    source_of_funds: 'France',
  }));

  const downloadExcel = (data, title) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(title('-'));
    const header = ['Client/Vendeur', 'Adversaire/Acquéreur', 'Nature', 'Montant', 'Nom de l\'émetteur', 'Qualité de l\'émetteur', 'Provenance des fonds'];
    worksheet.getRow(3).values = header;
    const fName = title('/');

    worksheet.getRow(1).font = {
      size: 16,
      bold: true,
      color: { argb: 'FFFFFFFF' },
    };

    worksheet.getRow(3).alignment = { vertical: 'top', horizontal: 'left' };

    worksheet.getRow(3).font = {
      size: 14,
      bold: true,
    };

    worksheet.getCell('A1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF0052CC' },
    };

    worksheet.columns = [
      { width: 24 },
      { width: 24 },
      { width: 17 },
      { width: 11 },
      { width: 24 },
      { width: 24 },
      { width: 24 },
    ];
    for (const x1 of data) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of x2) {
        temp.push(x1[y]);
      }
      worksheet.addRow(temp);
    }

    worksheet.getColumn(4).numFmt = '#,##0.00" €";[Red]-#,##0.00" €"';

    worksheet.mergeCells('A1', 'G1');
    worksheet.getCell('A1').value = title('/');

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, `${fName}-${new Date().valueOf()}.xlsx`);
    });
  };

  useEffect(() => {
    if (claims && 'data' in claims) {
      downloadExcel(DataExcelRib(claims.data), title);
    }
  }, [claims]);

  return (
    <ButtonCustom
      disabled={list.length === 0}
      background="lightBlue"
      text="Excel CARPA"
      startIcon={<AccountBalanceIcon />}
      onClick={() => getClaims(list)}
    />
  );
};

export {
  ButtonGenerateExcelRib,
};
