export const TRIGGER_SEARCH_DRAWER = 'TRIGGER_SEARCH_DRAWER';
export const SEARCH_REQUEST = 'SEARCH_REQUEST_REQUEST';
export const SEARCH_CLAIM_REQUEST = 'SEARCH_CLAIM_REQUEST';
export const SEARCH_RESPONSE = 'SEARCH_REQUEST_RESPONSE';
export const SEARCH_REQUEST_ERRORS = 'SEARCH_REQUEST_ERRORS';

export const triggerSearchDrawer = () => ({
  type: TRIGGER_SEARCH_DRAWER,
});

export const searchRequest = (query) => ({
  type: SEARCH_REQUEST,
  payload: query,
});

export const searchClaimRequest = (query) => ({
  type: SEARCH_CLAIM_REQUEST,
  payload: query,
});

export const searchResponse = (response) => ({
  type: SEARCH_RESPONSE,
  payload: response,
});

export const search_errors = (errors) => ({
  type: SEARCH_REQUEST_ERRORS,
  payload: errors,
});
