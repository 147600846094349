import styled from 'styled-components';

export const HeaderImg = styled.img.attrs({
  src: 'https://mycowork.fr/wp-content/uploads/2016/01/12313610_1055480694496610_3014947793050728283_n-200x200.png',
})`
width: ${(props) => props.size};
height: ${(props) => props.size};
margin-bottom: ${(props) => props.marginBottom};
z-index: 1;
`;
