import { uniqBy } from 'lodash';
import {
  ADD_CONVOCATION_REQUEST,
  ADD_CONVOCATION_RESPONSE,
  ADD_DATE_TIME,
  REMOVE_PASSENGER,
  SEARCH_REQUEST_CONVOCATION,
  SEARCH_REQUEST_ERRORS_CONVOCATION,
  SEARCH_RESPONSE_CONVOCATION,
  SELECT_JURISDICTION,
  SELECT_PASSENGER,
  UPDATE_PASSENGER_FIELD,
  ADD_TYPE,
  CLEAR_CONVOCATION,
  UPDATE_CONVOCATION,
  UPDATE_CONVOCATION_RESPONSE,
  UPDATE_CONVOCATION_ERROR,
  ADD_JURISTS_CONVOCATION, DISMISS_ERROR_CONVOCATION,
} from './convocation.actions';

const { v4: uuidv4 } = require('uuid');

const initialConvocationState = {
  loading: false,
  errors: [],
  data: [],
  value: '',
  selected: [],
  jurisdiction: null,
  datetime: new Date(),
  type: null,
  jurists: [],
  success: null,
  redirect: false,
};

export const convocationReducer = (state = initialConvocationState, action) => {
  switch (action.type) {
    case CLEAR_CONVOCATION:
      return {
        ...initialConvocationState,
        redirect: false,
      };

    case ADD_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case SEARCH_REQUEST_CONVOCATION:
      return {
        ...state,
        loading: true,
        value: action.payload,
      };

    case SEARCH_RESPONSE_CONVOCATION:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case SEARCH_REQUEST_ERRORS_CONVOCATION:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case SELECT_PASSENGER:
      const passengers = uniqBy([...state.selected, action.payload], 'passenger._id');
      return {
        ...state,
        selected: passengers,
      };

    case REMOVE_PASSENGER:
      return {
        ...state,
        selected: state.selected.filter(({ passenger }) => passenger._id !== action.payload),
      };

    case UPDATE_PASSENGER_FIELD:
      const before = [...state.selected];
      const selected = before.map((props) => {
        if (props.passenger._id === action.payload._id) {
          return {
            ...props,
            passenger: {
              ...props.passenger,
              [action.payload.field]: action.payload.value,
            },
          };
        }
        return { ...props };
      });
      return {
        ...state,
        selected,
      };

    case SELECT_JURISDICTION:
      return {
        ...state,
        jurisdiction: action.payload,
      };

    case ADD_CONVOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ADD_CONVOCATION_RESPONSE:
      const errors = [
        ...state.errors,
      ];

      const id = uuidv4();
      errors.unshift({
        created: Date.now(),
        id,
        key: id,
        title: 'Convocation ajouté avec succès!',
        appearance: 'success',
      });

      return {
        ...state,
        loading: false,
        errors,
        data: [],
        value: '',
        selected: [],
        jurisdiction: null,
        datetime: null,
      };

    case ADD_DATE_TIME:
      return {
        ...state,
        datetime: action.payload,
      };

    case ADD_JURISTS_CONVOCATION:
      return {
        ...state,
        jurists: action.payload,
      };

    case UPDATE_CONVOCATION:
      return {
        ...state,
        loading: true,
        redirect: false,
      };

    case DISMISS_ERROR_CONVOCATION:
      return {
        ...state,
        errors: state.errors.slice(1),
      };

    case UPDATE_CONVOCATION_RESPONSE: {
      const errors = [
        ...state.errors,
      ];

      const id = uuidv4();
      errors.unshift({
        created: Date.now(),
        id,
        key: id,
        title: 'Convocation mise à jour avec succès !',
        appearance: 'success',
      });

      return {
        ...state,
        redirect: true,
        errors,
      };
    }

    case UPDATE_CONVOCATION_ERROR: {
      console.log(UPDATE_CONVOCATION_ERROR, action.payload);
      const errors = [
        ...state.errors,
      ];

      const id = uuidv4();
      errors.unshift({
        created: Date.now(),
        id,
        key: id,
        title: 'Impossible d\'ajouter la convocation ! Veuillez réessayer ultérieurement !',
        appearance: 'warn',
      });

      return {
        ...state,
        errors,
      };
    }

    default:
      return state;
  }
};
