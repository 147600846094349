import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { LoginContainer } from './LoginContainer';
import { clearUserState, loginUserRequest } from '../../../usescases/login/user.action';
import {
  selectErrorsLogin,
  selectLoadingUser,
  selectLoginSuccess,
  selectToken,
  selectUser,
} from '../../../usescases/login/user.selector';

const mapStateToProps = (state) => ({
  loading: selectLoadingUser(state),
  user: selectUser(state),
  loginSuccess: selectLoginSuccess(state),
  errors: selectErrorsLogin(state),
  token: selectToken(state),
});

const mapDispatchToProps = (dispatch) => ({
  login: (data) => dispatch(loginUserRequest(data)),
  push: (value) => dispatch(push(value)),
  resetState: () => dispatch(clearUserState()),
});

const Login = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginContainer);

export default Login;
