import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { ButtonCustom } from '../../../common/button/button';
import { Text } from '../../../common/text/Text';

function getUser() {
  return JSON.parse(localStorage.getItem('user'));
}

const HelpDrawerContainer = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userData = getUser();
    if (!user) setUser(userData);
  }, [user]);

  return (
    <HelpWrapper>
      <Text variant="h5" color="textPrimary">
        Bonjour
        {user && ` ${user.firstName}`}
      </Text>
      <Text variant="body2" color="secondary">Si vous avez besoin d'aide, vous pouvez accéder à notre cloud.</Text>
      <Text variant="body2" color="secondary">
        Vous y trouverez toutes les informations requises à l'utilisation de ce CRM ainsi qu'au bon fonctionnement de
        notre cabinet.
      </Text>
      <a
        rel="nofollow noreferrer"
        href="https://drive.google.com/drive/u/4/folders/0AJwDk2JIdeKIUk9PVA"
        target="_blank"
      >
        <ButtonCustom
          text="Accéder au drive"
          background="blue"
          size="medium"
          margin={false}
        />
      </a>
    </HelpWrapper>
  );
};

const HelpWrapper = styled.div`
  width: 200px;
  min-height: 280px;
  height: auto;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export {
  HelpDrawerContainer,
};
