import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import {
  retrieve_jurisdictions_error,
  RETRIEVE_JURISDICTIONS_REQUEST,
  retrieve_jurisdictions_response,
  retrieve_operated_airlines_error,
  retrieve_operated_airlines_response,
  RETRIEVE_OPERATING_AIRLINES_REQUEST,
} from './filterlist.action';

export const retrieveFilterOperatinAirlineList = (action$, store, { CaseListGateway }) => action$.pipe(
  ofType(RETRIEVE_OPERATING_AIRLINES_REQUEST),
  mergeMap((action) => CaseListGateway.retrieveOperatingAirlines(action.payload)
    .pipe(
      map((list) => retrieve_operated_airlines_response(list)),
      catchError((error) => of(retrieve_operated_airlines_error(error.response))),
    )),
);

export const retrieveJurisdictionsList = (action$, store, { CaseListGateway }) => action$.pipe(
  ofType(RETRIEVE_JURISDICTIONS_REQUEST),
  mergeMap((action) => CaseListGateway.retrieveJurisdictions(action.payload)
    .pipe(
      map((list) => retrieve_jurisdictions_response(list)),
      catchError((error) => of(retrieve_jurisdictions_error(error.response))),
    )),
);
