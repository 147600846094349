import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { MyContainerDossiersNavigation } from './dossier.navigation.container';
import { selectStateCase } from '../../../../casescontext/usescases/case/case.selector';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
  state: selectStateCase(state),
});

const mapDispatchToProps = (dispatch) => ({
  push: (value) => dispatch(push(value)),
});

const DossierContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyContainerDossiersNavigation);

export default DossierContainer;
