import { map } from 'rxjs/operators';
import axios from 'axios-observable';
import { ajax } from 'rxjs/ajax';
import { Case } from '../../../domain/case';

function mapCase(data) {
  return {
    ...data,
    data: Case({...data.data}),
  };
}

function mapCaseList(cases) {
  if ('message' in cases && cases.message === 'Token is not valid') {
    localStorage.clear();
    return window.location = '/';
  }

  return {
    ...cases.data,
    data: cases.data.data.map((el) => Case({...el})),
  };
}

function mapListOptions(options) {
  return {
    ...options,
    data: options.data.map((option) => ({
      label: option,
      value: option,
    })),
  };
}

function isSelected(item) {
  return item.selected;
}

function CaseListGatewayRest() {
  return {
    retrieveCaseList(queryString) {
      return axios.get(`${process.env.REACT_APP_API_URL}/api/record/getList${queryString === undefined ? '' : queryString}`, {
        headers: {'x-access-token': localStorage.getItem('admin-token')},
      }).pipe(
        map((cases) => mapCaseList(cases)),
      );
    },
    getAutomaticsLogs(claimId) {
      return axios.get(`${process.env.REACT_APP_API_URL}/api/automatic/logs?claimId=${claimId}`, {
        headers: {'x-access-token': localStorage.getItem('admin-token')},
      });
    },
    retrieveOperatingAirlines() {
      return axios.get(`${process.env.REACT_APP_API_URL}/api/record/getAirlines`, {
        headers: {'x-access-token': localStorage.getItem('admin-token')},
      }).pipe(
        map((airlines) => mapListOptions(airlines.data)),
      );
    },
    retrieveJurisdictions() {
      return axios.get(`${process.env.REACT_APP_API_URL}/api/record/getJurisdiction`, {
        headers: {'x-access-token': localStorage.getItem('admin-token')},
      }).pipe(
        map((jurisdictions) => mapListOptions(jurisdictions.data)),
      );
    },
    updateMultipleStatus(status, list) {
      const recordsId = list.filter(isSelected).map((item) => item._id);
      return ajax({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/claim/update/status`,
        body: {claimIds: recordsId, status},
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': localStorage.getItem('admin-token'),
        },
      });
    },
    updateStatusClaim(status, id) {
      return ajax({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/claim/update/status`,
        body: {claimIds: [id], status},
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': localStorage.getItem('admin-token'),
        },
      });
    },
    retrieveCaseById(queryString) {
      return axios.get(`${process.env.REACT_APP_API_URL}/api/record/get${queryString === undefined ? '' : queryString}`, {
        headers: {'x-access-token': localStorage.getItem('admin-token')},
      }).pipe(
        map((cases) => mapCase(cases.data)),
      );
    },
    syncWithAlgolia({claimId}) {
      return axios.get(`${process.env.REACT_APP_API_URL}/api/algolia/sync?claimId=${claimId}`, {
        headers: {'x-access-token': localStorage.getItem('admin-token')},
      });
    },
    updateSinglePassenger(passengerId, data) {
      const freshSend = {
        customerId: passengerId,
        customer: {
          ...data,
        },
      };

      return ajax.post(`${process.env.REACT_APP_API_URL}/api/customer/updateSingle`, JSON.stringify({...freshSend}), {
        'x-access-token': localStorage.getItem('admin-token'),
        'Content-Type': 'application/json',
      });
    },
    updateSingleCase(caseId, data) {
      const fieldToUpdate = {
        recordId: caseId,
        record: {
          ...data,
        },
      };
      return ajax.post(`${process.env.REACT_APP_API_URL}/api/record/update`, JSON.stringify({
        ...fieldToUpdate,
      }), {
        'x-access-token': localStorage.getItem('admin-token'),
        'Content-Type': 'application/json',
      });
    },
    generateDocuments(folder, type) {
      if (type === 'merge') {
        const objForPdf = {
          name: `Pièces-justificatives-dossier-${folder.fullNameMainPassenger().replace(/ /g, '-')}`,
          key: folder._id,
        };
        return ajax.post(`${process.env.REACT_APP_API_URL}/api/generate/${type}`, {
          ...objForPdf,
        }, {
          'x-access-token': localStorage.getItem('admin-token'),
          'Content-Type': 'application/json',
        });
      }
      return ajax.post(`${process.env.REACT_APP_API_URL}/api/generate/${type}`, {
        id: folder._id,
      }, {
        'x-access-token': localStorage.getItem('admin-token'),
        'Content-Type': 'application/json',
      });
    },
    removeDocuments(folder) {
      const documentsId = folder.documents.filter((e) => e.selected).map((id) => id._id);
      return ajax.post(`${process.env.REACT_APP_API_URL}/api/document/delete`, {
        documentsId,
      }, {
        'x-access-token': localStorage.getItem('admin-token'),
        'Content-Type': 'application/json',
      });
    },
    addComment(folder, message) {
      const log = {
        custom: {
          message,
        },
      };
      return ajax.post(`${process.env.REACT_APP_API_URL}/api/logger/add`, {
        recordId: folder._id,
        action: {...log},
      }, {
        'x-access-token': localStorage.getItem('admin-token'),
        'Content-Type': 'application/json',
      });
    },
    retrieveHearingDates(id) {
      return ajax.getJSON(`${process.env.REACT_APP_API_URL}/api/convocation/passenger?id=${id}`, {
        'x-access-token': localStorage.getItem('admin-token'),
      });
    },
    retrieveAirhelpCompensation(claimIds) {
      return ajax.post(`${process.env.REACT_APP_API_URL}/api/record/finalCompensation`, JSON.stringify({claimIds}), {
        'x-access-token': localStorage.getItem('admin-token'),
        'Content-Type': 'application/json',
      });
    }
  };
}

export {
  CaseListGatewayRest,
  axios,
};
