import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import { GlobalWrapper } from '../common/GlobalWrapper';
import { PageHeaderList } from '../../../../../common/pageheaderlist/pageheaderlist';
import { Breadcrumbs } from '../../../../../common/breadcrumbs/breadcrumbs';
import { ButtonCustom } from '../../../../../common/button/button';
import { Text } from '../../../../../common/text/Text';
import { Spacer } from '../../../../../common/spacer/Spacer';
import { InputCalendar } from '../../../../../common/input/inputCalendar';
import { InputSelect } from '../../../../../common/input/inputSelect';
import { InputText } from '../../../../../common/input/inputText';
import { InputNumber } from '../../../../../common/input/inputNumber';
import { ItemsContainer } from '../../../../../common/input/styledCssCommon';
import { AvatarDefault } from '../../../../../common/avatar/avatar';
import { theme } from '../../../../../config/theme';
import { OcrDomain } from '../../../../../documentscontext/adapters/primary/tableutils';

const { data } = require('../../filterCaseList/dataFilters');

const ListWrapper = styled.div`
  min-width: 600px;
  width: 100%;
  padding: 0;
`;

const PDFViewer = ({ url }) => (
  <embed src={url} width="100%" height="800" type="application/pdf" />
);

function detectIfIbanAndRefNumberShouldBeDisplayed(airline) {
  switch (airline) {
    case 'Easyjet':
      return true;
    case 'Easyjet Europe Airline':
      return true;
    case 'Easyjet Switzerland':
      return true;
    default:
      return false;
  }
}

const PassengersContainer = ({
  folder, updatePassenger, updateValueCase, retrieveHearingDates, saveValueInCase, savePassengerUpdate,
}) => {
  const _saveValueCase = (e) => {
    const { name, value } = e.target;
    saveValueInCase(name, value);
  };
  const _saveValuePassenger = (e, _id) => {
    const { name, value } = e.target;
    savePassengerUpdate(name, value, _id);
  };

  const _saveValuePassengerCalendar = (name, value, _id) => {
    savePassengerUpdate(name, value, _id);
  };

  const compare = (a, b) => {
    if (a.active < b.active) {
      return 1;
    }
    if (a.active > b.active) {
      return -1;
    }
    return 0;
  };

  if (!folder) return null;

  const pdf = folder.documents.find((d) => d.type === 'pdf') || null;

  return (
    <GlobalWrapper>
      <ListWrapper>
        <PageHeaderList
          actionsContent={null}
          breadcrumbs={(
            <Breadcrumbs
              text="Dossiers"
              key="DossiersKey"
              location="/dossiers?paginateSize=50"
              fullName={folder.fullNameMainPassenger()}
              from_id={folder.from_id}
            />
)}
          bottomBar={null}
        />
      </ListWrapper>
      <WrapperCards>
        {folder.passengers.sort(compare).map((passenger, index) => (
          <PassengerCard
            _id={passenger._id}
            key={passenger._id}
            passenger={passenger}
            withIbanAndRefNumber={detectIfIbanAndRefNumberShouldBeDisplayed(folder.ca_name_operated)}
            index={index}
            folder={folder}
            updatePassenger={updatePassenger}
            setValueInState={updateValueCase}
            saveValueInCase={_saveValueCase}
            saveValuePassenger={_saveValuePassenger}
            saveValueCalendar={_saveValuePassengerCalendar}
            retrieveHearingDates={retrieveHearingDates}
            isMainPassenger={passenger.principal}
          />
        ))}
        {pdf && pdf.location && <PDFViewer url={pdf.location} />}
      </WrapperCards>
    </GlobalWrapper>
  );
};

function getAge(dateString) {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

const PassengerCard = ({
  passenger = {},
  _id,
  updatePassenger,
  saveValuePassenger,
  saveValueCalendar,
  isMainPassenger,
  withIbanAndRefNumber,
  folder,
}) => {
  const [openOcrJson, setOpenOcrJson] = useState(false);

  const ocr = passenger.ocr ? OcrDomain(passenger.ocr) : null;
  const rateLimit = 0.9;
  const rateLimitBirthPlace = 0.6;

  const _checkDateIsValid = (value) => Date.parse(value) <= Date.parse(new Date());

  const _setCalendarValue = (value) => {
    if (_checkDateIsValid(value)) {
      updatePassenger('birthDate', value, _id);
      saveValueCalendar('birthDate', value, _id);
    }
  };

  const compareValueBetweenOcrAndValue = (ocrValue = null, paxValue = null) => ocrValue === paxValue;

  const getRate = (key) => {
    if (!passenger.ocr) return null;
    if (compareValueBetweenOcrAndValue(ocr[key].value, passenger[key], key)) {
      const { probability } = ocr[key];
      return probability === 0 ? '0' : probability;
    }
    return null;
  };

  const checkIfOcr = () => {
    if (!passenger.ocr) return null;
    let isSuccess = true;

    const toCheck = Object.keys(ocr)
      .filter((key) => compareValueBetweenOcrAndValue(ocr[key].value, passenger[key], key))
      .reduce((obj, key) => {
        obj[key] = ocr[key];
        return obj;
      }, {});

    Object.keys(toCheck).forEach((key) => {
      if (ocr[key].probability <= rateLimit && isSuccess && key !== 'birthPlace') {
        isSuccess = false;
      } else if (key === 'birthPlace' && ocr[key].probability <= rateLimitBirthPlace) {
        isSuccess = false;
      }
    });

    return isSuccess;
  };

  const checkIfOcrIsSuccessful = useMemo(() => checkIfOcr(), [ocr]);

  console.log({passenger, folder})
  const findFreeOcrDocument = (documents) => {
    console.log(documents)
    const firstName = passenger.firstName
    const doc = documents.filter(document => ['profession_of_all_passengers'].includes(document.type)).filter(doc => doc.name.includes(firstName))
    return doc.length > 0 ? doc[0]?.location : null
  }

  return (
    <WrapperPassenger
      active={passenger.active}
      openOcrJson={openOcrJson}
      ocr={checkIfOcrIsSuccessful}
      hasOcr={passenger.ocr}
    >
      {passenger?.ocr_in_house && (
        <FreeOcrContainerWarning ocrInHouse={passenger.ocr_in_house}>
          <a target="_blank" href={findFreeOcrDocument(folder.documents)} rel="noreferrer">🏠 Free OCR</a>
        </FreeOcrContainerWarning>
      )}
      {ocr
        && (
        <OcrContainerWarning
          ocr={checkIfOcrIsSuccessful}
          onClick={() => {
            // eslint-disable-next-line no-unused-expressions
            !openOcrJson ? setOpenOcrJson(true) : setOpenOcrJson(false);
          }}
        >
          {checkIfOcrIsSuccessful ? <p>✓ OCR</p> : <p>⚠ OCR</p>}
        </OcrContainerWarning>
        )}
      <WrapperImg active={passenger.active}>
        <AvatarDefault />
        <Text color="secondary" variant="h6">{passenger?.fullName() || ''}</Text>
      </WrapperImg>
      <JsonContainer openOcrJson={openOcrJson}>
        <pre>
          <code>
            {JSON.stringify(passenger.ocr, undefined, 4)}
          </code>
        </pre>
      </JsonContainer>
      <WrapperInfoCard>
        {getAge(passenger.birthDate) <= 17
          ? (
            <>
              <TitleContainer>
                <Text variant="h6" color="primary">Représentant légal</Text>
              </TitleContainer>
              <InputContainer>
                <InputText
                  label="Représentant légal"
                  onBlur={(event) => {
                    saveValuePassenger(event, _id);
                    updatePassenger(event.target.name, event.target.value, _id);
                  }}
                  defaultValue={passenger?.legal_representative_full_name || ''}
                  name="legal_representative_full_name"
                />
              </InputContainer>
              <Spacer medium />
            </>
          )
          : null}
        <TitleContainer>
          <Text variant="h6" color="primary">Details</Text>
        </TitleContainer>
        <InputContainer>
          <InputSelect
            labelID={`gender-select-label-${_id}`}
            rate={passenger.ocr ? getRate('gender') : null}
            rateLimit={rateLimit}
            id={`gender-select-${_id}`}
            label="Genre"
            data={data.GENDER}
            defaultValue={passenger?.gender}
            onChange={(event) => {
              updatePassenger('gender', event.target.value, _id);
              saveValueCalendar('gender', event.target.value, _id);
            }}
          />
          <InputText
            label="Nom complet"
            disabled
            defaultValue={passenger?.fullName() || ''}
          />

          {isMainPassenger
            && (
            <InputText
              label="Adresse postale"
              name="address"
              onBlur={(event) => {
                updatePassenger(event.target.name, event.target.value, _id);
                saveValuePassenger(event, _id);
              }}
              defaultValue={passenger?.address || ''}
            />
            )}
          <InputCalendar
            name="birthDate"
            rate={passenger.ocr ? getRate('birthDate') : null}
            rateLimit={rateLimit}
            label="Date de naissance"
            dateDefault={passenger?.birthDate}
            onChange={_setCalendarValue}
            isValidDate={_checkDateIsValid}
            id={`datepicker-${_id}`}
          />
          <Spacer medium />
          <InputText
            label="Ville de naissance"
            name="birthPlace"
            rate={passenger.ocr ? getRate('birthPlace') : null}
            rateLimit={rateLimitBirthPlace}
            onBlur={(event) => {
              saveValuePassenger(event, _id);
              updatePassenger(event.target.name, event.target.value, _id);
            }}
            defaultValue={passenger?.birthPlace || ''}
          />
          <InputText
            label="Nationalité"
            name="nationality"
            rate={passenger.ocr ? getRate('nationality') : null}
            rateLimit={rateLimit}
            onBlur={(event) => {
              saveValuePassenger(event, _id);
              updatePassenger(event.target.name, event.target.value, _id);
            }}
            defaultValue={passenger?.nationality || ''}
          />
          <InputText
            label="Job"
            name="job"
            onBlur={(event) => {
              saveValuePassenger(event, _id);
              updatePassenger(event.target.name, event.target.value, _id);
            }}
            defaultValue={passenger?.job || ''}
          />
          <ItemsContainer />
          {!isMainPassenger && <ItemsContainer />}
        </InputContainer>
        <TitleContainer>
          <Spacer medium />
          <Text variant="h6" color="primary">La récolte</Text>
        </TitleContainer>
        <InputContainer>
          <InputSelect
            labelID={`pax-status-select-${_id}`}
            id={`pax-status-select-${_id}`}
            label="Statut passager"
            data={data.SOUS_STATUS}
            value={data.SOUS_STATUS[passenger.status].value}
            onChange={(event) => {
              updatePassenger('status', event.target.value, _id);
              saveValueCalendar('status', event.target.value, _id);
            }}
          />
          <InputText
            label="Indemnité prévue"
            disabled
            defaultValue={passenger.compensation || 0}
          />
          <InputNumber
            label="Indemnité reçu"
            name="compensation_received"
            onBlur={(event) => {
              saveValuePassenger(event, _id);
              updatePassenger(event.target.name, event.target.value, _id);
            }}
            defaultValue={passenger.compensation_received || 0}
          />
          <InputNumber
            label="Article 700"
            name="article_700"
            onBlur={(event) => {
              updatePassenger(event.target.name, event.target.value, _id);
              saveValuePassenger(event, _id);
            }}
            defaultValue={passenger.article_700 || null}
          />
          <InputNumber
            label="Dommages et intérêts"
            name="interest"
            onBlur={(event) => {
              saveValuePassenger(event, _id);
              updatePassenger(event.target.name, event.target.value, _id);
            }}
            defaultValue={passenger.interest || 0}
          />
          <InputText
            label="N° compte carpa"
            name="bank_account"
            onBlur={(event) => {
              saveValuePassenger(event, _id);
              updatePassenger(event.target.name, event.target.value, _id);
            }}
            defaultValue={passenger.bank_account || ''}
          />
          {withIbanAndRefNumber
            && (
            <>
              <InputText
                label="Iban"
                name="iban"
                onBlur={(event) => {
                  saveValuePassenger(event, _id);
                  updatePassenger(event.target.name, event.target.value, _id);
                }}
                defaultValue={passenger.iban || ''}
              />
              <InputText
                label="Numéro de référence"
                name="ref_number"
                onBlur={(event) => {
                  saveValuePassenger(event, _id);
                  updatePassenger(event.target.name, event.target.value, _id);
                }}
                defaultValue={passenger.ref_number || ''}
              />
            </>
            )}
          <InputText
            label="Source"
            disabled
            defaultValue={`AIRHELP ${folder.legal_entity || ''}`}
          />
          <ItemsContainer />
        </InputContainer>
        <TitleContainer>
          <Spacer medium />
          <Text variant="h6" color="primary">Audience</Text>
        </TitleContainer>
        <InputContainer>
          <InputText
            label="N° RG"
            name="rg_number"
            onBlur={(event) => {
              saveValuePassenger(event, _id);
              updatePassenger(event.target.name, event.target.value, _id);
            }}
            defaultValue={passenger.rg_number || ''}
          />
          <InputSelect
            label="Modifier audience"
            labelID={`audience-${_id}`}
            id={`audience-${_id}`}
            data={data.DESISTEMENT}
            value={data.DESISTEMENT[passenger.cancelling || 0].value}
            onChange={(event) => {
              updatePassenger('cancelling', event.target.value, _id);
              saveValueCalendar('cancelling', event.target.value, _id);
            }}
          />
          <ItemsContainer />
          <Spacer medium />
          <ItemsContainer>
            <ButtonCustom
              background={passenger.active ? 'red' : 'blue'}
              text={passenger.active ? 'Désactiver le passager' : 'Activer le passager'}
              type="button"
              endIcon={passenger.active ? <ToggleOnIcon /> : <ToggleOffIcon />}
              onClick={() => {
                updatePassenger('active', !passenger.active, _id);
                saveValueCalendar('active', !passenger.active, _id);
              }}
            />
          </ItemsContainer>
        </InputContainer>
      </WrapperInfoCard>
    </WrapperPassenger>
  );
};

const WrapperPassenger = styled.div`
  display: flex;
  align-item: flex-start;
  justify-content: flex-start;
  background: #fff;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.1);
  width: 100%;
  border: ${(props) => (props.hasOcr ? props.ocr ? 'solid #deecff 2px' : `solid ${theme.red} 2px` : 'solid #deecff 2px')};
  border-radius: 8px;
  min-height: 400px;
  margin: 0 auto 30px;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const WrapperImg = styled.div`
  min-width: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: ${(props) => (props.active ? '#DEEBFF' : `${theme.red}`)};
  border-radius: 6px 6px 0 0;
  padding: 10px 0;

  h3 {
    text-align: center;
    margin-top: 5px;
  }
`;

const WrapperCards = styled.div`
  display: flex;
  align-item: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background: white;
  width: 100%;
  margin: 60px 0;
  max-width: 925px;
`;

const WrapperInfoCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 95%;
  padding: 20px 0;
`;

const TitleContainer = styled.div`
  width: 95%;
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const OcrContainerWarning = styled.div`
  position: absolute;
  right: 6px;
  top: 6px;
  opacity: 0.9;
  padding: 4px 10px 4px 10px;
  background: ${(props) => (props.ocr ? theme.blue : theme.red)};
  border-radius: 9px;
  transition: all 0.4s;
  cursor: pointer;

  :hover {
    opacity: 1;
  }

  p {
    margin: 0;
    color: white;
    font-size: 12px;
  }
`;

const FreeOcrContainerWarning = styled.div`
  position: absolute;
  right: 6px;
  top: 6px;
  opacity: 0.9;
  padding: 4px 10px 4px 10px;
  background: ${(props) => (props.ocrInHouse ? theme.green : theme.red)};
  border-radius: 9px;
  transition: all 0.4s;
  cursor: pointer;

  :hover {
    opacity: 1;
  }

  p {
    margin: 0;
    color: white;
    font-size: 12px;
  }
`;

const JsonContainer = styled.div`
  width: 95%;
  height: ${(props) => (props.openOcrJson ? '400px' : '0px')};
  background: ${(props) => (props.openOcrJson ? '#fff59d' : 'transparent')};
  display: flex;
  border: ${(props) => (props.openOcrJson ? '2px solid black' : '2px solid transparent')};
  margin: 15px;
  overflow: auto;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  transition: all 1s;

  pre {
    font-size: 14px;
    margin: 0;
    padding: 10px;
    border-left: 6px solid #00458b;
    line-height: 1.4;
    width: 100%;
  }
`;

export {
  PassengersContainer,
};
