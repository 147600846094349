/*
 * Developed by quentinmalguy on 4/25/19 3:59 PM from Malguy Technology.
 * Last modified 4/25/19 3:35 PM.
 */

export const RETRIEVE_DOCUMENTS_LIST = 'RETRIEVE_DOCUMENTS_LIST';
export const RETRIEVE_DOCUMENTS_LIST_RESPONSE = 'RETRIEVE_DOCUMENTS_LIST_RESPONSE';
export const RETRIEVE_DOCUMENTS_LIST_ERROR = 'RETRIEVE_DOCUMENTS_LIST_ERROR';
export const SELECT_ELEM_IN_DOCUMENTS_LIST = 'SELECT_ELEM_IN_DOCUMENTS_LIST';
export const SELECT_ALL_ELEM_IN_DOCUMENTS_LIST = 'SELECT_ALL_ELEM_IN_DOCUMENTS_LIST';
export const GENERATE_ZIP = 'GENERATE_ZIP';
export const GENERATE_ZIP_RESPONSE = 'GENERATE_ZIP_RESPONSE';
export const GENERATE_ZIP_ERROR = 'GENERATE_ZIP_ERROR';
export const INIT_LIST_DOCS = 'INIT_LIST_DOCS';
export const GENERATE_GREFFE_REQUEST = 'GENERATE_GREFFE_REQUEST';
export const GENERATE_GREFFE_RESPONSE = 'GENERATE_GREFFE_RESPONSE';
export const GENERATE_DOCUMENTS_LIST = 'GENERATE_DOCUMENTS_LIST';
export const GENERATE_DOCUMENTS_LIST_RESPONSE = 'GENERATE_DOCUMENTS_LIST_RESPONSE';

export const retrieveDocumentsList = (search) => ({
  type: RETRIEVE_DOCUMENTS_LIST,
  payload: search,
});

export const retrieveDocumentsListResponse = (list) => ({
  type: RETRIEVE_DOCUMENTS_LIST_RESPONSE,
  payload: list,
});

export const retrieveDocumentsListError = (error) => ({
  type: RETRIEVE_DOCUMENTS_LIST_ERROR,
  payload: error,
});

export const selectElementInList = (elem) => ({
  type: SELECT_ELEM_IN_DOCUMENTS_LIST,
  payload: { elem },
});

export const selectAllElemInList = (isChecked) => ({
  type: SELECT_ALL_ELEM_IN_DOCUMENTS_LIST,
  payload: isChecked,
});

export const generateZipList = (type) => ({
  type: GENERATE_ZIP,
  payload: type,
});

export const generateDocumentsListRequest = (type) => ({
  type: GENERATE_DOCUMENTS_LIST,
  payload: type,
});

export const generateDocumentsListResponse = (response) => ({
  type: GENERATE_DOCUMENTS_LIST_RESPONSE,
  payload: response,
});

export const generateZipListResponse = (response) => ({
  type: GENERATE_ZIP_RESPONSE,
  payload: response,
});

export const initListDocs = () => ({
  type: INIT_LIST_DOCS,
});

export const generateGreffeRequest = (jurisdiction) => ({
  type: GENERATE_GREFFE_REQUEST,
  payload: { jurisdiction },
});

export const generateGreffeResponse = (response) => ({
  type: GENERATE_GREFFE_RESPONSE,
  payload: response,
});
