import React from 'react'
import { ButtonCustom } from "../../../../common/button/button";
import { format } from "date-fns";
import * as fs from 'file-saver';
import { Workbook } from "exceljs";
import EuroIcon from '@mui/icons-material/Euro';

function ButtonGenerateExcelAccounting({ list, status }) {
  const title = (char) => {
    return `Comptabilité du ${format(new Date(), `dd${char}MM${char}yyyy`)}`
  };

  const downloadExcel = (data) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(title('-'));
    const header =
      [
        'C.A.',
        'Référence AirHelp',
        'Prénom Nom',
        'N° Compte banque carpa',
        'Montant d\'EC 261 prévu',
        'Montant article 700',
        'Montant dommages et intérêts',
        'Montant total',
        'Type de contrat',
        'Entité'
      ];
    worksheet.getRow(3).values = header;
    const fName = title('/');

    worksheet.getRow(1).font = {
      size: 16,
      bold: true,
      color: { argb: 'FFFFFFFF' },
    };

    worksheet.getRow(3).alignment = { vertical: 'top', horizontal: 'left' };

    worksheet.getRow(3).font = {
      size: 14,
      bold: true,
    };

    worksheet.getCell('A1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF0052CC' },
    };

    worksheet.columns = [
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 30 },
      { width: 30 },
      { width: 25 },
      { width: 25 },
      { width: 25 },
      { width: 20 },
      { width: 15 },
    ];

    for (const x1 of data) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of x2) {
        temp.push(x1[y]);
      }
      worksheet.addRow(temp);
    }

    worksheet.mergeCells('A1', 'I1');
    worksheet.getCell('A1').value = title('/');

    worksheet.getColumn(5).numFmt = '#,##0.00" €";[Red]-#,##0.00" €"';
    worksheet.getColumn(6).numFmt = '#,##0.00" €";[Red]-#,##0.00" €"';
    worksheet.getColumn(7).numFmt = '#,##0.00" €";[Red]-#,##0.00" €"';

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, `${fName}-${new Date().valueOf()}.xlsx`);
    });
  };

  function add(accumulator, a) {
    return accumulator + a;
  }

  const DataExcelAudience = (data) => data.map((e) => {
    const totalArticle700 = Number(e.passengers.map(e => e.article_700).reduce(add, 0))
    const ec61Amount = Number(e.compensation_total_ca - totalArticle700)
    const totalIntesrest = Number(e.passengers.map(e => e.interest).reduce(add, 0))

    return {
      airline: e.ca_name_operated,
      airhelp_ref: Number(e.from_id),
      name: e.fullNameMainPassenger(),
      bank_account_carpa: e.bank_account?.bank_account_id ? Number(e.bank_account.bank_account_id) : null,
      ec_61_amount: ec61Amount,
      total_article_700: totalArticle700,
      total_interest: totalIntesrest,
      total_amount: e.compensation_total_ca,
      contract_type: e.contract_type,
      legal_entity: e.legal_entity
    }
  });

  return (
    <ButtonCustom
      disabled={list.length === 0 || status !== '35'}
      background="green"
      text="Excel comptabilité"
      startIcon={<EuroIcon />}
      onClick={() => downloadExcel(DataExcelAudience(list))}
    />
  )
}

export {
  ButtonGenerateExcelAccounting
}
