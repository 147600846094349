import { combineEpics } from 'redux-observable';
import { rootUserEpics } from '../usercontext/usescases/rootUserEpics';
import { rootCaseEpics } from '../casescontext/usescases/rootCaseEpics';
import { UserGatewayRest } from '../usercontext/adapters/secondary/rest/userGatewayRest';
import { CaseListGatewayRest } from '../casescontext/adapters/secondary/rest/CaseListGateway';
import { searchClaimQuery, searchQuery } from '../searchcontext/usescases/search.epics';
import { SearchGatewayRest } from '../searchcontext/adapters/secondary/rest/SearchGatewayRest';
import { rootDocumentsEpics } from '../documentscontext/usescases/documents.epics';
import { DocumentsGatewayRest } from '../documentscontext/adapters/secondary/rest/DocumentsGateway';
import { rootConvocationEpics } from '../convocationcontext/usescases/rootConvocationEpics';
import { ConvocationGateway } from '../convocationcontext/adapters/secondary/ConvocationGateway';
import { AudienceGateway } from '../audiencecontext/adapters/secondary/AudienceGateway';
import { rootAudiencesEpics } from '../audiencecontext/usecases/rootEpics';

export const rootEpics = combineEpics(
  rootUserEpics,
  rootCaseEpics,
  searchQuery,
  searchClaimQuery,
  rootDocumentsEpics,
  rootConvocationEpics,
  rootAudiencesEpics,
);

export const deps = {
  dependencies: {
    UserGateway: UserGatewayRest(),
    CaseListGateway: CaseListGatewayRest(),
    SearchGateway: SearchGatewayRest(),
    DocumentsGateway: DocumentsGatewayRest(),
    ConvocationGateway: ConvocationGateway(),
    AudienceGateway: AudienceGateway(),
  },
};
