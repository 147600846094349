import { ofType } from 'redux-observable';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { getState } from '../../convocationcontext/usescases/convocation.epics';
import {
  ADD_JURISTS_REQUEST,
  addJuristsResponse,
  RETRIEVE_AUDIENCES,
  RETRIEVE_JURISTS_REQUEST,
  RETRIEVE_MY_AUDIENCE_REQUEST,
  retrieveAudiencesError,
  retrieveAudiencesResponse,
  retrieveJuristsErrors,
  retrieveJuristsResponse,
  retrieveMyAudiencesResponse,
  RETRIEVE_AUDIENCE_REQUEST,
  retrieveAudienceResponse,
  ADD_AUDIENCE_INFORMATION,
  saveAudienceInformationResponse,
  saveAudienceInformationError,
  ADD_CONVOCATION_FROM_AUDIENCE,
  addConvocationFromAudienceResponse,
  addConvocationFromAudienceError,
  GENERATE_ZIP_CCL,
  generateZipCCLResponse,
  generateZipCCLError,
  GENERATE_ZIP_AUDIENCE,
  generateZipAudienceResponse,
  generateZipAudienceError,
  UPDATE_STATUS_AUDIENCE_LIST_REQUEST,
  updateStatusSelectedCaseCourtResponse,
  TOGGLE_FINISH_AUDIENCE_REQUEST,
  toggleFinishResponse, UPDATE_CANCELLING_AUDIENCE_LIST_REQUEST,
} from './audience.actions';

export const retrieveAudiences = (action$, store, { AudienceGateway }) => action$.pipe(
  ofType(RETRIEVE_AUDIENCES),
  mergeMap((action) => AudienceGateway.retrieveAudience(action.payload)
    .pipe(
      map((data) => retrieveAudiencesResponse(data)),
      catchError((error) => of(retrieveAudiencesError(error.response))),
    )),
);

export const toggleAudienceFinish = (action$, store, { AudienceGateway }) => action$.pipe(
  ofType(TOGGLE_FINISH_AUDIENCE_REQUEST),
  mergeMap(
    (action) => AudienceGateway.updateAudienceToggle(action.payload)
      .pipe(
        map((data) => toggleFinishResponse(data)),
        catchError((error) => of(retrieveAudiencesError(error.response))),
      ),
  ),
);

export const retrieveJurists = (action$, store, { AudienceGateway }) => action$.pipe(
  ofType(RETRIEVE_JURISTS_REQUEST),
  mergeMap((action) => AudienceGateway.retrieveJurists(action.payload)
    .pipe(
      map((data) => retrieveJuristsResponse(data)),
      catchError((error) => of(retrieveJuristsErrors(error.response))),
    )),
);

export const addJurists = (action$, store, { AudienceGateway }) => action$.pipe(
  ofType(ADD_JURISTS_REQUEST),
  mergeMap((action) => AudienceGateway.addJurists(action.payload.jurists, action.payload.audienceId)
    .pipe(
      map((data) => addJuristsResponse(data)),
      catchError((error) => of(retrieveJuristsErrors(error.response))),
    )),
);

export const retrieveMyAudiences = (action$, store, { AudienceGateway }) => action$.pipe(
  ofType(RETRIEVE_MY_AUDIENCE_REQUEST),
  mergeMap((action) => AudienceGateway.retrieveMyAudiences(action.payload)
    .pipe(
      map((data) => retrieveMyAudiencesResponse(data)),
      catchError((error) => of(retrieveJuristsErrors(error.response))),
    )),
);

export const retrieveAudience = (action$, store, { AudienceGateway }) => action$.pipe(
  ofType(RETRIEVE_AUDIENCE_REQUEST),
  mergeMap((action) => AudienceGateway.retrieveAudienceById(action.payload)
    .pipe(
      map((data) => retrieveAudienceResponse(data)),
      catchError((error) => of(retrieveJuristsErrors(error.response))),
    )),
);

export const updateAudienceNote = (action$, store, { AudienceGateway }) => action$.pipe(
  ofType(ADD_AUDIENCE_INFORMATION),
  mergeMap((action) => AudienceGateway.updateNote(getState(store).audience.audience._id, action.payload)
    .pipe(
      map((data) => saveAudienceInformationResponse(data.response)),
      catchError((error) => of(saveAudienceInformationError(error.response))),
    )),
);

export const addConvocationFromAudience = (action$, store, { AudienceGateway }) => action$.pipe(
  ofType(ADD_CONVOCATION_FROM_AUDIENCE),
  mergeMap((action) => AudienceGateway.add(action.payload)
    .pipe(
      map((response) => addConvocationFromAudienceResponse(response)),
      catchError((error) => of(addConvocationFromAudienceError(error))),
    )),
);

export const generateZipCCL = (action$, store, { AudienceGateway }) => action$.pipe(
  ofType(GENERATE_ZIP_CCL),
  mergeMap((action) => AudienceGateway.generateCCL(action.payload)
    .pipe(
      map((response) => generateZipCCLResponse(response)),
      catchError((error) => of(generateZipCCLError(error))),
    )),
);

export const generateZipAudience = (action$, store, { AudienceGateway }) => action$.pipe(
  ofType(GENERATE_ZIP_AUDIENCE),
  mergeMap((action) => AudienceGateway.generateZipAudience(action.payload)
    .pipe(
      map((response) => generateZipAudienceResponse(response)),
      catchError((error) => of(generateZipAudienceError(error))),
    )),
);

export const updateStatusAudienceListRequest = (action$, store, { AudienceGateway }) => action$.pipe(
  ofType(UPDATE_STATUS_AUDIENCE_LIST_REQUEST),
  mergeMap((action) => AudienceGateway.updateMultipleStatusAudience(action.payload, getState(store).audience.audience.passengers)
    .pipe(
      map((list) => updateStatusSelectedCaseCourtResponse(list)),
      catchError((error) => of(console.log(error.response))),
    )),
);

export const updateCancellingAudienceListRequest = (action$, store, { AudienceGateway }) => action$.pipe(
  ofType(UPDATE_CANCELLING_AUDIENCE_LIST_REQUEST),
  mergeMap((action) => AudienceGateway.bulkUpdatePassengersCancelling(action.payload, getState(store).audience.audience.passengers)
    .pipe(
      map((list) => updateStatusSelectedCaseCourtResponse(list)),
      catchError((error) => of(console.log(error.response))),
    )),
);
