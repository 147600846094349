import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from '@mui/material/styles';
import { rootEpics } from './configuration/epicsMiddleware.redux';
import reduxStore, { epicMiddleware, history } from './configuration/store.redux';
import { theme } from './config/theme';
import { useAuth } from './hooks/auth/auth.hook';
import { AuthenticatedApp } from './auth/authenticatedApp';
import { UnAuthenticatedApp } from './auth/UnAuthenticatedApp';
import { GlobalStyle } from './config/injectGlobal';
import './translation/i18n';

const store = reduxStore();
epicMiddleware.run(rootEpics);

const DisplayUser = () => {
  const { isLoggedIn } = useAuth();
  return isLoggedIn ? <AuthenticatedApp /> : <UnAuthenticatedApp />;
};

const App = () => {
  const queryClient = new QueryClient();

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <GlobalStyle />
        <ConnectedRouter history={history}>
          <ThemeProvider theme={theme}>
            <DisplayUser />
          </ThemeProvider>
        </ConnectedRouter>
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
