import React, { Fragment } from 'react';
import {
  HeaderSection,
  MenuSection,
} from '@atlaskit/navigation-next';
import { MenuSectionContainer } from './menu/menu';

const MyProductNavigation = ({ handleSubMenuAudience }) => (
  <>
    <HeaderSection>
      {({ className }) => (
        <div className={className}>
          <p className="title-rg">RGAVOCATS</p>
        </div>
      )}
    </HeaderSection>
    <MenuSection>
      {({ className }) => (
        <MenuSectionContainer
          handleSubMenuAudience={handleSubMenuAudience}
          className={className}
        />
      )}
    </MenuSection>
  </>
);

export {
  MyProductNavigation,
};
