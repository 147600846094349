import styled from 'styled-components';

const SelectDiv = styled.div`
  width: 200px;
  position: absolute;
  right: 10px;
  top: 15px;
`;
const Multiple = styled.div`
  display: flex;
  width: 100%;

  div {
    margin-bottom: 0;

    &:first-child {
      margin-right: 20px;
    }
  }
`;

const SquareContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
`;

const CustomCtn = styled.div`
  width: ${(props) => props.width};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 20px;
`;

const BoxSession = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 200px;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 0 0 30px 0;
  margin: 30px auto;

  h3 {
    padding: 20px 0 0 20px;
  }
`;

export {
  SelectDiv,
  Multiple,
  SquareContainer,
  CustomCtn,
  BoxSession,
};
