import React from 'react';
import styled from 'styled-components';
import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AvatarDefault } from '../../../../../common/avatar/avatar';

const useStyles = makeStyles(() => ({
  chip: {
    marginRight: '10px',
  },
}));

const ListOfAssignedLawyers = ({ audience }) => {
  const classes = useStyles();

  return (
    <ContainerListJurist>
      {audience.jurists.map((e) => (
        <Chip
          key={[e._id]}
          className={classes.chip}
          label={`${e.firstName} ${e.lastName}`}
          avatar={<AvatarDefault>{e.firstName[0]}</AvatarDefault>}
        />
      ))}
    </ContainerListJurist>
  );
};

const ContainerListJurist = styled.div`
  display: flex;
`;

export {
  ListOfAssignedLawyers,
};
