import React, { useEffect, useRef, useState } from 'react';
import { isValid } from 'date-fns';
import { FormControl, Grid, TextField } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import frLocale from 'date-fns/locale/fr';
import { RateContainer } from './rateContainer';
import { ItemsContainer } from './styledCssCommon';

const InputCalendar = ({
  label,
  disabled,
  onChange,
  onError,
  dateDefault,
  name,
  id,
  rate,
  rateLimit,
  variant = 'standard',
  size,
  maxDate,
}) => {
  const ref = useRef(null);

  const [selectedDate, setSelectedDate] = useState(
    isValid(new Date(dateDefault)) ? dateDefault : null,
  );

  useEffect(() => {
    setSelectedDate(isValid(new Date(dateDefault)) ? dateDefault : null);
  }, [dateDefault]);

  return (
    <ItemsContainer>
      {rate && <RateContainer rate={rate} rateLimit={rateLimit} />}
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
        <Grid container justify="space-around">
          <FormControl fullWidth>
            <DesktopDatePicker
              label={label}
              onError={onError}
              disabled={disabled}
              name={name}
              maxDate={maxDate}
              id={id}
              inputRef={ref}
              value={selectedDate}
              renderInput={(params) => (
                <TextField
                  size={size}
                  label={label}
                  variant={variant}
                  {...params}
                />
              )}
              onChange={(e) => {
                setSelectedDate(e);
                onChange(e);
              }}
            />
          </FormControl>
        </Grid>
      </LocalizationProvider>
    </ItemsContainer>
  );
};

export {
  InputCalendar,
};
