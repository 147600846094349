import { Line } from 'react-chartjs-2';
import React from 'react';

const GraphLine = ({ data }) => (
  <Line
    options={{
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: true,
        },
      },
    }}
    data={data}
  />
);

export {
  GraphLine,
};
