import { combineEpics } from 'redux-observable';
import {
  addJurists,
  retrieveAudiences,
  retrieveJurists,
  retrieveMyAudiences,
  retrieveAudience,
  updateAudienceNote,
  addConvocationFromAudience,
  generateZipCCL,
  generateZipAudience,
  updateStatusAudienceListRequest,
  toggleAudienceFinish, updateCancellingAudienceListRequest,
} from './audience.epics';

export const rootAudiencesEpics = combineEpics(
  retrieveAudiences,
  retrieveJurists,
  addJurists,
  retrieveMyAudiences,
  retrieveAudience,
  updateAudienceNote,
  addConvocationFromAudience,
  generateZipCCL,
  generateZipAudience,
  updateStatusAudienceListRequest,
  toggleAudienceFinish,
  updateCancellingAudienceListRequest
);
