import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { getState } from '../../../convocationcontext/usescases/convocation.epics';
import {
  ADD_AIRHELP_COMPENSATION_LIST_REQUEST, addAirhepCompensationFinalResponse,
  RETRIEVE_CASE_LIST_REQUEST,
  retrieveCaseListError,
  retrieveCaseListResponse,
  UPDATE_STATUS_LIST_REQUEST,
  updateStatusSelectedCaseFailed,
  updateStatusSelectedCaseResponse,
} from './caselist.action';

export const retrieveCaseList = (action$, store, { CaseListGateway }) => action$.pipe(
  ofType(RETRIEVE_CASE_LIST_REQUEST),
  mergeMap((action) => CaseListGateway.retrieveCaseList(action.payload)
    .pipe(
      map((list) => retrieveCaseListResponse(list)),
      catchError((error) => of(retrieveCaseListError(error.message))),
    )),
);

export const retrieveAirHelpFinalCompensation = (action$, store, { CaseListGateway }) => action$.pipe(
  ofType(ADD_AIRHELP_COMPENSATION_LIST_REQUEST),
  mergeMap((action) => CaseListGateway.retrieveAirhelpCompensation(action.payload)
    .pipe(
      map((list) => addAirhepCompensationFinalResponse(list)),
      catchError(() => of(addAirhepCompensationFinalResponse(true))),
    )),
);

export const updateStatusCaseList = (action$, store, { CaseListGateway }) => action$.pipe(
  ofType(UPDATE_STATUS_LIST_REQUEST),
  mergeMap((action) => CaseListGateway.updateMultipleStatus(action.payload, getState(store).caseList.list)
    .pipe(
      map((list) => updateStatusSelectedCaseResponse(list)),
      catchError((error) => of(updateStatusSelectedCaseFailed(error.response))),
    )),
);
