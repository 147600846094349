import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BoxSession, CustomCtn, SquareContainer } from './cssForComponents';
import { Text } from '../../common/text/Text';
import { useStatsList } from '../hooks/stat.hook';
import { Loader } from '../../common/loader/loader';
import { StatisticsMed } from './statistics.med';

const GetClaimsWithProblems = () => {
  const { getClaimsWithProblems } = useStatsList();
  const { data, isLoading } = getClaimsWithProblems;
  const [dataProblem, setdataProblem] = useState({ labels: [], datasets: [] });
  useEffect(() => {
    if (!data || data.length === 0) return;
    async function getDataProblems() {
      const backgroundColor = ['#ffc6ff', '#9bf6ff', '#fdffb6'];
      const datas = data.data.map((e) => e.count);
      // eslint-disable-next-line array-callback-return
      const labels = data.data.map((e) => {
        if (e._id === 25) {
          return `Problème Tkla : ${e.count}`;
        }
        if (e._id === 22) {
          return `Problème Med : ${e.count}`;
        }
        if (e._id === 20) {
          return `Problème saisine : ${e.count}`;
        }
      });

      setdataProblem({
        labels,
        datasets: [{
          data: datas,
          backgroundColor,
        }],
      });
    }

    getDataProblems();
  }, [data]);

  return (
    <BoxSession>
      <Text component="h3" variant="subtitle2" color="primary">Problèmes Tkla / MED / Saisines :</Text>
      {isLoading ? <LoaderContainer><Loader /></LoaderContainer>
        : (
          <SquareContainer>
            <CustomCtn width="70%">
              <StatisticsMed data={dataProblem} legend tooltips={false} />
            </CustomCtn>
          </SquareContainer>
        )}
    </BoxSession>
  );
};

const LoaderContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  GetClaimsWithProblems,
};
