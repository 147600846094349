import { format } from 'date-fns';
import { Workbook } from 'exceljs';
import React from 'react';
import * as fs from 'file-saver';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import { ButtonCustom } from '../../../../common/button/button';

const ButtonGenerateExcel = ({ list, airline }) => {
  const title = (char) => {
    if (airline) {
      return `Liste des claims ${airline} - ${format(new Date(), `dd${char}MM${char}yyyy`)}`;
    } return `Liste des claims - ${format(new Date(), `dd${char}MM${char}yyyy`)}`;
  };

  const downloadExcel = (data) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(title('-'));
    const header = ['Dossier', 'Compagnie aérienne', 'Booking ref', 'Numéro\nde vol', 'Date du vol', 'Aéroport\nde départ', 'Aéroport\nd\'arrivée', 'Montant total\nde l\'indemnité'];
    worksheet.getRow(3).values = header;
    const fName = title('/');

    worksheet.getRow(1).font = {
      size: 16,
      bold: true,
      color: { argb: 'FFFFFFFF' },
    };

    worksheet.getRow(3).alignment = { vertical: 'top', horizontal: 'left' };

    worksheet.getRow(3).font = {
      size: 14,
      bold: true,
    };

    worksheet.getCell('A1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF0052CC' },
    };

    worksheet.columns = [
      { width: 24 },
      { width: 24 },
      { width: 17 },
      { width: 11 },
      { width: 14 },
      { width: 13 },
      { width: 13 },
      { width: 18 },
    ];

    for (const x1 of data) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of x2) {
        temp.push(x1[y]);
      }
      worksheet.addRow(temp);
    }

    worksheet.getColumn(8).numFmt = '#,##0.00" €";[Red]-#,##0.00" €"';
    worksheet.mergeCells('A1', 'H1');
    worksheet.getCell('A1').value = title('/');
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, `${fName}-${new Date().valueOf()}.xlsx`);
    });
  };

  const DataExcelAudience = (data) => data.map((e) => ({
    name: e.fullNameMainPassenger(),
    airline: e.ca_name_operated,
    booking_ref: e.booking_ref,
    flight_number: e.flight_number,
    flight_date: format(new Date(e.flight_date), 'dd/MM/yyyy'),
    city_departure: e.airport_departure,
    city_arrival: e.airport_arrival,
    compensation_total: e.compensation_total,
  }));

  return (
    <ButtonCustom
      disabled={list.length === 0}
      background="lightGreen"
      text="Excel claims"
      startIcon={<FolderSharedIcon />}
      onClick={() => downloadExcel(DataExcelAudience(list))}
    />
  );
};

export {
  ButtonGenerateExcel,
};
