import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import { Case } from '../../../casescontext/domain/case';

function mapCaseList(cases) {
  return {
    ...cases,
    data: cases.data.map((el) => Case({ ...el })),
  };
}

export function ConvocationGateway() {
  return {
    add(list, datetime, jurisdiction, type, jurists) {
      const data = {
        customersId: list.map(({ passenger, claim }) => ({
          _id: passenger._id,
          rg_number: passenger.rg_number,
          objectID: claim.objectID,
          passenger,
        })),
        datetime,
        jurisdiction: jurisdiction ? jurisdiction.value : '',
        type: type.value,
        jurists: jurists.map(({ value }) => value),
      };
      return ajax.post(`${process.env.REACT_APP_API_URL}/api/convocation/add`, {
        ...data,
      }, {
        'x-access-token': localStorage.getItem('admin-token'),
        'Content-Type': 'application/json',
      });
    },
    update(list, datetime, jurisdiction, type, convocationId, jurists) {
      const juristsIds = jurists ? jurists.map((j) => j.value) : [];
      const data = {
        customersId: list.map(({ passenger, claim }) => ({
          _id: passenger._id,
          rg_number: passenger.rg_number,
          objectID: claim.objectID,
          passenger,
        })),
        datetime,
        jurisdiction: jurisdiction ? jurisdiction.value : '',
        type: type.value,
        convocationId,
        jurists: juristsIds,
      };

      return ajax.post(`${process.env.REACT_APP_API_URL}/api/convocation/update`, {
        ...data,
      }, {
        'x-access-token': localStorage.getItem('admin-token'),
        'Content-Type': 'application/json',
      });
    },
    query(queryString) {
      return ajax.getJSON(`${process.env.REACT_APP_API_URL}/api/convocation/search/query?text=${queryString === undefined ? '' : queryString}`, {
        'x-access-token': localStorage.getItem('admin-token'),
        'Content-Type': 'application/json',
      }).pipe(
        map(mapCaseList),
      );
    },
  };
}
