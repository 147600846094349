import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { AudienceContainer } from './audience.container';
import { selectAudience, selectAudienceErrors, selectZipLoading } from '../../../usecases/audience.selector';
import {
  addConvocationFromAudience, addErrorsAudience, generateZipAudience, generateZipCCL,
  removeFlag,
  retrieveAudienceRequest,
  saveAudienceInformation,
  selectAll,
  selectBox,
  updateCancellingSelectedCaseCourtRequest,
  updateStatusSelectedCaseCourtRequest,
} from '../../../usecases/audience.actions';
import { selectAudienceMenuState } from '../../../../navigationcontext/usecases/navigation.selector';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
  audience: selectAudience(state),
  errors: selectAudienceErrors(state),
  viewType: selectAudienceMenuState(state),
  isLoading: selectZipLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  push: (value) => dispatch(push(value)),
  retrieveAudience: (id) => dispatch(retrieveAudienceRequest(id)),
  selectAll: () => dispatch(selectAll()),
  selectBox: (id) => dispatch(selectBox(id)),
  saveInformation: (value) => dispatch(saveAudienceInformation(value)),
  dismissError: () => dispatch(removeFlag()),
  addConvocationFromAudience: (convocation) => dispatch(addConvocationFromAudience(convocation)),
  updateStatusList: (status) => dispatch(updateStatusSelectedCaseCourtRequest(status)),
  updateCancellingList: (cancelling) => dispatch(updateCancellingSelectedCaseCourtRequest(cancelling)),
  handleErrors: (error) => dispatch(addErrorsAudience(error)),
  generateZipCCL: (passengers) => dispatch(generateZipCCL(passengers)),
  generateZip: (passengers, type) => dispatch(generateZipAudience(passengers, type)),
});

const Audience = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudienceContainer);

export default Audience;
