import styled from 'styled-components';

const ListWrapper = styled.div`
  min-width: 600px;
  width: 95%;
  padding: 0;
  font-size: 14px;
`;

export {
  ListWrapper,
};
