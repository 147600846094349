import React from 'react';
import styled from 'styled-components';
import { red } from '@mui/material/colors';
import { theme } from '../../config/theme';

const RateContainer = ({ rate, rateLimit }) => (
  <RateContainerCss>
    <p style={{ color: rate >= rateLimit ? theme.blue : `${red[400]}` }}>{Number.parseFloat(rate).toFixed(2)}</p>
  </RateContainerCss>
);

const RateContainerCss = styled.div`
  position: absolute;
  top: 3px;
  right: 19px;
  p {
    color: rgba(0, 0, 0, 0.54);
    font-size: 10px;
    font-weight: bold;
  }
`;

export {
  RateContainer,
};
