import React from 'react';
import { ButtonCustom } from '../../../../common/button/button';
import { ButtonGenerateExcel } from './generateExel';
import { ButtonGenerateExcelRibAudience } from './components/generateExcelRibAudience';
import { ButtonActionGroup } from '../../../../casescontext/adapters/primary/listcases/buttonactiongroup';
import { data } from '../../../../casescontext/adapters/primary/filterCaseList/dataFilters';

const ActionsContentAudience = ({
  generateZip, generateCCL, audience, updateStatusList, updateCancelling,
}) => {
  const passengers = audience.passengers.filter((p) => p.checked);

  return (
    <>
      <ButtonGenerateExcel audience={audience} />
      <ButtonGenerateExcelRibAudience audience={audience} />
      <ButtonActionGroup
        update={updateStatusList}
        disabled={passengers.length === 0}
        data={data}
        type="STATUS"
        title="Changer statut"
      />
      <ButtonActionGroup
        update={updateCancelling}
        disabled={passengers.length === 0}
        data={data}
        type="DESISTEMENT"
        title="Changer Intention"
      />
      <ButtonCustom
        background="default"
        textcolordark
        text="Générer CCL de désistement"
        onClick={generateCCL}
      />
      <ButtonActionGroup update={generateZip} data={data} type="ZIP_AUDIENCE" title="Générer un zip" />
    </>
  );
};

export
{
  ActionsContentAudience,
};
