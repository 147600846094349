import React from 'react';
import CheckIcon from '@atlaskit/icon/glyph/check';
import { format } from 'date-fns';

const { v4: uuidv4 } = require('uuid');

function createKey(input) {
  if (typeof input !== 'string') return uuidv4();
  return input ? input.replace(/^(the|a|an)/, '').replace(/\s/g, '') : input;
}

function isDisrupted(disrupt) {
  if (!disrupt) return null;
  return <CheckIcon />;
}

const createHeadCaseTable = (withWidth) => ({
  cells: [
    {
      key: 'Marketing airline name',
      content: 'Marketing airline name',
      isSortable: false,
      width: withWidth ? 15 : undefined,
    },
    {
      key: 'Marketing flight n°',
      content: 'Marketing flight n°',
      shouldTruncate: false,
      isSortable: false,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Operating airline name',
      content: 'Operating airline name',
      shouldTruncate: false,
      isSortable: false,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'Operating flight n°',
      content: 'Operating flight n°',
      shouldTruncate: false,
      isSortable: false,
      width: withWidth ? 8 : undefined,
    },
    {
      key: 'Date du vol',
      content: 'Date du vol',
      shouldTruncate: false,
      isSortable: false,
      width: withWidth ? 12 : undefined,
    },
    {
      key: 'Départ',
      content: 'Départ',
      shouldTruncate: false,
      isSortable: false,
      width: withWidth ? 11 : undefined,
    },
    {
      key: 'Arrivée',
      content: 'Arrivée',
      shouldTruncate: false,
      isSortable: false,
      width: withWidth ? 11 : undefined,
    },
    {
      key: 'Vol disrupté',
      content: 'Vol perturbé',
      shouldTruncate: false,
      isSortable: false,
      width: withWidth ? 8 : undefined,
    },
  ],
});

const rowsCaseTable = (data) => data.map((flight, index) => ({
  key: `row-${index}-${flight.id}`,
  cells: [
    {
      key: createKey(flight.marketing_airline.display_name),
      content: flight.marketing_airline.display_name,
    },
    {
      key: createKey(flight.marketing_airline.flight_number),
      content: flight.marketing_airline.flight_number,
    },
    {
      key: createKey(flight.operating_airline.display_name),
      content: flight.operating_airline.display_name,
    },
    {
      key: createKey(flight.flight_details.flight_number),
      content: flight.flight_details.flight_number,
    },
    {
      key: createKey(flight.flight_details.flight_date),
      content: format(new Date(flight.flight_details.flight_date), 'dd/MM/yyyy'),
    },
    {
      key: createKey(flight.flight_details.departure_airport_name),
      content: flight.flight_details.departure_airport_name,
    },
    {
      key: createKey(flight.flight_details.arrival_airport_name),
      content: flight.flight_details.arrival_airport_name,
    },
    {
      key: createKey(flight.disrupted),
      content: isDisrupted(flight.disrupted),
    },
  ],
}));

export {
  createHeadCaseTable,
  rowsCaseTable,
};
