import React from 'react';
import { AutoDismissFlag, FlagGroup } from '@atlaskit/flag';
import { colors } from '@atlaskit/theme';
import Error from '@atlaskit/icon/glyph/error';
import Info from '@atlaskit/icon/glyph/info';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Warning from '@atlaskit/icon/glyph/warning';

const iconMap = (key) => {
  const icons = {
    info: <Info
      label="Info icon"
      secondaryColor={colors.N500}
    />,
    success: <Tick
      label="Success"
      secondaryColor={colors.G400}
    />,
    warning: <Warning
      label="Warning icon"
      secondaryColor={colors.Y300}
    />,
    error: <Error
      label="Error icon"
      secondaryColor={colors.R300}
    />,
  };

  return key ? icons[key] : icons;
};

export const FlagGroupError = ({ data, dismissFlag }) => (
  <FlagGroup onDismissed={dismissFlag}>
    {data && data.map((flag) => (
      <AutoDismissFlag
        key={flag.id}
        {...flag}
        id={flag.id}
        appearance={flag.appearance}
        icon={iconMap(flag.appearance)}
      />
    ))}
  </FlagGroup>
);
