import React, { useRef } from 'react';
import { MenuItem } from '@mui/material';

const FileUploaderMenuItem = ({
  onChange, text, name, multiple = false, id, setFileLoader
}) => {


  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click(event);
  };

  const handleChange = (event) => {
    onChange(event);
    setFileLoader(true)
  };

  return (
    <>
      <MenuItem
        onClick={handleClick}
      >
        {text}
      </MenuItem>
      <input
        name={name}
        type="file"
        multiple={multiple}
        id={id}
        ref={hiddenFileInput}
        onChange={(e) => handleChange(e)}
        style={{ display: 'none' }}
      />
    </>
  );
};

export {
  FileUploaderMenuItem,
};
