import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { JuristSelector } from '../../../../ui/selector/jurists.selector';
import { Text } from '../../../../common/text/Text';

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

export const createHeadTable = (withWidth) => ({
  cells: [
    {
      key: 'juridiction',
      content: 'Juridiction',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 30 : undefined,
    },
    {
      key: 'type',
      content: 'Type',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 30 : undefined,
    },
    {
      key: 'datetime',
      content: 'Date',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 30 : undefined,
    },
    {
      key: 'nombre-de-passagers',
      content: 'Nombre de passagers',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 30 : undefined,
    },
    {
      key: 'juriste',
      content: 'Juriste assigné',
      shouldTruncate: true,
      isSortable: false,
      width: withWidth ? 30 : undefined,
    },
    {
      key: 'choose',
      content: 'Choisir',
      shouldTruncate: true,
      isSortable: false,
      width: withWidth ? 30 : undefined,
    },
  ],
});

export function createKey(input) {
  return input ? input.toString().trim() : Math.random() * (200000);
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const createRowsTable = (list, options, selectJurist, push, search) => list.map((audience, index) => ({
  key: `${createKey(audience._id)}`,
  cells: [
    {
      key: createKey(audience.jurisdiction),
      content: (<RenderGoodLinkList caseElement={audience} search={search} />),
    },
    {
      key: createKey(audience.type),
      content: (<Text color="secondary" variant="body2">{capitalizeFirstLetter(audience.type)}</Text>),
    },
    {
      key: createKey(audience.hearingDate),
      content: (
        <Text color="secondary" variant="body2">{format(new Date(audience.hearingDate), 'dd/MM/yyyy HH:mm')}</Text>),
    },
    {
      key: createKey(audience._id),
      content: (<Text color="secondary" variant="body2">{audience.passengers.length}</Text>),
    },
    {
      key: createKey(`${Math.random()}-${index}`),
      content: (
        <div>
          <ul>
            {audience.jurists && audience.jurists.map((val) => (<li key={getRandomInt(20000)}>{val.firstName}</li>))}
            {' '}
          </ul>
        </div>),
    },
    {
      key: createKey(`${Math.random()}-${index}`),
      content: (
        <div>
          <JuristSelector
            options={options}
            select={selectJurist}
            audienceId={audience._id}
            defaultSelected={audience.jurists}
          />
        </div>),

    },
  ],
}));

export const RenderGoodLinkList = ({ caseElement, search }) => (
  <Link to={{ pathname: '/audience', search: `?id=${caseElement._id}&from=audiences&${search.slice(1)}` }}>
    <p>{caseElement.jurisdiction}</p>
  </Link>
);
