import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import {
  SEARCH_CLAIM_REQUEST, search_errors, SEARCH_REQUEST, searchResponse,
} from './search.actions';

export const searchQuery = (action$, store, { SearchGateway }) => action$.pipe(
  ofType(SEARCH_REQUEST),
  mergeMap((action) => SearchGateway.query(action.payload)
    .pipe(
      map((response) => searchResponse(response.data.hits)),
      catchError((error) => of(search_errors(error.response))),
    )),
);

export const searchClaimQuery = (action$, store, { SearchGateway }) => action$.pipe(
  ofType(SEARCH_CLAIM_REQUEST),
  mergeMap((action) => SearchGateway.searchOnCrm(action.payload)
    .pipe(
      map((response) => searchResponse(response.data.hits)),
      catchError((error) => of(search_errors(error.response))),
    )),
);
