import React from 'react';
import styled from 'styled-components';
import Select from '@atlaskit/select';

export const JuristSelector = ({
  options, select, audienceId, defaultSelected = [],
}) => {
  const selected = defaultSelected ? defaultSelected.map((jurist) => jurist._id) : [];
  const defaultValue = options.filter((val) => selected.includes(val.value));
  return (
    <WrapperSelect
      className="multi-select"
      classNamePrefix="react-select"
      options={options}
      onChange={(jurist) => {
        select(jurist, audienceId);
      }}
      defaultValue={defaultValue}
      isMulti
      isSearchable
      placeholder="Assigner"
    />
  );
};

const WrapperSelect = styled(Select)`
  div {
    &:last-child {
      position: relative
    }
  }
`;
