import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import styled from 'styled-components';
import frLocale from 'date-fns/locale/fr';
import { FormControl, InputLabel } from '@mui/material';
import { useEffect, useState } from 'react';
import { isValid } from 'date-fns';
import { Spacer } from '../spacer/Spacer';

const TimePickerWithHour = ({
                              label,
                              withLabel = true,
                              placeholder,
                              dateDefault,
                              onError,
                              disabled,
                              name,
                              id,
                              ref,
                              onChange,
                              minDate,
                              padding = true,
                            }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    setSelectedDate(isValid(new Date(dateDefault)) ? dateDefault : null);
  }, [dateDefault]);

  return (
    <Container padding={padding}>
      {withLabel && <InputLabel>{label}</InputLabel>}
      {withLabel && <Spacer small/>}
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
        <FormControl fullWidth>
          <DateTimePicker
            renderInput={(params) => <TextField size="small" label={label} variant="outlined" {...params} />}
            label={placeholder}
            onError={onError}
            disabled={disabled}
            name={name}
            minDate={minDate}
            minutesStep={15}
            id={id}
            inputRef={ref}
            value={selectedDate}
            onChange={(e) => {
              const event = new Date(e)
              event.setSeconds(0)
              setSelectedDate(event);
              onChange(event);
            }}
          />
        </FormControl>
      </LocalizationProvider>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.padding ? '0 20px' : '0 5px')};
  height: ${(props) => (props.padding ? '90px' : null)};
`;

export {
  TimePickerWithHour,
};
