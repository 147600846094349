import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DynamicTable from '@atlaskit/dynamic-table';
import ConstructionIcon from '@mui/icons-material/Construction';
import { ListWrapper } from '../../../../common/layout/wrapper';
import { Breadcrumbs } from '../../../../common/breadcrumbs/breadcrumbs';
import { PageHeaderList } from '../../../../common/pageheaderlist/pageheaderlist';
import { createHeadTableAudience, createRowsTableAudience } from './tableutils';
import BarContentFilters from '../../../../casescontext/adapters/primary/filterCaseList';
import { FlagGroupError } from '../../../../common/error/error';
import { ActionsContentAudience } from './header';
import { retrieveStatusString } from '../../../../casescontext/adapters/primary/listcases/tableutils';
import { ListOfAssignedLawyers } from './components/listOfAssignedLawyers';
import { HearingDateView } from './components/hearingDateView';
import { RecallToOtherDate } from './components/recallToOtherDate';
import { EditorNote } from './components/editorNote';
import { ButtonCustom } from '../../../../common/button/button';
import { Loader } from '../../../../common/loader/loader';

const queryString = require('query-string');

const AudienceContainer = ({
  search,
  retrieveAudience,
  audience,
  selectAll,
  selectBox,
  push,
  saveInformation,
  dismissError,
  errors,
  updateStatusList,
  generateZip,
  addConvocationFromAudience,
  handleErrors,
  generateZipCCL,
  viewType,
  isLoading, updateCancellingList,
}) => {
  const { id } = queryString.parse(search);

  const [sort, setSort] = useState({
    sortKey: 'rg',
    sortOrder: 'ASC',
  });

  useEffect(() => {
    retrieveAudience(id);
  }, [id]);

  function sortByRgNumber() {
    // eslint-disable-next-line func-names
    return function (a, b) {
      const nameA = a.rg_number.toUpperCase();
      const nameB = b.rg_number.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    };
  }

  function copyToClipboard(audience) {
    const { passengers } = audience;
    passengers.sort(sortByRgNumber());

    const clipboard = passengers.map(
      (e) => `${e.fullName()} - ${e.rg_number}\n`,
    );
    copy(clipboard.join(''));
  }

  function sortByAirline() {
    // eslint-disable-next-line func-names
    return function (a, b) {
      const nameA = a.record?.ca_name_operated?.toUpperCase() || a.record.airline.operating_airline_name.toUpperCase();
      const nameB = b.record?.ca_name_operated?.toUpperCase() || b.record.airline.operating_airline_name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    };
  }

  function copyToClipboardThird(audience) {
    const { passengers } = audience;
    const clipboard = passengers.sort(sortByAirline()).map(
      (e) => `${e.fullName()} - ${e.rg_number} - ${e.record.ca_name_operated}\n`,
    );
    copy(clipboard.join(''));
  }

  function copyToClipboardNote(audience) {
    const { jurisdiction, hearingDate, passengers } = audience;

    const clipboard = passengers.sort(sortByAirline()).map(
      (e) => `${e.fullName()} - ${e.rg_number} - ${e.record.ca_name_operated} - ${retrieveStatusString(e.record.status)} \n`,
    );
    clipboard.unshift(`${jurisdiction} - ${new Date(hearingDate).toLocaleString('fr')} \n\n`);
    copy(clipboard.join(''));
  }

  const dismissFlag = () => {
    dismissError();
  };

  function recallAudience(date, handleErrors, type) {
    const passengers = audience.passengers.filter((p) => p.checked);

    if (!date) {
      return handleErrors('Merci de sélectionner une date de renvoi !');
    }
    if (passengers.length === 0) {
      return handleErrors('Merci de sélectionner des passagers !');
    }
    const freshAudience = {
      ...audience,
      passengers: audience.passengers.filter((p) => p.checked),
      hearingDate: new Date(date),
      jurists: audience.jurists ? audience.jurists.map((j) => j?._id) : [],
      type: type.value,
    };
    addConvocationFromAudience(freshAudience);
  }

  function generateCCL() {
    const passengers = audience.passengers.filter((p) => p.checked);
    if (passengers.length === 0) {
      return handleErrors('Merci de sélectionner des passagers !');
    }
    generateZipCCL(passengers);
  }

  function generateZipAudience(type) {
    const passengers = audience.passengers.filter((p) => p.checked);
    if (passengers.length === 0) {
      return handleErrors('Merci de sélectionner des passagers !');
    }
    generateZip(passengers, type);
  }

  const copyToClipboardDocsExcel = () => {
    const clipboard = audience.passengers.map((e) => `${e.firstName};${e.lastName};${e.record.booking_ref};${e.record.flight_number};${new Date(e.record.flight_date).toLocaleString('fr-FR')}`).sort().map((e) => `${e}\n`).join('');
    copy(clipboard);
  };

  const updateStatus = (status) => {
    updateStatusList(status, audience);
  };

  const updateCancelling = (cancelling) => {
    updateCancellingList(cancelling)
  }

  return (
    <div>
      {errors && <FlagGroupError data={errors} dismissFlag={dismissFlag} />}
      {audience && (
        <PageHeaderList
          actionsContent={(
            <ActionsContentAudience
              generateZip={generateZipAudience}
              generateCCL={generateCCL}
              audience={audience}
              updateStatusList={updateStatus}
              updateCancelling={updateCancelling}
            />
          )}
          breadcrumbs={(
            <Breadcrumbs
              key="AudiencesKey"
              location="/audiences"
              text="Audiences"
            />
          )}
          bottomBar={(
            <BarContentFilters
              noInitFilters
              initList={() => null}
              listSize={audience && audience.passengers.length}
              copyPaste
              copyPasteFn={() => copyToClipboard(audience)}
              copyToClipboardDocsExcel={() => copyToClipboardThird(audience)}
              copyToClipboardSaisineAll={() => copyToClipboardNote(audience)}
              contentCopyPasteSaisineAll="nom + rg + airline + statut"
              contentCopyToClipboardDocsExcel="nom + rg + airline"
              contentCopyPaste="Nom + Prénom + Numéro RG"
              copyToClipboardCloseByAh={() => copyToClipboardDocsExcel()}
              contentCopyPasteClosedByAH="Dossier Prenom + Nom - Booking ref - Numero de vol - Date de vol"
            />
          )}
          title="Passagers"
        />
      )}
      <ListWrapper>
        {audience && <HearingDateView audience={audience} />}
        {audience
          && (
            <ParamsContainer>
              {audience && audience.jurists && <ListOfAssignedLawyers audience={audience} />}
              <ButtonCustom
                background="orange"
                text="Modifier"
                endIcon={<ConstructionIcon />}
                width="100"
                onClick={() => {
                  push(`/convocation?id=${audience?._id}`);
                }}
              />
              <RecallToOtherDate
                saveRecall={recallAudience}
                handleErrors={handleErrors}
                type={audience.type}
                audience={audience}
              />
            </ParamsContainer>
          )}
        {!audience && <LoaderContainer><Loader /></LoaderContainer>}
        {audience && viewType === 'LIST' && (
          <DynamicTable
            head={createHeadTableAudience(true, selectAll)}
            rows={createRowsTableAudience(
              audience.passengers,
              audience.hearingDate,
              selectBox,
              push,
              audience?._id,
            )}
            sortKey={sort.sortKey}
            sortOrder={sort.sortOrder}
            onSort={(data) => setSort({ sortKey: data.key, sortOrder: data.sortOrder })}
            rowsPerPage={audience.passengers.length}
            isLoading={isLoading}
            defaultPage={1}
            loadingSpinnerSize="large"
            isFixedSize
            paginationi18n={{
              prev: 'Précédent',
              next: 'Suivant',
            }}
          />
        )}
        {audience && viewType === 'NOTE' && <EditorNote save={saveInformation} content={audience.information} />}
      </ListWrapper>
    </div>
  );
};

const ParamsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  AudienceContainer,
};
