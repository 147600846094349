import { useQuery } from 'react-query';
import axios from 'axios';

export function UseGetMedToSent() {
  return useQuery('getMedToSent', async () => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/stat/med`, {
        headers: {'x-access-token': localStorage.getItem('admin-token')},
      })
      .then(res => res.data)
  }, {
    refetchOnWindowFocus: false,
  })
}

export function UseGetDataRibToReset() {
  return useQuery('getDataRibToReset', async () => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/stat/rib`, {
        headers: {'x-access-token': localStorage.getItem('admin-token')},
      })
      .then(res => res.data)
  }, {
    refetchOnWindowFocus: false,
  })
}

async function claimReceivedByMonth() {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/stat/claim`, {
    headers: {'x-access-token': localStorage.getItem('admin-token')},
  });
  return data;
}

async function statCourtPending() {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/stat/court/waiting`, {
    headers: {'x-access-token': localStorage.getItem('admin-token')},
  });
  return data;
}

async function conciliationSentByMonth() {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/stat/conciliation/sent`, {
    headers: {'x-access-token': localStorage.getItem('admin-token')},
  });
  return data;
}

async function courtSentByMonth() {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/stat/court`, {
    headers: {'x-access-token': localStorage.getItem('admin-token')},
  });
  return data;
}

async function medSentByMonth() {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/stat/medsent`, {
    headers: {'x-access-token': localStorage.getItem('admin-token')},
  });
  return data;
}

async function stats() {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/stat/claim/paid/med`, {
    headers: {'x-access-token': localStorage.getItem('admin-token')},
  });
  return data;
}

async function claimPaidByMonth() {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/stat/claim/paid`, {
    headers: {'x-access-token': localStorage.getItem('admin-token')},
  });
  return data;
}

async function ribSentByMonth() {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/stat/rib/sent`, {
    headers: {'x-access-token': localStorage.getItem('admin-token')},
  });
  return data;
}

async function passengersInCourtByMonth() {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/stat/hearingdate`, {
    headers: {'x-access-token': localStorage.getItem('admin-token')},
  });
  return data;
}

async function getStatsList(params) {
  const {data: {data}} = await axios.get(
    params.airline
      ? `${process.env.REACT_APP_API_URL}/api/stat/paid?airline=${params.airline}`
      : `${process.env.REACT_APP_API_URL}/api/stat/paid`,
  );
  return data;
}

async function retrieveOperatingAirlines() {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/record/getAirlines`, {
    headers: {'x-access-token': localStorage.getItem('admin-token')},
  });
  return data;
}

async function statisticHide() {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/stat/status`, {
    headers: {'x-access-token': localStorage.getItem('admin-token')},
  });
  return data;
}

async function claimPaidByAirlinesByMonth(param, param2) {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/stat/paid/airline?year=${param.label}&status=${param2}`, {
    headers: {'x-access-token': localStorage.getItem('admin-token')},
  });
  return data;
}

async function getClaimsWithProblems() {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/stat/problems`, {
    headers: {'x-access-token': localStorage.getItem('admin-token')},
  });
  return data;
}

async function getStatPaymentCarpaByMonth(param) {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/api/stat/carpa/payment?year=${param.value}`, {
    headers: {'x-access-token': localStorage.getItem('admin-token')},
  });
  return data;
}

export function UseStatsGetStatPaidClaimByLegalEntity(param) {
  return useQuery('getStatPaidClaimByLegalEntity', async () => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/stat/legal_entity?year=${param.value}`, {
        headers: {'x-access-token': localStorage.getItem('admin-token')},
      })
      .then(res => res.data)
  }, {
    refetchOnWindowFocus: false,
  })
}

export function useStatsList(params = {airline: null}) {
  return {
    stats: useQuery('statsAcceptedBeforeLba', stats),
    claimReceivedByMonth: useQuery('claimReceivedByMonth', claimReceivedByMonth),
    statCourtPending: useQuery('statCourtPending', statCourtPending),
    conciliationSentByMonth: useQuery('conciliationSentByMonth', conciliationSentByMonth),
    courtSentByMonth: useQuery('courtSentByMonth', courtSentByMonth),
    medSentByMonth: useQuery('medSentByMonth', medSentByMonth),
    claimPaidByMonth: useQuery('claimPaidByMonth', claimPaidByMonth),
    ribSentByMonth: useQuery('ribSentByMonth', ribSentByMonth),
    passengersInCourtByMonth: useQuery('passengersInCourtByMonth', passengersInCourtByMonth),
    getStatsList: useQuery(['stats_list', params], () => getStatsList(params), {enabled: !!params}),
    airlinesList: useQuery('airlinesList', retrieveOperatingAirlines),
    statisticHide: useQuery('statisticHide', statisticHide),
    claimPaidByAirlinesByMonth: useQuery(['claimPaidByAirlinesByMonth', params], () => claimPaidByAirlinesByMonth(params.year, params.status)),
    getClaimsWithProblems: useQuery('getClaimsWithProblems', getClaimsWithProblems),
    getStatPaymentCarpaByMonth: useQuery(['getStatPaymentCarpaByMonth', params], () => getStatPaymentCarpaByMonth(params)),
  };
}
