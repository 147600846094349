import React from 'react';
import { Typography } from '@mui/material';

const Text = ({
  children, align, color, variant, component, marginBottom, gutterBottom,
}) => (
  <Typography align={align} color={color} gutterBottom={gutterBottom} component={component} paragraph={marginBottom} variant={variant}>
    {children}
  </Typography>
);

export {
  Text,
};
