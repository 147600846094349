import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { selectAutomaticsLogs, selectCase, selectCommentLoading } from '../../../../usescases/case/case.selector';
import { InformationContainer } from './informations.container';
import {
  retrieveHearingDates, retrieveLogsAutomatics,
  saveValueCase,
  sendCommentRequest, syncWithAlgoliaRequest, updateStatusClaim,
  updateValueCase,
} from '../../../../usescases/case/case.action';
import { selectCaseList } from '../../../../usescases/caseList/caselist.selector';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
  folder: selectCase(state),
  loadingComment: selectCommentLoading(state),
  listCase: selectCaseList(state),
  automaticsLogs: selectAutomaticsLogs(state),
});

const mapDispatchToProps = (dispatch) => ({
  push: (value) => dispatch(push(value)),
  updateCase: (field, value) => dispatch(updateValueCase(field, value)),
  saveValueInCase: (field, value) => dispatch(saveValueCase(field, value)),
  sendComment: (message) => dispatch(sendCommentRequest(message)),
  retrieveHearingDates: (id) => dispatch(retrieveHearingDates(id)),
  updateStatusClaim: (status, id) => dispatch(updateStatusClaim(status, id)),
  retrieveLogsAutomatics: (claimId) => dispatch(retrieveLogsAutomatics(claimId)),
  syncWithAlgolia: (claimId) => dispatch(syncWithAlgoliaRequest(claimId)),
});

const InformationsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InformationContainer);

export default InformationsPage;
