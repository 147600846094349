import React from 'react';
import { Avatar } from '@mui/material';
import avatarImg from '../../assets/avatar.png';

const AvatarDefault = () => (
  <Avatar src={avatarImg} style={{ height: '35px', width: '35px' }} />
);

export {
  AvatarDefault,
};
