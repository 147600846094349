import { connect } from 'react-redux';
import { HelpDrawerContainer } from './HelpDrawerContainer';
import { selectUser } from '../../../usercontext/usescases/login/user.selector';

const mapStateToProps = (state) => ({
  user: selectUser(state),
});

const mapDispatchToProps = () => ({});

const HelpDrawer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HelpDrawerContainer);

export default HelpDrawer;
