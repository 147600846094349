import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import styled from 'styled-components';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import Select from 'react-select';
import {
  BoxSession, CustomCtn, SelectDiv, SquareContainer,
} from './cssForComponents';
import { getRandomColor } from './statistics.container';
import { Text } from '../../common/text/Text';
import { useStatsList } from '../hooks/stat.hook';
import { Loader } from '../../common/loader/loader';
import { Spacer } from '../../common/spacer/Spacer';

const StatPaymentCarpaByMonth = () => {
  const [selectedYear, setSelectedYear] = useState({ value: 'total', label: 'Total' });
  const { getStatPaymentCarpaByMonth } = useStatsList(selectedYear);
  const { data, isLoading } = getStatPaymentCarpaByMonth;
  const [dataCourt, setDataCourt] = useState({
    labels: [], datasets: [], total: null, date: '',
  });
  const current_year = Number(format(new Date(), 'yyyy'));
  const year_1 = current_year - 2;
  const year_2 = current_year - 1;

  useEffect(() => {
    if (!data || data.length === 0) return;
    const list = data.data.filter((e) => JSON.stringify(e._id)
      !== JSON.stringify({ year: 2021, month: 10 }));

    async function getDataStat() {
      const backgroundColor = list.map((v) => {
        if (selectedYear.value === 'total') {
          switch (v._id.year) {
            case current_year:
              return '#EED590';
            case year_2:
              return '#223656';
            case year_1:
              return '#F08C4A';
            default: return getRandomColor();
          }
        } else {
          return getRandomColor();
        }
      });

      const parsingDatasByYear = (year) => {
        let label = [];
        let count = [];
        if (year === 'total') {
          label = list.map((v) => `${format(new Date(v._id.month.toString()), 'MMM', { locale: fr })} ${v._id.year}`);
          count = list.map((v) => v.count);
          return { label, count };
        }
        label = list.map((e) => format(new Date(e._id.month.toString()), 'MMM', { locale: fr }));
        count = list.map((e) => e.count);
        return { label, count };
      };

      const labels = parsingDatasByYear(selectedYear.value)?.label;
      const datas = parsingDatasByYear(selectedYear.value)?.count;

      const total = datas.reduce((a, b) => a + b, 0);
      setDataCourt({
        labels,
        date: list.date,
        total,
        datasets: [{
          data: datas,
          stack: 'stack',
          lineTension: 0.1,
          backgroundColor,
        }],
      });
    }

    getDataStat();
  }, [data, selectedYear]);

  return (
    <BoxSession>
      <Text component="h3" variant="subtitle2" color="primary">Fonds disponibles CARPA :</Text>
      <Text component="h3" variant="subtitle2" color="secondary">
        <strong>Total :</strong>
        {' '}
        {dataCourt?.total}
      </Text>
      <SelectDiv>
        <Select
          value={selectedYear}
          options={[
            { value: 'total', label: 'Total' },
            { value: current_year, label: current_year },
            { value: year_2, label: year_2 },
            { value: year_1, label: year_1 },
          ]}
          onChange={(val) => setSelectedYear(val)}
        />
      </SelectDiv>
      <Spacer medium />
      {isLoading ? <LoaderContainer><Loader /></LoaderContainer>
        : (
          <SquareContainer>
            <CustomCtn width="100%">
              <Bar
                data={dataCourt}
                width={100}
                height={50}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            </CustomCtn>
          </SquareContainer>
        )}
    </BoxSession>
  );
};

const LoaderContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  StatPaymentCarpaByMonth,
};
