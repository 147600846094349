import { Passenger } from '../../casescontext/domain/case';

function Audience({
  passengers, hearingDate, jurisdiction, _id, jurists, type, information, status,
}) {
  return {
    passengers: passengers.map((passenger) => Passenger(passenger)),
    hearingDate,
    jurisdiction,
    _id,
    jurists,
    type,
    information,
    status,
  };
}

export {
  Audience,
};
