import React, { useEffect } from 'react';
import * as qs from 'query-string';
import { ListAudiences } from './listaudiences/listaudiences.container';
import { PageHeaderList } from '../../../common/pageheaderlist/pageheaderlist';
import { ListWrapper } from '../../../common/layout/wrapper';
import { Breadcrumbs } from '../../../common/breadcrumbs/breadcrumbs';
import BarContentFilters from '../../../casescontext/adapters/primary/filterCaseList';
import MyAudiences from './myaudiences';

const AudiencesContainer = ({
                              retrieveAudiences,
                              audiences = [],
                              audienceMenuState,
                              search,
                              jurists = [],
                              getJurists,
                              addJurists, retrieveMyAudiences, myAudiences, push, loadingList,
                            }) => {
  useEffect(() => {
    getJurists();
  }, []);

  useEffect(() => {
    const query = qs.parse(search);
    const fresh = {...query};
    if ('paginateSize' in fresh) {
      retrieveAudiences(search);
      retrieveMyAudiences(search);
    }
  }, [search]);

  if (audienceMenuState === 'LIST_AUDIENCES') {
    return (
      <ListWrapper>
        <PageHeaderList
          actionsContent={null}
          breadcrumbs={<Breadcrumbs fullName="Liste des audiences" key="AudiencesKey" location="#" text="Audiences"/>}
          bottomBar={(
            <BarContentFilters
              initList={() => null}
              list={audiences}
              start
              end
              jurisdiction
              listSize={audiences ? audiences.length : 0}
            />
          )}
        />
        <ListAudiences
          push={push}
          audiences={audiences}
          loading={loadingList}
          jurists={jurists}
          addJurists={addJurists}
          search={search}
        />
      </ListWrapper>
    );
  }
  return (
    <ListWrapper>
      <PageHeaderList
        actionsContent={null}
        breadcrumbs={<Breadcrumbs fullName="Mes audiences" key="MesAudiencesKey" location="#" text="Audiences"/>}
        bottomBar={(
          <BarContentFilters
            initList={() => null}
            list={myAudiences}
            start
            end
            jurisdiction
            listSize={myAudiences ? myAudiences.length : 0}
          />
        )}
      />
      <MyAudiences search={search}/>
    </ListWrapper>
  );
};

export {
  AudiencesContainer,
};
