import React from 'react';
import { ClaimPaidByAirlinesByMonth } from './claimPaidByAirlinesByMonth';
import { Spacer } from '../../common/spacer/Spacer';
import { Multiple } from './cssForComponents';
import { GetMedToSent } from './getMedToSent';
import { GetDataRibToReset } from './getDataRibToReset';
import { ClaimReceivedByMonth } from './claimReceivedByMonth';
import { StatCourtPending } from './statCourtPending';
import { ConciliationSentByMonth } from './consiliationSentByMonth';
import { CourtSentByMonth } from './courtSentByMonth';
import { MedSentByMonth } from './medSentByMonth';
import { Stats } from './stats';
import { StatPaymentCarpaByMonth } from './statPaymentCarpaByMonth';
import { ClaimPaidByMonth } from './claimPaidByMonth';
import { RibSentByMonth } from './ribSentByMonth';
import { PassengersInCourtByMonth } from './passengersInCourtByMonth';
import { TabCompareAirlines } from './tabCompareAirlines';
import { StatisticHide } from './statisticHide';
import { GetClaimsWithProblems } from './getclaimswithproblems';
import { PaidClaimByLegalEntity } from "./paidClaimByLegalEntity";

function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

const StatisticsContainer = () => (
  <div>
    <Multiple>
      <GetMedToSent/>
      <GetDataRibToReset/>
    </Multiple>
    <ClaimReceivedByMonth/>
    <StatCourtPending/>
    <ConciliationSentByMonth/>
    <CourtSentByMonth/>
    <MedSentByMonth/>
    <Stats/>
    <StatPaymentCarpaByMonth/>
    <ClaimPaidByMonth/>
    <RibSentByMonth/>
    <PassengersInCourtByMonth/>
    <PaidClaimByLegalEntity/>
    <ClaimPaidByAirlinesByMonth title="Payées & fermées par AH :" status={[6, 17]}/>
    <ClaimPaidByAirlinesByMonth title="Fonds disponibles Carpa par CA :" status={[35]}/>
    <TabCompareAirlines/>
    <StatisticHide/>
    <GetClaimsWithProblems/>
    <Spacer small/>
  </div>
);

export {
  getRandomColor,
  groupBy,
  StatisticsContainer,
};
