import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { getState } from '../../convocationcontext/usescases/convocation.epics';
import {
  GENERATE_DOCUMENTS_LIST,
  GENERATE_GREFFE_REQUEST,
  GENERATE_ZIP, generateDocumentsListResponse,
  generateGreffeResponse,
  generateZipListResponse,
  RETRIEVE_DOCUMENTS_LIST,
  retrieveDocumentsListError,
  retrieveDocumentsListResponse,
} from './documents.action';

export const retrieveDocumentsList = (action$, store, { DocumentsGateway }) => action$.pipe(
  ofType(RETRIEVE_DOCUMENTS_LIST),
  mergeMap((action) => DocumentsGateway.retrieveCaseList(action.payload)
    .pipe(
      map((list) => retrieveDocumentsListResponse(list)),
      catchError((error) => of(retrieveDocumentsListError(error.response))),
    )),
);

export const generateZipList = (action$, store, { DocumentsGateway }) => action$.pipe(
  ofType(GENERATE_ZIP),
  mergeMap((action) => DocumentsGateway.generateZipList(getState(store).documents.list, action.payload)
    .pipe(
      map((list) => generateZipListResponse(list)),
      catchError((error) => of(retrieveDocumentsListError(error.response))),
    )),
);

export const generateCourtPostale = (action$, store, { DocumentsGateway }) => action$.pipe(
  ofType(GENERATE_GREFFE_REQUEST),
  mergeMap((action) => DocumentsGateway.generateCourtDocument(getState(store).documents.list, action.payload)
    .pipe(
      map((response) => generateGreffeResponse(response)),
      catchError((error) => of(retrieveDocumentsListError(error.response))),
    )),
);

export const generateDocumentsList = (action$, store, { DocumentsGateway }) => action$.pipe(
  ofType(GENERATE_DOCUMENTS_LIST),
  mergeMap((action) => DocumentsGateway.generateDocumentsList(getState(store).documents.list, action.payload)
    .pipe(
      map((list) => generateDocumentsListResponse(list)),
      catchError((error) => of(retrieveDocumentsListError(error.response))),
    )),
);

export const rootDocumentsEpics = combineEpics(
  retrieveDocumentsList,
  generateZipList,
  generateCourtPostale,
  generateDocumentsList,
);
