import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { MyGlobalNavigation } from './globalnavigation.container';
import { triggerSearchDrawer } from '../../../searchcontext/usescases/search.actions';
import { selectUser } from '../../../usercontext/usescases/login/user.selector';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
  user: selectUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route)),
  openSearchTab: () => dispatch(triggerSearchDrawer()),
});

const GlobalNavigation = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyGlobalNavigation);

export default GlobalNavigation;
