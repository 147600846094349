import { connect } from 'react-redux';
import { selectCase } from '../../../../usescases/case/case.selector';
import { PassengersContainer } from './passengers.container';
import {
  retrieveHearingDates,
  saveValueCase,
  updatePassengerValueRequest,
  updateValueCase,
  updateValueCasePassenger,
} from '../../../../usescases/case/case.action';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
  folder: selectCase(state),
});

const mapDispatchToProps = (dispatch) => ({
  updatePassenger: (field, value, _id) => dispatch(updateValueCasePassenger(field, value, _id)),
  savePassengerUpdate: (field, value, passengerId) => dispatch(updatePassengerValueRequest(field, value, passengerId)),
  updateValueCase: (field, value) => dispatch(updateValueCase(field, value)),
  saveValueInCase: (field, value) => dispatch(saveValueCase(field, value)),
  retrieveHearingDates: (id) => dispatch(retrieveHearingDates(id)),
});

const PassengersPages = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PassengersContainer);

export default PassengersPages;
