import React from 'react';
import { Text } from '../../../../common/text/Text';
import { CheckBoxCustom } from '../../../../common/input/inputCheckbox';

export const createHeadTableDocument = (withWidth, checkAll) => ({
  cells: [
    {
      key: 'selected',
      content: (
        <div>
          <CheckBoxCustom
            onChange={(event) => checkAll(event.target.checked)}
            name="checkbox-basic"
          />
        </div>),
      shouldTruncate: true,
      isSortable: false,
      width: withWidth ? 5 : undefined,
    },
    {
      key: 'Document',
      content: 'Document',
      shouldTruncate: true,
      isSortable: false,
      width: withWidth ? 80 : undefined,
    },
    {
      key: 'Type',
      content: 'Type',
      shouldTruncate: true,
      isSortable: false,
      width: withWidth ? 40 : undefined,
    },
    {
      key: 'created_at',
      content: 'Création',
      shouldTruncate: true,
      isSortable: false,
      width: withWidth ? 30 : undefined,
    },
    {
      key: 'updated_at',
      content: 'Dernière modification',
      shouldTruncate: true,
      isSortable: false,
      width: withWidth ? 30 : undefined,
    },
  ],
});

export function createKey(input) {
  return input ? input.toString().trim() : Math.random() * (200000);
}

export const createRowsTableDocument = (list, selectElemInList) => list.map((caseElement, index) => ({
  key: `row-${index}-${caseElement.name.trim()}`,
  cells: [
    {
      key: createKey(false),
      content: (
        <div>
          <CheckBoxCustom
            checked={caseElement.selected}
            onChange={() => selectElemInList(caseElement)}
            name="checkbox-basic"
          />
        </div>),
    },
    {
      key: createKey(caseElement.name),
      content: (<a href={caseElement.location} rel="noopener noreferrer" target="_blank"><p>{caseElement.name}</p></a>),
    },
    {
      key: createKey(caseElement.type),
      content: (<Text color="secondary" variant="body2">{caseElement.type}</Text>),
    },
    {
      key: createKey(caseElement.updated_at),
      content: (<Text color="secondary" variant="body2">{new Date(caseElement.updated_at).toLocaleString('fr')}</Text>),
    },
    {
      key: createKey(caseElement.created_at),
      content: (<Text color="secondary" variant="body2">{new Date(caseElement.created_at).toLocaleString('fr')}</Text>),
    },
  ],
}));
