import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import { Case } from '../../../../casescontext/domain/case';

function mapCaseList(cases) {
  return {
    data: {
      hits: cases.data.map((el) => Case({ ...el })),
    },
  };
}

function mapCaseListAlgolia(cases) {
  return {
    ...cases,
    data: {
      ...cases.data,
      hits: cases.data.hits.map((el) => Case({ ...el })),
    },
  };
}

export function SearchGatewayRest() {
  return {
    query(queryString) {
      return ajax.getJSON(`${process.env.REACT_APP_API_URL}/api/search/query?text=${queryString === undefined ? '' : queryString}`, {
        'x-access-token': localStorage.getItem('admin-token'),
      }).pipe(
        map((cases) => mapCaseListAlgolia(cases)),
      );
    },
    searchOnCrm(queryString) {
      return ajax.getJSON(`${process.env.REACT_APP_API_URL}/api/claim/search?airhelpID=${queryString === undefined ? '' : queryString}`, {
        'x-access-token': localStorage.getItem('admin-token'),
      }).pipe(
        map((cases) => mapCaseList(cases)),
      );
    },
  };
}
