export const RETRIEVE_CASE_LIST_REQUEST = 'RETRIEVE_CASE_LIST_REQUEST';
export const RETRIEVE_CASE_LIST_RESPONSE = 'RETRIEVE_CASE_LIST_RESPONSE';
export const RETRIEVE_CASE_LIST_ERROR = 'RETRIEVE_CASE_LIST_ERROR';
export const SELECT_ELEM_IN_LIST = 'SELECT_ELEM_IN_LIST';
export const SELECT_ALL_ELEM = 'SELECT_ALL_ELEM';
export const UPDATE_STATUS_LIST_REQUEST = 'UPDATE_STATUS_LIST_REQUEST';
export const UPDATE_STATUS_LIST_RESPONSE = 'UPDATE_STATUS_LIST_RESPONSE';
export const UPDATE_STATUS_LIST_FAILED = 'UPDATE_STATUS_LIST_FAILED';
export const DISMISS_ERROR_CASE_LIST = 'DISMISS_ERROR_CASE_LIST';
export const INIT_CASE_LIST = 'INIT_CASE_LIST';
export const ADD_AIRHELP_COMPENSATION_LIST_REQUEST = 'ADD_AIRHELP_COMPENSATION_LIST_REQUEST';
export const ADD_AIRHELP_COMPENSATION_LIST_RESPONSE = 'ADD_AIRHELP_COMPENSATION_LIST_RESPONSE';
export const ADD_AIRHELP_COMPENSATION_LIST_ERROR = 'ADD_AIRHELP_COMPENSATION_LIST_ERROR';

export const initCaseList = () => ({
  type: INIT_CASE_LIST,
});

export const retrieveCaseListRequest = (search) => ({
  type: RETRIEVE_CASE_LIST_REQUEST,
  payload: search,
});

export const retrieveCaseListResponse = (list) => ({
  type: RETRIEVE_CASE_LIST_RESPONSE,
  payload: list,
});

export const retrieveCaseListError = (error) => ({
  type: RETRIEVE_CASE_LIST_ERROR,
  payload: error,
});

export const selectElementInList = (list, elem) => ({
  type: SELECT_ELEM_IN_LIST,
  payload: { elem, list },
});

export const selectAllElemInList = (isChecked) => ({
  type: SELECT_ALL_ELEM,
  payload: isChecked,
});

export const updateStatusSelectedCaseRequest = (status) => ({
  type: UPDATE_STATUS_LIST_REQUEST,
  payload: status,
});

export const updateStatusSelectedCaseResponse = (response) => ({
  type: UPDATE_STATUS_LIST_RESPONSE,
  payload: response,
});

export const updateStatusSelectedCaseFailed = (error) => ({
  type: UPDATE_STATUS_LIST_FAILED,
  payload: error,
});

export const dismissErrorCaseList = () => ({
  type: DISMISS_ERROR_CASE_LIST,
});

export const addAirhepCompensationFinalRequest = (list) => ({
  type: ADD_AIRHELP_COMPENSATION_LIST_REQUEST,
  payload: list
})

export const addAirhepCompensationFinalResponse = (response) => ({
  type: ADD_AIRHELP_COMPENSATION_LIST_RESPONSE,
  payload: response
})

export const addAirhepCompensationFinalError = (error) => ({
  type: ADD_AIRHELP_COMPENSATION_LIST_ERROR,
  payload: error
})
