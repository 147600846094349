import axios from 'axios-observable';

const headers = {
  'Content-Type': 'application/json',
};

function UserGatewayRest() {
  return {
    loginUserApi(data) {
      const { email, password } = data;
      return axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/login`,
        {
          email,
          password,
        },
        {
          ...headers,
          responseType: 'json',
        },
      );
    },
    resetPassword() {
    },
  };
}

export {
  UserGatewayRest,
};
