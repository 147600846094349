import React from 'react';
import styled from 'styled-components';
import PageHeader from '@atlaskit/page-header';

const TitleHeader = styled.div`
text-align: left;
font-size: 25px;
`;

export const PageHeaderList = ({
  breadcrumbs, actionsContent, bottomBar, title,
}) => (
  <PageHeader
    breadcrumbs={breadcrumbs}
    actions={actionsContent}
    bottomBar={bottomBar}
  >
    <TitleHeader>{title}</TitleHeader>
  </PageHeader>
);
