import React from 'react';
import { CircularProgress } from '@mui/material';

const Loader = ({ size }) => (
  <CircularProgress color="secondary" size={size || 60} />
);

export {
  Loader,
};
