import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { ListCasesContainer } from './listcasescontainer';
import {
  addAirhepCompensationFinalRequest,
  dismissErrorCaseList,
  initCaseList,
  retrieveCaseListRequest,
  selectAllElemInList,
  selectElementInList,
  updateStatusSelectedCaseRequest,
} from '../../../usescases/caseList/caselist.action';
import {
  selectCaseErrors,
  selectCaseList,
  selectCaseListLoading,
  selectCaseListSize,
} from '../../../usescases/caseList/caselist.selector';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
  loading: selectCaseListLoading(state),
  list: selectCaseList(state),
  errors: selectCaseErrors(state),
  listSize: selectCaseListSize(state),
});

const mapDispatchToProps = (dispatch) => ({
  push: (value) => dispatch(push(value)),
  retrieveCaseList: (search) => dispatch(retrieveCaseListRequest(search)),
  selectElemInList: (list, elem) => dispatch(selectElementInList(list, elem)),
  checkAllCase: (isChecked) => dispatch(selectAllElemInList(isChecked)),
  updateStatusList: (status, list) => dispatch(updateStatusSelectedCaseRequest(status, list)),
  dismissError: () => dispatch(dismissErrorCaseList()),
  initList: () => dispatch(initCaseList()),
  addAirhelpCompensationFinal: (list) => dispatch(addAirhepCompensationFinalRequest(list))
});

const ListCases = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListCasesContainer);

export default ListCases;
