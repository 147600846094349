import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import EmptyState from '@atlaskit/empty-state';
import { ListWrapper } from '../../../../common/layout/wrapper';
import { createHeadTable, createRowsTable } from './tableutils';

const img = require('../../../../images/emptyState.png');

const MyAudiencesContainer = ({
  audiences = [], search, myHearingDateLoading, toggleFinishAudience,
}) => (
  <div>
    <ListWrapper>
      <DynamicTable
        head={createHeadTable(true)}
        rows={createRowsTable(audiences, search, toggleFinishAudience)}
        loadingSpinnerSize="large"
        isLoading={myHearingDateLoading}
        isFixedSize
        emptyView={(
          <EmptyState
            header=""
            imageUrl={img}
          />
          )}
        paginationi18n={{
          prev: 'Précédent',
          next: 'Suivant',
        }}
      />
    </ListWrapper>
  </div>
);

export {
  MyAudiencesContainer,
};
