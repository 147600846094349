import { concat, of } from 'rxjs';
import {
  catchError, map, mergeMap, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import {
  emailResetAbort,
  LOGIN_REQUEST,
  loginAbort,
  loginUserResponse,
  SEND_EMAIL_RESET_REQUEST,
  sendEmailResponse,
} from './user.action';

const loginUser = (action$, store, { UserGateway }) => action$.pipe(
  ofType(LOGIN_REQUEST),
  switchMap((action) => UserGateway.loginUserApi(action.payload)
    .pipe(
      switchMap((data) => concat(
        of(loginUserResponse(data.data)),
        of(setTimeout(() => window.location.replace('/dossiers?paginateSize=50'), 0)),
      )),
      catchError((error) => of(loginAbort(error.response.data))),
    )),
);

const resetPassword = (action$, store, { UserGateway }) => action$.pipe(
  ofType(SEND_EMAIL_RESET_REQUEST),
  mergeMap((action) => UserGateway.resetPassword(action)
    .pipe(
      map((success) => sendEmailResponse(success)),
      catchError((error) => of(emailResetAbort(error))),
    )),
);

export {
  loginUser,
  resetPassword,
};
