export const SET_STATE_CASE = 'SET_STATE_CASE';
export const RETRIEVE_CASE_ID_REQUEST = 'RETRIEVE_CASE_ID_REQUEST';
export const RETRIEVE_CASE_ID_RESPONSE = 'RETRIEVE_CASE_ID_RESPONSE';
export const RETRIEVE_CASE_ID_FAILED = 'RETRIEVE_CASE_ID_FAILED';

export const UPDATE_VALUE_CASE = 'UPDATE_VALUE_CASE';
export const SAVE_VALUE_CASE_REQUEST = 'SAVE_VALUE_CASE_REQUEST';
export const SAVE_VALUE_CASE_RESPONSE = 'SAVE_VALUE_CASE_RESPONSE';
export const SAVE_VALUE_CASE_FAILED = 'SAVE_VALUE_CASE_FAILED';

export const UPDATE_VALUE_CASE_PASSENGER = 'UPDATE_VALUE_CASE_PASSENGER';
export const UPDATE_FIELD_CASE_STATE = 'UPDATE_FIELD_CASE_STATE';

export const SELECT_DOCUMENT_FILE = 'SELECT_DOCUMENT_FILE';
export const SELECT_ALL_DOCUMENT_FILE = 'SELECT_ALL_DOCUMENT_FILE';

export const GENERATE_DOCUMENT_REQUEST = 'GENERATE_DOCUMENT_REQUEST';
export const GENERATE_DOCUMENT_RESPONSE = 'GENERATE_DOCUMENT_RESPONSE';
export const GENERATE_DOCUMENT_FAILED = 'GENERATE_DOCUMENT_FAILED';

export const REMOVE_DOCUMENTS_REQUEST = 'REMOVE_DOCUMENTS_REQUEST';
export const REMOVE_DOCUMENTS_RESPONSE = 'REMOVE_DOCUMENTS_RESPONSE';
export const REMOVE_DOCUMENTS_FAILED = 'REMOVE_DOCUMENTS_FAILED';

export const DISMISS_CASE_FLAG_ERROR = 'DISMISS_CASE_FLAG_ERROR';
export const ADD_LOG_REQUEST = 'ADD_LOG_REQUEST';
export const ADD_LOG_RESPONSE = 'ADD_LOG_RESPONSE';
export const ADD_LOG_ERROR = 'ADD_LOG_ERROR';
export const INIT_CASE = 'INIT_CASE';

export const RETRIEVE_HEARING_DATES_REQUEST = 'RETRIEVE_HEARING_DATES_REQUEST';
export const RETRIEVE_HEARING_DATES_RESPONSE = 'RETRIEVE_HEARING_DATES_RESPONSE';
export const RETRIEVE_HEARING_DATES_FAILED = 'RETRIEVE_HEARING_DATES_FAILED';

export const UPDATE_STATUS_REQUEST = 'UPDATE_STATUS_REQUEST';
export const UPDATE_STATUS_RESPONSE = 'UPDATE_STATUS_RESPONSE';
export const UPDATE_STATUS_FAILED = 'UPDATE_STATUS_FAILED';

export const GET_LOGS_AUTOMATICS = 'GET_LOGS_AUTOMATICS';
export const GET_LOGS_AUTOMATICS_RESPONSE = 'GET_LOGS_AUTOMATICS_RESPONSE';

export const SYNC_WITH_ALGOLIA_REQUEST = 'SYNC_WITH_ALGOLIA_REQUEST';
export const SYNC_WITH_ALGOLIA_RESPONSE = 'SYNC_WITH_ALGOLIA_RESPONSE';

export const retrieveLogsAutomatics = (claimId) => ({
  type: GET_LOGS_AUTOMATICS,
  payload: {
    claimId,
  },
});

export const syncWithAlgoliaRequest = (claimId) => ({
  type: SYNC_WITH_ALGOLIA_REQUEST,
  payload: {
    claimId,
  },
});

export const retrieveResponseSyncWithAlgolia = (response) => ({
  type: SYNC_WITH_ALGOLIA_RESPONSE,
  payload: {
    response,
  },
});

export const retrieveLogsAutomaticsResponse = (response) => ({
  type: GET_LOGS_AUTOMATICS_RESPONSE,
  payload: {
    response,
  },
});

export const updateStatusClaim = (status, id) => ({
  type: UPDATE_STATUS_REQUEST,
  payload: {
    status,
    id,
  },
});

export const updateStatusClaimResponse = (response) => ({
  type: UPDATE_STATUS_RESPONSE,
  payload: response,
});

export const setStateCase = (state) => ({
  type: SET_STATE_CASE,
  payload: state,
});

export const retrieveCaseById = (id) => ({
  type: RETRIEVE_CASE_ID_REQUEST,
  payload: id,
});

export const initCase = () => ({
  type: INIT_CASE,
});

export const retrieveCaseByIdResponse = (data) => ({
  type: RETRIEVE_CASE_ID_RESPONSE,
  payload: data,
});

export const caseByIdFailed = (error) => ({
  type: RETRIEVE_CASE_ID_FAILED,
  payload: error,
});

export const updateValueCase = (field, value) => ({
  type: UPDATE_VALUE_CASE,
  payload: { field, value },
});
export const saveValueCase = (field, value) => ({
  type: SAVE_VALUE_CASE_REQUEST,
  payload: { field, value },
});
export const retrieveResponseUpdateCase = () => ({
  type: SAVE_VALUE_CASE_RESPONSE,
});

export const retrieveResponseUpdateCaseFailed = (error) => ({
  type: SAVE_VALUE_CASE_FAILED,
  payload: error,
});

export const updateValueCasePassenger = (field, value, _id) => ({
  type: UPDATE_VALUE_CASE_PASSENGER,
  payload: { field, value, _id },
});

export const updatePassengerValueRequest = (field, value, _id) => ({
  type: UPDATE_FIELD_CASE_STATE,
  payload: { field, value, _id },
});

export const selectDocumentsFiles = (documents) => ({
  type: SELECT_DOCUMENT_FILE,
  payload: documents,
});

export const selectAllDocuments = () => ({
  type: SELECT_ALL_DOCUMENT_FILE,
});

export const generateDocumentRequest = (type) => ({
  type: GENERATE_DOCUMENT_REQUEST,
  payload: type,
});

export const generateDocumentsResponse = (response) => ({
  type: GENERATE_DOCUMENT_RESPONSE,
  payload: response,
});

export const generateDocumentsFailed = (error) => ({
  type: GENERATE_DOCUMENT_FAILED,
  payload: error,
});

export const removeDocumentsRequest = () => ({
  type: REMOVE_DOCUMENTS_REQUEST,
});

export const removeDocumentsResponse = (response) => ({
  type: REMOVE_DOCUMENTS_RESPONSE,
  payload: response,
});

export const removeDocumentsFailed = (error) => ({
  type: REMOVE_DOCUMENTS_FAILED,
  payload: error,
});

export const dismissErrorCase = () => ({
  type: DISMISS_CASE_FLAG_ERROR,
});

export const sendCommentRequest = (message) => ({
  type: ADD_LOG_REQUEST,
  payload: message,
});

export const getCommentResponse = (response) => ({
  type: ADD_LOG_RESPONSE,
  payload: response,
});

export const errorSendingComment = () => ({
  type: ADD_LOG_ERROR,
});

export const retrieveHearingDates = (id) => ({
  type: RETRIEVE_HEARING_DATES_REQUEST,
  payload: id,
});

export const retrieveHearingDatesResponse = (response) => ({
  type: RETRIEVE_HEARING_DATES_RESPONSE,
  payload: response,
});

export const retrieveHearingDatesFailed = (error) => ({
  type: RETRIEVE_HEARING_DATES_FAILED,
  payload: error,

});
