export const Passenger = ({
                            article_700,
                            birthDate,
                            active,
                            birthPlace,
                            boarding_pass_valid,
                            compensation,
                            compensation_received = 0,
                            email,
                            firstName,
                            gender = 2,
                            hearingDate,
                            interest,
                            job,
                            lastName,
                            lawyer,
                            nationality,
                            principal,
                            record,
                            status,
                            _id,
                            ocr,
                            address,
                            legal_representative_full_name,
                            bank_account,
                            cancelling,
                            iban,
                            ref_number,
                            rg_number = 'A définir',
                            ocr_in_house = false
                          }) => ({
  article_700,
  cancelling,
  iban,
  ref_number,
  active,
  rg_number,
  address,
  birthDate,
  birthPlace,
  boarding_pass_valid,
  compensation,
  compensation_received,
  email,
  firstName,
  gender,
  hearingDate,
  interest,
  job,
  lastName,
  legal_representative_full_name,
  lawyer,
  nationality,
  principal,
  record,
  status,
  _id,
  ocr,
  bank_account,
  ocr_in_house,
  checked: false,
  fullName() {
    return `${firstName} ${lastName}`;
  },
});

const Document = ({
                    _id,
                    location,
                    key,
                    name,
                    type,
                    record,
                    created_at,
                    updated_at,
                  }) => ({
  _id,
  location,
  key,
  name,
  type,
  record,
  created_at,
  updated_at,
  selected: false,
});

export const Case = ({
                       lastName = 'N/A',
                       firstName = 'N/A', source, airline, amount, updated_at, state = 0,
                       from_id, airport_arrival, airport_departure, booking_ref, automatics = null,
                       ca_address, legal_entity,
                       documents = [],
                       ca_name,
                       created,
                       ca_name_operated,
                       comment,
                       compensation = 0,
                       compensation_total = 0,
                       created_at,
                       delay_time,
                       objectID,
                       disrupted,
                       distance,
                       type_court_file,
                       flight_date,
                       flight_number,
                       flight_status,
                       honorary,
                       jurisdiction,
                       airport_jurisdiction,
                       language,
                       status,
                       flightInfos,
                       type,
                       contract_type,
                       passengers,
                       logs = [],
                       compensation_total_ca,
                       documentsState,
                       adverse_lawyer = {
                         name: null,
                         address: null,
                         email: null,
                       },
                       _id,
                       claimant_country,
                       bank_account,
                       final_compensation,
                       medWasSentDuringTheLastThirtyDays
                     }) => ({
  automatics,
  claimant_country,
  created,
  lastName,
  type_court_file,
  legal_entity,
  firstName,
  airline,
  amount,
  updated_at,
  objectID,
  state,
  contract_type,
  from_id,
  airport_arrival,
  airport_departure,
  booking_ref,
  ca_address,
  ca_name,
  ca_name_operated,
  comment,
  passengers: passengers.map((e) => Passenger({...e})),
  compensation,
  compensation_total_ca,
  compensation_total,
  created_at,
  delay_time,
  disrupted,
  distance,
  flight_date,
  flight_number,
  flight_status,
  honorary,
  jurisdiction,
  airport_jurisdiction,
  language,
  source,
  status,
  flightInfos,
  type,
  _id,
  adverse_lawyer,
  logs,
  documents: documents.map((e) => Document({...e})),
  fullNameMainPassenger() {
    const main_passenger = passengers.find((e) => e.principal);
    return main_passenger ? `${main_passenger.firstName} ${main_passenger.lastName}` : '';
  },
  selected: false,
  documentsState,
  bank_account,
  final_compensation,
  medWasSentDuringTheLastThirtyDays
});
