export const data = {
  STATUS: [
    {
      label: "Nouveau dossier aérien",
      value: 0,
      tasks: ["Je vérifie les pièces", "Je génère une MED et Saisine"],
    },
    {
      label: "MeD à envoyer",
      value: 1,
      tasks: ["J’envoie une MED", "J’update le statut MED sur Cockpit"],
    },
    {
      label: "MeD envoyée  (en attente de retour CA)",
      value: 2,
      tasks: [],
    },
    {
      label: "RIB à créer",
      value: 3,
      tasks: [
        "Je vérifie le montant de l’indemnité et renseigne l’article 700 et les dommages et intérêts",
        "Je crée le RIB",
        "Je télécharge la décision",
        "J’envoie la décision au confrère (CA)",
        "J’envoie le RIB au confrère (CA)",
      ],
    },
    {
      label: "RIB à envoyer",
      value: 4,
      tasks: ["J’envoie le RIB", "J’envoie le protocole (optionnel)"],
    },
    {
      label: "RIB envoyé",
      value: 5,
      tasks: [
        "Je relance et renvoie le RIB ou protocole (optionnel)",
        "Je demande le renvoi de l’audience au tribunal (uniquement si pas de paiement à une semaine de la date d’audience)",
      ],
    },
    {
      label: "Payé",
      value: 6,
      tasks: [
        "Je reçois le virement ou le chèque au bon montant",
        "Je vérifie le montant indemnité (pré-défini) et renseigne l’article 700 sur le CRM si besoin",
        "J’update Cockpit avec les informations de paiement",
        "J’envoie les conclusions de désistement au tribunal",
        "Je mandate les huissiers (optionnel)",
      ],
    },
    {
      label: "Saisine à envoyer",
      value: 7,
      tasks: ["J’envoie la saisine", "J’update le statut saisine sur Cockpit"],
    },
    {
      label: "Saisine envoyée (en attente de date d’audience)",
      value: 8,
      tasks: [
        "Je renseigne la date d’audience",
        "Je télécharge la convocation",
      ],
    },
    {
      label: "Date d’audience fixée",
      value: 9,
      tasks: ["Je demande un renvoi d’audience"],
    },
    {
      label: "Conclusions au fond à envoyer",
      value: 10,
      tasks: [
        "Je demande l’avis d’AirHelp",
        "Je prépare les conclusions au fond",
        "J’envoie les conclusions au confrère et au postulant éventuel",
      ],
    },
    {
      label: "Conclusions au fond envoyées",
      value: 11,
      tasks: [],
    },
    {
      label: "Perdu au tribunal",
      value: 12,
      tasks: ["Je télécharge la décision", "J’update Cockpit"],
    },
    {
      label: "Attente Retour AirHelp",
      value: 13,
      tasks: [
        "Je demande à AirHelp si le client a déjà été payé (la CA a dit déjà payé)",
        "Je demande à AirHelp le boarding pass",
        "(la CA réclame le boarding pass)",
        "Je demande à AirHelp la raison du retard",
        "(La CA déclare ne pas être responsable de la perturbation du vol)",
      ],
    },
    {
      label: "Paiement non conforme",
      value: 14,
      tasks: [
        "J’écris à la compagnie pour obtenir le bon montant (la CA a viré le mauvais montant)",
        "Je relance la compagnie pour obtenir le bon montant",
      ],
    },
    {
      label: "Problème Tribunal",
      value: 15,
      tasks: [
        "Je demande le relevé de caducité au tribunal (Le tribunal a renvoyé une décision de caducité)",
      ],
    },
    {
      label: "Dossier Fermé",
      value: 16,
      tasks: [
        "Le dossier est en double",
        "Le client a clôturé le dossier",
        "AirHelp a clôturé le dossier",
        "La CA a déjà indemnisé le client",
      ],
    },
    {
      label: "Dossier Fermé par AirHelp",
      value: 17,
      tasks: [],
    },
    {
      label: "Problème saisine",
      value: 20,
      tasks: ["Document à regénérer"],
    },
    {
      label: "Nouveau dossier aérien incomplet",
      value: 21,
      tasks: [],
    },
    {
      label: "Problème MED",
      value: 22,
      tasks: [],
    },
    {
      label: "Incomplet saisine",
      value: 23,
      tasks: [],
    },
    {
      label: "Attente PV NC",
      value: 24,
      tasks: [],
    },
    {
      label: "Problème TKLA",
      value: 25,
      tasks: [],
    },
    {
      label: "On Hold MED",
      value: 27,
      tasks: [],
    },
    {
      label: "Conciliation à envoyer",
      value: 28,
      tasks: [],
    },
    {
      label: "Conciliation envoyée (en attente de date de conciliation)",
      value: 29,
      tasks: [],
    },
    {
      label: "Périmé",
      value: 30,
      tasks: [],
    },
    {
      label: "Quittance envoyée (en attente de retour pax)",
      value: 31,
      tasks: [],
    },
    {
      label: "Attente de fees AH",
      value: 32,
      tasks: [],
    },
    {
      label: "Conciliateur saisi - en attente de date",
      value: 33,
      tasks: [],
    },
    {
      label: "En attente de délibéré",
      value: 34,
      tasks: [],
    },
    {
      label: "Fonds disponibles CARPA",
      value: 35,
      tasks: [],
    },
    {
      label: "Attente retour pax",
      value: 36,
      tasks: [],
    },
    {
      label: "Renvoyé sine die",
      value: 37,
      tasks: [],
    },
    {
      label: "Problème CA",
      value: 38,
      tasks: [],
    },
    {
      label: "Jugement gagné - en attente de paiement",
      value: 39,
      tasks: [],
    },
    {
      label: "A rétablir",
      value: 40,
      tasks: [],
    },
    {
      label: "Nouveau dossier amiable",
      value: 41,
      tasks: [],
    },
    {
      label: "Amiable en négociation",
      value: 42,
      tasks: [],
    },
    {
      label: "Saisine amiable à envoyer",
      value: 43,
      tasks: [],
    },
    {
      label: "Statut Feffe",
      value: 44,
      tasks: [],
    },
  ],
  TYPE: [
    {
      label: "AÉRIEN",
      value: 0,
    },
    {
      label: "AUTRES",
      value: 1,
    },
  ],
  SLA: [
    {
      label: "BACKLOG MED",
      icon: "",
      value: 0,
    },
    {
      label: "BACKLOG SAISINE",
      icon: "",
      value: 1,
    },
    {
      label: "RELANCE PAIEMENT",
      icon: "",
      value: 2,
    },
  ],
  SOURCE: [
    {
      label: "Airhelp",
      value: 0,
    },
    {
      label: "Indemniflight",
      value: 1,
    },
  ],
  AVOCAT: [
    {
      label: "Elodie",
      value: "ER",
      index: 0,
    },
    {
      label: "Gwendolineuh",
      value: "GG",
      index: 1,
    },
    {
      label: "Niels",
      value: "Niels",
      index: 3,
    },
    {
      label: "Lwyer",
      value: "Lwyer",
      index: 4,
    },
  ],
  OAG: [
    {
      label: "Oui",
      value: "oui",
    },
    {
      label: "Non",
      value: "non",
    },
  ],
  MED_30: [
    {
      label: "Oui",
      value: "oui",
    },
    {
      label: "Non",
      value: "non",
    },
  ],
  DESISTEMENT: [
    {
      label: "",
      value: 0,
    },
    {
      label: "Renvoi - transaction",
      value: 1,
    },
    {
      label: "Renvoi - pièces et infos",
      value: 2,
    },
    {
      label: "Renvoi - conclusions",
      value: 3,
    },
    {
      label: "Désistement d'instance et d'action",
      value: 4,
    },
    {
      label: "Désistement d'instance",
      value: 5,
    },
    {
      label: "Retrait du rôle",
      value: 6,
    },
    {
      label: "Radiation",
      value: 7,
    },
    {
      label: "Caducité",
      value: 8,
    },
    {
      label: "A plaider",
      value: 9,
    },
    {
      label: "Intention demandée à la partie adverse",
      value: 10,
    },
  ],
  DOCUMENTS: [
    {
      label: "Pièces justificatives",
      value: "merge",
    },
    {
      label: "Mise en demeure",
      value: "med",
    },
    {
      label: "Saisine",
      value: "saisine",
    },
    {
      label: "Générer CCL de désistement",
      value: "disclaimer",
    },
    {
      label: "Autorisation de prélèvement",
      value: "accounting",
    },
    {
      label: "Demande de data",
      value: "data",
    },
    {
      label: "Conciliation",
      value: "conciliation",
    },
  ],
  CLAIMANT_COUNTRY: [
    {
      label: "France",
      value: "fr",
    },
    {
      label: "Autre",
      value: "other",
    },
    {
      label: "Non défini",
      value: "none",
    },
  ],
  OCR: [
    {
      label: 0.1,
      value: 0.1,
    },
    {
      label: 0.2,
      value: 0.2,
    },
    {
      label: 0.3,
      value: 0.3,
    },
    {
      label: 0.4,
      value: 0.4,
    },
    {
      label: 0.5,
      value: 0.5,
    },
    {
      label: 0.6,
      value: 0.6,
    },
    {
      label: 0.7,
      value: 0.7,
    },
    {
      label: 0.8,
      value: 0.8,
    },
    {
      label: 0.9,
      value: 0.9,
    },
  ],
  REMOVE: [
    {
      label: "Supprimer",
      value: "Supprimer",
    },
  ],
  ZIP: [
    {
      label: "Générer Med",
      value: "med",
    },
    {
      label: "Générer Conciliation",
      value: "conciliation",
    },
    {
      label: "Générer Saisine",
      value: "saisine",
    },
    {
      label: "Générer AF",
      value: "zip-af",
    },
    {
      label: "Générer Debit Auth",
      value: "zip_debit_authorization",
    },
    {
      value: "rib",
      label: "Générer zip rib",
    },
  ],
  FILES: [
    {
      label: "Générer Conciliations",
      value: "conciliation",
    },
    {
      label: "Générer Saisines",
      value: "court",
    },
  ],
  GENDER: [
    {
      label: "Mr",
      value: 0,
    },
    {
      label: "Mme",
      value: 1,
    },
  ],
  PASSENGERS_NUMBER: [
    {
      label: "1",
      value: 1,
    },
    {
      label: "2",
      value: 2,
    },
    {
      label: "3 & plus",
      value: 3,
    },
  ],
  BOARDIND_PASS: [
    {
      label: "À vérifier",
      value: 0,
    },
    {
      label: "À demander",
      value: 1,
    },
    {
      label: "Demandé",
      value: 2,
    },
    {
      label: "Non",
      value: 3,
    },
    {
      label: "Oui",
      value: 4,
    },
  ],
  CONVOCATION_TYPE: [
    {
      label: "Jugement",
      value: "jugement",
    },
    {
      label: "Conciliation",
      value: "conciliation",
    },
  ],
  STATUS_LEGACY: [
    {
      label: "Nouveau dossier aérien",
      value: 0,
      tasks: ["Je vérifie les pièces", "Je génère une MED et Saisine"],
    },
    {
      label: "MeD à envoyer",
      value: 1,
      tasks: ["J’envoie une MED", "J’update le statut MED sur Cockpit"],
    },
    {
      label: "MeD envoyée  (en attente de retour CA)",
      value: 2,
      tasks: [],
    },
    {
      label: "RIB à créer",
      value: 3,
      tasks: [
        "Je vérifie le montant de l’indemnité et renseigne l’article 700 et les dommages et intérêts",
        "Je crée le RIB",
        "Je télécharge la décision",
        "J’envoie la décision au confrère (CA)",
        "J’envoie le RIB au confrère (CA)",
      ],
    },
    {
      label: "RIB à envoyer",
      value: 4,
      tasks: ["J’envoie le RIB", "J’envoie le protocole (optionnel)"],
    },
    {
      label: "RIB envoyé",
      value: 5,
      tasks: [
        "Je relance et renvoie le RIB ou protocole (optionnel)",
        "Je demande le renvoi de l’audience au tribunal (uniquement si pas de paiement à une semaine de la date d’audience)",
      ],
    },
    {
      label: "Payé",
      value: 6,
      tasks: [
        "Je reçois le virement ou le chèque au bon montant",
        "Je vérifie le montant indemnité (pré-défini) et renseigne l’article 700 sur le CRM si besoin",
        "J’update Cockpit avec les informations de paiement",
        "J’envoie les conclusions de désistement au tribunal",
        "Je mandate les huissiers (optionnel)",
      ],
    },
    {
      label: "Saisine à envoyer",
      value: 7,
      tasks: ["J’envoie la saisine", "J’update le statut saisine sur Cockpit"],
    },
    {
      label: "Saisine envoyée (en attente de date d’audience)",
      value: 8,
      tasks: [
        "Je renseigne la date d’audience",
        "Je télécharge la convocation",
      ],
    },
    {
      label: "Date d’audience fixée",
      value: 9,
      tasks: ["Je demande un renvoi d’audience"],
    },
    {
      label: "Conclusions au fond à envoyer",
      value: 10,
      tasks: [
        "Je demande l’avis d’AirHelp",
        "Je prépare les conclusions au fond",
        "J’envoie les conclusions au confrère et au postulant éventuel",
      ],
    },
    {
      label: "Conclusions au fond envoyées",
      value: 11,
      tasks: [],
    },
    {
      label: "Perdu au tribunal",
      value: 12,
      tasks: ["Je télécharge la décision", "J’update Cockpit"],
    },
    {
      label: "Attente Retour AirHelp",
      value: 13,
      tasks: [
        "Je demande à AirHelp si le client a déjà été payé (la CA a dit déjà payé)",
        "Je demande à AirHelp le boarding pass",
        "(la CA réclame le boarding pass)",
        "Je demande à AirHelp la raison du retard",
        "(La CA déclare ne pas être responsable de la perturbation du vol)",
      ],
    },
    {
      label: "Paiement non conforme",
      value: 14,
      tasks: [
        "J’écris à la compagnie pour obtenir le bon montant (la CA a viré le mauvais montant)",
        "Je relance la compagnie pour obtenir le bon montant",
      ],
    },
    {
      label: "Problème Tribunal",
      value: 15,
      tasks: [
        "Je demande le relevé de caducité au tribunal (Le tribunal a renvoyé une décision de caducité)",
      ],
    },
    {
      label: "Dossier Fermé",
      value: 16,
      tasks: [
        "Le dossier est en double",
        "Le client a clôturé le dossier",
        "AirHelp a clôturé le dossier",
        "La CA a déjà indemnisé le client",
      ],
    },
    {
      label: "Dossier Fermé par AirHelp",
      value: 17,
      tasks: [],
    },
    {
      label: "Update à faire à AH",
      value: 18,
      tasks: [],
    },
    {
      label: "Gagné Tribunal",
      value: 19,
      tasks: [],
    },
    {
      label: "Problème saisine",
      value: 20,
      tasks: ["Document à regénérer"],
    },
    {
      label: "Nouveau dossier aérien incomplet",
      value: 21,
      tasks: [],
    },
    {
      label: "Problème MED",
      value: 22,
      tasks: [],
    },
    {
      label: "Incomplet saisine",
      value: 23,
      tasks: [],
    },
    {
      label: "Attente PV NC",
      value: 24,
      tasks: [],
    },
    {
      label: "Problème TKLA",
      value: 25,
      tasks: [],
    },
    {
      label: "Problème Pièces Saisine",
      value: 26,
      tasks: [],
    },
    {
      label: "On Hold MED",
      value: 27,
      tasks: [],
    },
    {
      label: "Conciliation à envoyer",
      value: 28,
      tasks: [],
    },
    {
      label: "Conciliation envoyée (en attente de date de conciliation)",
      value: 29,
      tasks: [],
    },
    {
      label: "Périmé",
      value: 30,
      tasks: [],
    },
    {
      label: "Quittance envoyée (en attente de retour pax)",
      value: 31,
      tasks: [],
    },
    {
      label: "Attente de fees AH",
      value: 32,
      tasks: [],
    },
    {
      label: "Conciliateur saisi - en attente de date",
      value: 33,
      tasks: [],
    },
    {
      label: "En attente de délibéré",
      value: 34,
      tasks: [],
    },
    {
      label: "Fonds disponibles CARPA",
      value: 35,
      tasks: [],
    },
    {
      label: "Attente retour pax",
      value: 36,
      tasks: [],
    },
    {
      label: "Renvoyé sine die",
      value: 37,
      tasks: [],
    },
    {
      label: "Problème CA",
      value: 38,
      tasks: [],
    },
    {
      label: "Jugement gagné - en attente de paiement",
      value: 39,
      tasks: [],
    },
    {
      label: "A rétablir",
      value: 40,
      tasks: [],
    },
    {
      label: "Nouveau dossier amiable",
      value: 41,
      tasks: [],
    },
    {
      label: "Amiable en négociation",
      value: 42,
      tasks: [],
    },
    {
      label: "Saisine amiable à envoyer",
      value: 43,
      tasks: [],
    },
    {
      label: "Statut Feffe",
      value: 44,
      tasks: [],
    },
  ],
  ZIP_AUDIENCE: [
    {
      value: "conciliation",
      label: "Générer zip conciliation",
    },
    {
      value: "saisine",
      label: "Générer zip saisine",
    },
    {
      value: "rib",
      label: "Générer zip rib",
    },
  ],
  SOUS_STATUS: [
    {
      label: "En attente",
      value: 0,
    },
    {
      label: "CA ok paiement",
      value: 1,
    },
    {
      label: "Paiement ok",
      value: 2,
    },
  ],
};
