import React, { useEffect, useState } from 'react';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import styled from 'styled-components';
import SaveIcon from '@mui/icons-material/Save';
import { Spacer } from '../../../../../common/spacer/Spacer';
import { ButtonCustom } from '../../../../../common/button/button';

const toolbar = {
  options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'emoji', 'remove', 'history'],
  inline: {
    inDropdown: true,
    dropdownClassName: undefined,
    options: ['bold', 'italic', 'underline', 'strikethrough'],
  },
  fontSize: {
    options: [10, 11, 12, 14, 16, 18, 24, 30, 36, 48],
  },
  list: {
    inDropdown: true,
    options: ['unordered', 'ordered', 'indent', 'outdent'],
  },
  colorPicker: {
    colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
      'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
      'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
      'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
      'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
      'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
  },
  emoji: {
    emojis: [
      '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
      '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
      '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
      '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
      '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
      '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
      '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
      '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
      '✅', '❎', '💯',
    ],
  },
};

const EditorNote = ({ save, content }) => {
  const [internalContent, setInternalContent] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (!content) {
      return setInternalContent(EditorState.createEmpty());
    }
    setInternalContent(EditorState.createWithContent(convertFromRaw(JSON.parse(content))));
  }, [content]);

  const onContentStateChange = (contentState) => {
    setInternalContent(contentState);
  };

  const _save = (value) => save(JSON.stringify(convertToRaw(value.getCurrentContent())));
  return (
    <EditorContainer>
      <Editor
        editorState={internalContent}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onContentStateChange}
        toolbar={toolbar}
      />
      <Spacer medium />
      <ButtonCustom
        background="blue"
        size="medium"
        startIcon={<SaveIcon />}
        text="Sauvegarder"
        onClick={() => _save(internalContent)}
      />
    </EditorContainer>
  );
};

const EditorContainer = styled.div`
  div.DraftEditor-root {
    background-color: #fafafa;
    height:auto;
    min-height:200px;
    overflow-y: scroll;
    font-size: 18px;
    padding: 0 10px;
  }
`;

export {
  EditorNote,
};
