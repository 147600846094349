import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { format } from 'date-fns';
import styled from 'styled-components';
import { BoxSession, CustomCtn, SquareContainer } from './cssForComponents';
import { getRandomColor } from './statistics.container';
import { Text } from '../../common/text/Text';
import { useStatsList } from '../hooks/stat.hook';
import { Loader } from '../../common/loader/loader';

const StatCourtPending = () => {
  const { statCourtPending } = useStatsList();
  const { data, isLoading } = statCourtPending;
  const [dataCourt, setDataCourt] = useState({
    labels: [], datasets: [], total: null, date: '',
  });
  useEffect(() => {
    if (!data || data.length === 0) return;

    async function getDataStat() {
      const labels = data.data.map((v) => `${v._id}`);
      const datas = data.data.map((v) => v.count);
      const backgroundColor = data.data.map(() => getRandomColor());

      const total = datas.reduce((a, b) => a + b, 0);
      setDataCourt({
        labels,
        date: data.date,
        total,
        datasets: [{
          data: datas,
          stack: 'stack',
          label: 'conciliation',
          lineTension: 0.1,
          backgroundColor,
        }],
      });
    }

    getDataStat();
  }, [data]);

  return (
    <BoxSession>
      <Text component="h3" variant="subtitle2" color="primary">
        Saisines / Conciliations à envoyer jusqu'au
        {' '}
        {dataCourt.date && format(new Date(dataCourt.date), 'dd/MM/yyyy')}
        {' '}
        :
      </Text>
      <Text component="h3" variant="subtitle2" color="secondary">
        <strong>Total :</strong>
        {' '}
        {dataCourt.total}
      </Text>
      {isLoading ? <LoaderContainer><Loader /></LoaderContainer>
        : (
          <SquareContainer>
            <CustomCtn width="100%">
              <Bar
                data={dataCourt}
                width={100}
                height={50}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                }}
              />
            </CustomCtn>
          </SquareContainer>
        )}
    </BoxSession>
  );
};

const LoaderContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  StatCourtPending,
};
