import React, { useState } from 'react';
import Drawer from '@atlaskit/drawer';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import {
  List, Button, ListItemIcon, ListItemText, ListItem,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { debounce } from './debounce';
import { ButtonCustom } from '../../../common/button/button';
import { Loader } from '../../../common/loader/loader';

const { v4: uuidv4 } = require('uuid');

const SearchPassengersInput = ({ value, onChange, loading }) => (
  <div className="div-input-inside">
    <input
      className="input-search"
      placeholder="Rechercher..."
      value={value}
      onChange={onChange}
    />
    {loading && <Loader size="medium" />}
  </div>
);

SearchPassengersInput.propTypes = {
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  onChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
  loading: PropTypes.any,
};

const SearchDrawerContainer = ({
  triggerDrawer, query, push, isOpen, loading, data, searchClaimRequest,
}) => {
  const [value, setValue] = useState('');
  const [typeSearch, setTypeSearch] = useState('global');

  const onClose = () => {
    triggerDrawer();
  };

  const search = debounce((value) => {
    if (typeSearch === 'global') {
      query(value);
    } else if (typeSearch === 'airhelpID') {
      searchClaimRequest(value);
    }
  }, 200);

  const goToFolderId = (id) => {
    push(`/dossier?id=${id}&from=dossiers`);
    triggerDrawer();
  };

  return (
    <div style={{ padding: '0rem' }}>
      <Drawer
        onClose={onClose}
        isOpen={isOpen}
        width="wide"
      >
        <ContainerBtnSearch>
          <ButtonCustom
            background={typeSearch === 'global' ? 'blue' : 'default'}
            text="Recherche par nom"
            onClick={() => setTypeSearch('global')}
          />
          <ButtonCustom
            background={typeSearch === 'airhelpID' ? 'blue' : 'default'}
            text="Recherche par ID Airhelp"
            onClick={() => setTypeSearch('airhelpID')}
          />
        </ContainerBtnSearch>
        <ContainerSearchBox>
          <div className="box-input-search">
            <SearchPassengersInput
              value={value}
              onChange={({ target }) => {
                setValue(target.value);
                search(target.value);
              }}
              loading={loading}
            />
          </div>
          <WrapperButtonGroup>
            <List dense component="div" aria-label="contacts">
              {data.map((e) => (
                <ListItem dense button onClick={() => goToFolderId(e._id)} key={uuidv4()}>
                  <ListItemText dense>
                    {`${e.fullNameMainPassenger()} - ${e.from_id} - ${e.compensation_total} - ${e.airport_departure} - ${e.airport_arrival}`}
                  </ListItemText>
                  <ListItemIcon>
                    <ArrowForwardIcon />
                  </ListItemIcon>
                </ListItem>
              ))}
            </List>
          </WrapperButtonGroup>
        </ContainerSearchBox>
      </Drawer>
    </div>
  );
};

const ContainerBtnSearch = styled.div`
  display: flex;
  margin: 0 0 35px 0;
`;
export const WrapperButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
`;
const ContainerSearchBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
`;
export const WrapperButton = styled(Button)`
  background: whitesmoke !important;
  width: 80%;
  max-width: 90%;
  min-width: 600px;
  justify-content: flex-start;
`;

export {
  SearchPassengersInput,
  SearchDrawerContainer,
};
