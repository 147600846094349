import { applyMiddleware, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { deps } from './epicsMiddleware.redux';
import reduxReducers from './reducers.redux';

export const history = createBrowserHistory();
export const epicMiddleware = createEpicMiddleware(deps);

const reduxStore = () => createStore(
  reduxReducers(history),
  {},
  composeWithDevTools(
    applyMiddleware(routerMiddleware(history), epicMiddleware),
  ),
);

export default reduxStore;
