/*
 * Developed by quentinmalguy on 7/20/19 11:59 PM from Malguy Technology.
 * Last modified 7/20/19 11:53 PM.
 */

import { HANDLE_SUB_MENU, NAVIGATE_IN_AUDIENCE, NAVIGATE_IN_AUDIENCES } from './navigation.actions';

const initialNavigationState = {
  submenu: false,
  audiencesMenuState: 'LIST_AUDIENCES',
  audienceMenuState: 'LIST',
};

export const navigationReducer = (state = initialNavigationState, action) => {
  switch (action.type) {
    case HANDLE_SUB_MENU:
      return {
        ...state,
        submenu: !state.submenu,
      };

    case NAVIGATE_IN_AUDIENCES:
      return {
        ...state,
        audiencesMenuState: action.payload,
      };

    case NAVIGATE_IN_AUDIENCE:
      return {
        ...state,
        audienceMenuState: action.payload,
      };
    default:
      return state;
  }
};
