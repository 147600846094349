import React from 'react';
import { Link } from 'react-router-dom';
import Problem24Icon from '@atlaskit/icon-object/glyph/problem/16';
import Improvement24Icon from '@atlaskit/icon-object/glyph/improvement/16';
import Changes16Icon from '@atlaskit/icon-object/glyph/changes/16';
import { isValid } from 'date-fns';
import { getPeremptedClaim, retrieveStatusString } from '../../../casescontext/adapters/primary/listcases/tableutils';
import { Text } from '../../../common/text/Text';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import TimerIcon from '@mui/icons-material/Timer';
import { CheckBoxCustom } from '../../../common/input/inputCheckbox';

const getIcon = (type) => {
  if (type === 'yes') return <Improvement24Icon />;
  return <Problem24Icon />;
};

function getExtension(file) {
  return file.substr(file.lastIndexOf('.') + 1);
}

const RenderGoodLinkList = ({ caseElement, path }) => (
  <Link to={{ pathname: '/dossier', search: `?id=${caseElement._id}&from=documents&${path.slice(1)}` }}>
    <Text variant="body2">{caseElement.fullNameMainPassenger()}</Text>
  </Link>
);

export const createHeadTableDocuments = (withWidth, checkAll) => ({
  cells: [
    {
      key: 'selected',
      content: (
        <div>
          <CheckBoxCustom
            onChange={(event) => checkAll(event.target.checked)}
            name="checkbox-basic"
          />
        </div>),
      shouldTruncate: true,
      isSortable: false,
      width: withWidth ? 10 : undefined,
    }, {
      key: 'ID',
      content: 'ID',
      shouldTruncate: true,
      isSortable: false,
      width: withWidth ? 15 : undefined,
    }, {
      key: 'fullName',
      content: 'Prénom Nom',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 30 : undefined,
    }, {
      key: 'files',
      content: 'Docs',
      shouldTruncate: true,
      isSortable: false,
      width: withWidth ? 25 : undefined,
    }, {
      key: 'airline',
      content: 'CA',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 25 : undefined,
    }, {
      key: 'juridiction',
      content: 'Juridiction',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 25 : undefined,
    }, {
      key: 'status',
      content: 'Statut',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 25 : undefined,
    }, {
      key: 'AF',
      content: 'AF',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 15 : undefined,
    }, {
      key: 'claim_perempted',
      content: 'État',
      shouldTruncate: false,
      isSortable: true,
      width: withWidth ? 15 : undefined,
    }, {
      key: 'oag',
      content: 'OAG',
      shouldTruncate: false,
      isSortable: true,
      width: withWidth ? 15 : undefined,
    }, {
      key: 'ocr_confidence',
      content: 'OCR',
      shouldTruncate: false,
      isSortable: true,
      width: withWidth ? 15 : undefined,
    }, {
      key: 'ocr_in_house',
      content: 'Free',
      shouldTruncate: false,
      isSortable: true,
      width: withWidth ? 15 : undefined,
    }, {
      key: 'medWasSentDuringTheLastThirtyDays',
      content: 'MED30',
      shouldTruncate: false,
      isSortable: true,
      width: withWidth ? 10 : undefined,
    },
  ],
});

export function createKey(input) {
  return input ? input.toString().trim() : Math.random() * (200000);
}

function checkIfAnyPaxHasOcrValues(caseElement) {
  return caseElement.passengers.filter(pax => !pax.ocr_in_house).reduce((result, next) => {
    if (next.ocr) result = false;
    return result;
  }, true);
}

function checkIfPaxPrincipalHasAddress(pax) {
  return pax.principal && (!pax.address || pax?.address?.length <= 10 || pax.address.includes('@'));
}

function checkIfAnyPaxHasFreeOcrValues(caseElement, ocrValues) {
  return caseElement.passengers.reduce((result, pax) => {
    if (!result) return false;

    if (!pax.active) return result;


    if (checkIfPaxPrincipalHasAddress(pax)) {
      return false;
    } else if (pax.gender == null) {
      return false;
    } else if (!pax.birthPlace || !pax.birthDate || !isValid(new Date(pax.birthDate)) || !pax.job || !pax.nationality) {
      return false;
    } else if (ocrValues && (ocrValues.ocr_lowest_value >= 0.9 || (ocrValues.lower_key === 'birthPlace' && ocrValues.ocr_lowest_value >= 0.6)) && ocrValues.lower_key) {
      return true;
    }

    return result;
  }, true);
}

export function OcrDomain(
  {
    birthDate = { probability: 0 },
    birthPlace = { probability: 0 },
    nationality = { probability: 0 },
    gender = { probability: 0 },
  }) {
  return {
    birthDate,
    birthPlace,
    nationality,
    gender,
  };
}

function ocrKeyWithLowerProbability(mappedDataOcr, key, acc, next) {
  return mappedDataOcr[key].probability <= acc && (`${mappedDataOcr[key].value}` === `${next[key]}`);
}

const MyComponent = React.memo(({ ocrCheckValues }) => {
  const getValidColor = () => {
    if (ocrCheckValues && (ocrCheckValues.ocr_lowest_value >= 0.9 || (ocrCheckValues.lower_key === 'birthPlace' && ocrCheckValues.ocr_lowest_value >= 0.6)) && ocrCheckValues.lower_key) {
      return 'primary';
    }
    if (ocrCheckValues && !ocrCheckValues.lower_key && ocrCheckValues.ocr_lowest_value === 1) {
      return '';
    }
    return 'error';
  };

  function getValidIcon() {
    if (ocrCheckValues && ocrCheckValues.ocr_lowest_value !== null && ocrCheckValues.lower_key) {
      return `${ocrCheckValues.ocr_lowest_value}`;
    }
    if (ocrCheckValues && !ocrCheckValues.lower_key && ocrCheckValues.ocr_lowest_value === 1) {
      return '✅';
    } else {
      // console.log({ocrCheckValues})
      return '❌';
    }
  }

  return (
    <Text
      color={getValidColor()}
      variant="body2"
    >
      <>{getValidIcon()}</>
    </Text>
  );
});

const MyOcrComponent = React.memo(({ checkIfAnyPaxHasFreeOcrValues }) => {
  // console.log(checkIfAnyPaxHasFreeOcrValues)
  const getValidColor = () => {
    if (checkIfAnyPaxHasFreeOcrValues) {
      return 'primary';
    }
    return 'error';
  };

  function getValidIcon() {
    if (checkIfAnyPaxHasFreeOcrValues) {
      return '✅';
    }
    return '❌';
  }

  return (
    <Text
      color={getValidColor()}
      variant="body2"
    >
      <>{getValidIcon()}</>
    </Text>
  );
});

function hasNoPassenger(passengers) {
  return passengers.length === 0 || !passengers;
}

export function passengersAreFillWithoutOcr(passengers = []) {
  if (hasNoPassenger(passengers)) return false;
  const val = passengers.reduce((acc, pax) => {
    if (!pax.active) return acc;
    if (!pax.birthPlace || !isValid(new Date(pax.birthDate)) || pax.gender === null || !pax.job || !pax.nationality) {
      acc = false;
    }
    return acc;
  }, true);

  return val;
}

export const createRowsTableDocuments = (list, selectElemInList, path) => {
  const ocrCheckValues = (caseElement) => {
    if (checkIfAnyPaxHasOcrValues(caseElement)) {
      return null;
    }

    /*if (checkIfAllPassengerHasOcrValues(caseElement)) {
      return 0;
    }*/

    let lower_key = null;
    const ocr_lowest_value = caseElement.passengers.filter(pax => !pax.ocr_in_house && pax.ocr).reduce((acc, next) => {
      const mappedDataOcr = OcrDomain(next.ocr);

      Object.keys(mappedDataOcr).forEach((key) => {
        if (ocrKeyWithLowerProbability(mappedDataOcr, key, acc, next)) {
          acc = mappedDataOcr[key].probability;
          lower_key = key;
        }
      });
      return acc;
    }, 1);

    return { ocr_lowest_value, lower_key };
  };

  return list.map((caseElement, index) => {
    return (
      {
        key: `row-${index}-${caseElement._id}`,
        cells: [
          {
            key: createKey(false),
            content: (<CheckBoxCustom
              checked={caseElement.selected}
              onChange={() => selectElemInList(caseElement)}
              name="checkbox-basic"
            />),
          }, {
            key: createKey(caseElement.from_id),
            content: (<Text color="secondary" variant="body2">{caseElement.from_id}</Text>),
          }, {
            key: createKey(caseElement.fullNameMainPassenger()),
            content: (<RenderGoodLinkList caseElement={caseElement} path={path} />),
          }, {
            key: createKey(caseElement.fullNameMainPassenger()),
            content: (
              <Text color="secondary" variant="body2" style={{ margin: 0 }}>
                M :
                {' '}
                {caseElement.documents.some((e) => e.type === 'med' && getExtension(e.name) !== 'pdf')
                  ?
                  <Changes16Icon /> : caseElement.documents.some((e) => e.type === 'med') ? getIcon('yes') : getIcon('no')}
                {' '}
                C :
                {caseElement.documents.some((e) => e.type === 'conciliation' && getExtension(e.name) !== 'pdf')
                  ?
                  <Changes16Icon /> : caseElement.documents.some((e) => e.type === 'conciliation') ? getIcon('yes') : getIcon('no')}
                {' '}
                {' '}
                S :
                {caseElement.documents.some((e) => e.type === 'saisine' && getExtension(e.name) !== 'pdf')
                  ?
                  <Changes16Icon /> : caseElement.documents.some((e) => e.type === 'saisine') ? getIcon('yes') : getIcon('no')}
              </Text>),
          }, {
            key: createKey(caseElement.ca_name_operated),
            content: (<Text color="secondary" variant="body2">{caseElement.ca_name_operated}</Text>),
          }, {
            key: createKey(caseElement.jurisdiction),
            content: (<Text color="secondary" variant="body2">{caseElement.jurisdiction}</Text>),
          }, {
            key: createKey(caseElement.status),
            content: (<Text color="secondary" variant="body2">{retrieveStatusString(caseElement.status)}</Text>),
          }, {
            key: createKey(caseElement.fullNameMainPassenger()),
            content: (
              <Text
                color="secondary"
                variant="body2"
              >
                {' '}
                {caseElement.documents.some((e) => e.type === 'assignment_form_for_all_passengers' && getExtension(e.name) !== 'pdf')
                  ?
                  <Changes16Icon /> : caseElement.documents.some((e) => e.type === 'assignment_form_for_all_passengers') ? getIcon('yes') : getIcon('no')}
                {' '}

              </Text>),
          }, {
            key: createKey(caseElement.flight_date),
            content: (getPeremptedClaim(caseElement.flight_date)),
          }, {
            key: createKey(caseElement.flight_status),
            content: (
              !caseElement.flight_status &&
              <RemoveCircleOutlineIcon fontSize="small" color="error" />
            )
          }, {
            key: createKey(caseElement._id),
            content: (<MyComponent ocrCheckValues={ocrCheckValues(caseElement)} />),
          }, {
            key: createKey(caseElement._id),
            content: (<MyOcrComponent checkIfAnyPaxHasFreeOcrValues={checkIfAnyPaxHasFreeOcrValues(caseElement, ocrCheckValues(caseElement))} />),
          }, {
            key: createKey(caseElement._id),
            content: (caseElement?.medWasSentDuringTheLastThirtyDays ? <TimerIcon fontSize="small" /> : null),
          },
        ],
      })
  });
};
