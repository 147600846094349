import axios from 'axios';

axios.interceptors.response.use((response) => response, (error) => {
  if (error.response && (error.response.status === 401 || error.message === 'Request failed with status code 401')) {
    localStorage.clear();
    window.location.replace('/');
  }
  return Promise.reject(error);
});

function useAuth() {
  const token = localStorage.getItem('admin-token');
  let isLoggedIn = false;

  if (token) {
    axios.defaults.headers.common['x-access-token'] = token;
    isLoggedIn = true;
  }
  return {
    token,
    isLoggedIn,
  };
}

export {
  useAuth,
};
