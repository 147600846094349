import styled from 'styled-components';
import { FormControl } from '@mui/material';

const ItemsContainer = styled.div`
  position: relative;
  padding: 0 20px;
  width: 167px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;

  .css-1b4j8mf-MuiFormLabel-root-MuiInputLabel-root {
    top: -10px !important;
    font-weight: bold !important;
  }

  .MuiInputLabel-formControl {
    top: -10px;
    font-weight: bold !important;
  }
`;

const FormControlCustom = styled(FormControl)`
  .MuiInputLabel-formControl {
    top: -10px;
  }
`;
export {
  ItemsContainer,
  FormControlCustom,
};
