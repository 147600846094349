import React from 'react';
import * as qs from 'query-string';
import * as PropTypes from 'prop-types';
import { ContainerHeader } from '@atlaskit/navigation-next';

export const RedirectToPreviousList = ({
  push, before, text, search,
}) => {
  const redirect = () => {
    const query = qs.parse(search);
    const fresh = { ...query };
    delete fresh.id;
    delete fresh.from;
    if (query.from === 'audience') {
      delete fresh.audienceId;
      fresh.id = query.audienceId;
    }
    const querystring = qs.stringify(fresh);
    push(`/${`${query.from}?${querystring}`}`);
  };

  return (
    <ContainerHeader
      onClick={redirect}
      before={before}
      text={text}
    />
  );
};

RedirectToPreviousList.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  onClick: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  before: PropTypes.func,
};
