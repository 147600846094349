import React from 'react';
import styled from 'styled-components';
import { Input, InputLabel } from '@mui/material';
import { FormControlCustom, ItemsContainer } from './styledCssCommon';
import { RateContainer } from './rateContainer';

const InputText = ({
  label, onBlur, defaultValue, name, disabled = false, rate, rateLimit,
}) => (
  <ItemsContainer>
    {rate && <RateContainer rate={rate} rateLimit={rateLimit} />}
    <FormControlCustom fullWidth>
      <InputLabel variant="standard" style={{ fontWeight: 'bold' }}>
        {label}
      </InputLabel>
      <InputCustom
        disabled={disabled}
        variant="standard"
        onBlur={onBlur}
        type="text"
        defaultValue={defaultValue}
        name={name}
      />
    </FormControlCustom>
  </ItemsContainer>
);

const InputCustom = styled(Input)`
  color: ${(props) => (props.disabled ? '#172B4D !important' : null)}
`;

export {
  InputText,
};
