import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import styled from 'styled-components';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { BoxSession, CustomCtn, SquareContainer } from './cssForComponents';
import { getRandomColor, groupBy } from './statistics.container';
import { Text } from '../../common/text/Text';
import { useStatsList } from '../hooks/stat.hook';
import { Loader } from '../../common/loader/loader';

const Stats = () => {
  const { stats } = useStatsList();
  const { data, isLoading } = stats;

  const [dataCourt, setDataCourt] = useState({
    labels: [],
    datasets: [],
    total: null,
    date: '',
  });

  const montString = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

  useEffect(() => {
    if (!data || data.length === 0) return;

    function fillArray2019(datas) {
      return [...Array(4).fill(0), ...datas];
    }

    async function getDataStat() {
      const groupByYear = groupBy(data.data, (d) => d._id.year);
      const groupByYearSecond = groupBy(data.log, (d) => d._id.year);

      const grouped = [...groupByYear, ...groupByYearSecond];

      grouped.sort((a, b) => a[0] - b[0])

      const labels = montString.map((e) => format(new Date(e), 'MMMM', { locale: fr }));
      const datasets = grouped.map((value, i) => {
        let datas = value[1].map((v) => v.count);
        if (value[0] === 2019) {
          datas = fillArray2019(value[1].map((v) => v.count));
        }
        const backgroundColor = getRandomColor();
        return {
          data: datas,
          label: `${value[0]}-${i}`,
          fill: false,
          lineTension: 0.1,
          backgroundColor,
          borderColor: backgroundColor,
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: backgroundColor,
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: backgroundColor,
          pointHoverBorderColor: backgroundColor,
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          hidden: value[0] < (new Date().getFullYear() - 1),
        };
      });

      setDataCourt({
        labels,
        total: 0,
        datasets,
      });
    }

    getDataStat();
  }, [data]);

  return (
    <BoxSession>
      <Text component="h3" variant="subtitle2" color="primary">Acceptations des compagnies aériennes :</Text>
      {isLoading ? <LoaderContainer><Loader /></LoaderContainer>
        : (
          <SquareContainer>
            <CustomCtn width="100%">
              <Bar
                data={dataCourt}
                width={100}
                height={50}
                options={{
                  plugins: {
                    legend: {
                      display: true,
                    },
                  },
                  maintainAspectRatio: false,
                }}
              />
            </CustomCtn>
          </SquareContainer>
        )}
    </BoxSession>
  );
};

const LoaderContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  Stats,
};
