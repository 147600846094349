export const selectConvocationSearchLoading = (store) => store.convocation.loading;
export const selectConvocationSearchErrors = (store) => store.convocation.errors;
export const selectConvocationSearchedData = (store) => store.convocation.data;
export const selectConvocationSearchedSelected = (store) => store.convocation.selected;
export const selectJurisdictionConvocation = (store) => store.convocation.jurisdiction;
export const selectDateTimeConvocation = (store) => store.convocation.datetime;
export const selectAddConvocationResponse = (store) => store.convocation.success;
export const selectConvocationType = (store) => store.convocation.type;
export const selectConvocationRedirectOnUpdate = (store) => store.convocation.redirect;
export const selectedJuristConvocation = (store) => store.convocation.jurists;
