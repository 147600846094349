import React, { useEffect } from 'react';
import styled from 'styled-components';
import { StatisticsMed } from './statistics.med';
import { BoxSession, CustomCtn, SquareContainer } from './cssForComponents';
import { getRandomColor } from './statistics.container';
import { Text } from '../../common/text/Text';
import { UseGetDataRibToReset } from '../hooks/stat.hook';
import { Loader } from '../../common/loader/loader';

const GetDataRibToReset = () => {
  const queryInfo = UseGetDataRibToReset()
  const labels = queryInfo?.data?.data?.map((v) => v?._id);
  const datas = queryInfo?.data?.data?.map((v) => v?.count);
  const backgroundColor = queryInfo?.data?.data?.map(() => getRandomColor());
  const total = datas?.reduce((a, b) => a + b, 0);

  const datasets = {
    labels,
    total,
    datasets: [{
      data: datas,
      backgroundColor,
    }]
  }

  useEffect(() => {
    queryInfo.refetch().then(r => r)
  }, [])

  return (
    <BoxSession>
      <Text component="h3" variant="subtitle2" color="primary">Backlog Rib à relancer :</Text>
      <Text component="h3" variant="subtitle2" color="secondary">
        <strong>Total :</strong>
        {' '}
        {total}
      </Text>
      {queryInfo.isFetching ? <LoaderContainer><Loader/></LoaderContainer>
        : (
          <SquareContainer>
            <CustomCtn width="100%">
              <StatisticsMed data={datasets}/>
            </CustomCtn>
          </SquareContainer>
        )}
    </BoxSession>
  );
};

const LoaderContainer = styled.div`
  width: 100%;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  GetDataRibToReset,
};
