import styled from 'styled-components';

export const GlobalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 95%;
  padding: 0 24px 24px;
`;
