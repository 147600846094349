export const CLEAR_STATE = 'CLEAR_STATE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_RESPONSE = 'LOGIN_RESPONSE';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const SEND_EMAIL_RESET_REQUEST = 'SEND_EMAIL_RESET_REQUEST';
export const SEND_EMAIL_RESET_RESPONSE = 'SEND_EMAIL_RESET_RESPONSE';
export const FAILED_TO_SEND_EMAIL = 'FAILED_TO_SEND_EMAIL';

export const clearUserState = () => ({
  type: CLEAR_STATE,
});

export const loginUserRequest = (data) => ({
  type: LOGIN_REQUEST,
  payload: data,
});

export const loginUserResponse = (user) => ({
  type: LOGIN_RESPONSE,
  payload: user,
});

export const loginAbort = (error) => ({
  type: LOGIN_FAILED,
  payload: error,
});

export const sendEmailRequest = (email) => ({
  type: SEND_EMAIL_RESET_REQUEST,
  payload: email,
});

export const sendEmailResponse = (success) => ({
  type: SEND_EMAIL_RESET_RESPONSE,
  payload: success,
});

export const emailResetAbort = (error) => ({
  type: FAILED_TO_SEND_EMAIL,
  payload: error,
});
