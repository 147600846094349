import React from 'react';
import GlobalNavigation from '@atlaskit/global-navigation';
import { HeaderImg } from '../../../usercontext/adapters/primary/login/LogoLogin';
import HelpDrawer from '../helpdrawer';

function disconnect() {
  localStorage.clear();
  window.location.replace('/');
}

const MyGlobalNavigation = ({ openSearchTab, push }) => (
  <GlobalNavigation
    productTooltip="RGAVOCATS"
    onSearchClick={() => openSearchTab()}
    searchTooltip="Rechercher"
    productIcon={() => (
      <HeaderImg
        href="#"
        size="30px"
        marginBottom="inherit"
      />
    )}
    onProductClick={() => push('/dossiers?paginateSize=50')}
    settingsTooltip="Déconnexion"
    onSettingsClick={() => disconnect()}
    helpItems={() => <HelpDrawer href="#" />}
    onCreateClick={() => window.open('https://cockpit.airhelp.com/claims/structured_search', '_blank')}
    helpTooltip="Info"
  />
);

export {
  MyGlobalNavigation,
};
