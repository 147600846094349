import React from 'react';
import { Route, Switch } from 'react-router';
import MenuContainer from '../navigationcontext/adapters/navigationcontainer';
import { NotFoundPage } from '../common/NotFound';
import Statistics from '../statisticscontext/adapters';
import ListCases from '../casescontext/adapters/primary/listcases';
import CasePage from '../casescontext/adapters/primary/case';
import DocumentsPage from '../documentscontext/adapters/primary';
import ConvocationPage from '../convocationcontext/adapters/primary';
import Audiences from '../audiencecontext/adapters/primary';
import Audience from '../audiencecontext/adapters/primary/audience';
import { history } from '../configuration/store.redux';

const RenderLazy = ({ children }) => (
  <MenuContainer>
    {children}
  </MenuContainer>
);

const AuthenticatedApp = () => (
  <Switch>
    <Route
      exact
      path="/dossiers"
    >
      <RenderLazy>
        <ListCases />
      </RenderLazy>
    </Route>
    <Route
      exact
      path="/dossier"
    >
      <RenderLazy>
        <CasePage />
      </RenderLazy>
    </Route>
    <Route
      exact
      path="/documents"
    >
      <RenderLazy>
        <DocumentsPage />
      </RenderLazy>
    </Route>
    <Route
      exact
      path="/convocation"
    >
      <RenderLazy>
        <ConvocationPage />
      </RenderLazy>
    </Route>
    <Route
      exact
      path="/audiences"
    >
      <RenderLazy>
        <Audiences />
      </RenderLazy>
    </Route>
    <Route
      exact
      path="/audience"
    >
      <RenderLazy>
        <Audience />
      </RenderLazy>
    </Route>
    <Route
      exact
      path="/rapports"
    >
      <RenderLazy>
        <Statistics />
      </RenderLazy>
    </Route>
    <Route path="*">
      <NotFoundPage history={history} />
    </Route>
  </Switch>
);

export {
  AuthenticatedApp,
};
