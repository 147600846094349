import { Typography } from '@mui/material';
import React from 'react';
import { format } from 'date-fns';

const HearingDateView = ({ audience }) => (
  <Typography color="secondary" variant="h6" paragraph>
    {`Date de ${audience.type} le ${format(new Date(audience.hearingDate), 'dd/MM/yyyy HH:mm')} à ${audience.jurisdiction.toUpperCase()}`}
  </Typography>
);

export {
  HearingDateView,
};
