import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { AudiencesContainer } from './audiences.container';
import {
  addJurists,
  retrieveAudiences,
  retrieveJurists,
  retrieveMyAudiences,
} from '../../usecases/audience.actions';
import {
  selectAudiences,
  selectJurists,
  selectListAudienceLoading,
  selectMyAudiences,
} from '../../usecases/audience.selector';
import { selectAudiencesMenuState } from '../../../navigationcontext/usecases/navigation.selector';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
  audiences: selectAudiences(state),
  audienceMenuState: selectAudiencesMenuState(state),
  jurists: selectJurists(state),
  myAudiences: selectMyAudiences(state),
  loadingList: selectListAudienceLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  push: (value) => dispatch(push(value)),
  retrieveAudiences: (query) => dispatch(retrieveAudiences(query)),
  getJurists: () => dispatch(retrieveJurists()),
  addJurists: (jurists, audienceId) => dispatch(addJurists(jurists, audienceId)),
  retrieveMyAudiences: (search) => dispatch(retrieveMyAudiences(search)),
});

const Audiences = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudiencesContainer);

export default Audiences;
