import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { DashboardLayoutContainer } from './navigation.container';
import { setStateCase } from '../../../casescontext/usescases/case/case.action';
import { selectCase } from '../../../casescontext/usescases/case/case.selector';
import {
  selectAudienceMenuState,
  selectAudiencesMenuState,
  selectSubMenuIsOpen,
} from '../../usecases/navigation.selector';
import { handleSubMenu, navigateInAudience, navigateInAudiences } from '../../usecases/navigation.actions';
import { clearConvocation } from '../../../convocationcontext/usescases/convocation.actions';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
  dossier: selectCase(state),
  isOpen: selectSubMenuIsOpen(state),
  audiencesMenuState: selectAudiencesMenuState(state),
  audienceMenuState: selectAudienceMenuState(state),
});

const mapDispatchToProps = (dispatch) => ({
  navigateInCase: (type) => dispatch(setStateCase(type)),
  handleSubMenuAudience: () => dispatch(handleSubMenu()),
  navigateInAudiences: (state) => dispatch(navigateInAudiences(state)),
  navigateInAudience: (state) => dispatch(navigateInAudience(state)),
  clearConvocation: () => dispatch(clearConvocation()),
  push: (value) => dispatch(push(value)),
});

const MenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardLayoutContainer);

export default MenuContainer;
