import {
  GENERATE_DOCUMENTS_LIST, GENERATE_DOCUMENTS_LIST_RESPONSE,
  GENERATE_GREFFE_REQUEST,
  GENERATE_GREFFE_RESPONSE,
  GENERATE_ZIP,
  GENERATE_ZIP_ERROR,
  GENERATE_ZIP_RESPONSE,
  INIT_LIST_DOCS,
  RETRIEVE_DOCUMENTS_LIST,
  RETRIEVE_DOCUMENTS_LIST_RESPONSE,
  SELECT_ALL_ELEM_IN_DOCUMENTS_LIST,
  SELECT_ELEM_IN_DOCUMENTS_LIST,
} from './documents.action';

const initialDocumentsState = {
  loading: false,
  errors: {
    generateZip: [],
  },
  success: {
    generateZip: [],
  },
  list: [],
  listSize: 0,
};

export const documentsReducer = (state = initialDocumentsState, action) => {
  switch (action.type) {
    case RETRIEVE_DOCUMENTS_LIST:
      return {
        ...state,
        loading: true,
      };

    case GENERATE_DOCUMENTS_LIST:
      return {
        ...state,
        loading: true,
      };

    case GENERATE_DOCUMENTS_LIST_RESPONSE:
      return {
        ...state,
        loading: false,
      };

    case INIT_LIST_DOCS:
      return {
        ...state,
        list: [],
      };

    case RETRIEVE_DOCUMENTS_LIST_RESPONSE:
      return {
        ...state,
        loading: false,
        list: [...state.list, ...action.payload.data],
        listSize: action.payload.size,
      };

    case SELECT_ELEM_IN_DOCUMENTS_LIST:
      const freshList = {
        ...state,
        list: state.list.map((elem) => ({
          ...elem,
          selected: elem._id === action.payload.elem._id ? !elem.selected : elem.selected,
        })),
      };
      return {
        ...freshList,
      };

    case SELECT_ALL_ELEM_IN_DOCUMENTS_LIST:
      const freshState = {
        ...state,
        list: state.list.map((elem) => ({
          ...elem,
          selected: action.payload,
        })),
      };
      return {
        ...freshState,
      };

    case GENERATE_ZIP:
      return {
        ...state,
        loading: true,
      };

    case GENERATE_ZIP_RESPONSE:
      const blob = new Blob([action.payload.response], { type: 'application/zip' });
      const url = URL.createObjectURL(blob);
      try {
        let windowObjectReference = null;
        const strWindowFeatures = 'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
        // eslint-disable-next-line no-unused-vars
        windowObjectReference = window.open(url, 'ZIP_WindowName', strWindowFeatures);
      } catch (e) {
        console.log('error', e);
      }
      return {
        ...state,
        loading: false,
      };

    case GENERATE_ZIP_ERROR:
      return {
        ...state,
      };

    case GENERATE_GREFFE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GENERATE_GREFFE_RESPONSE: {
      const blob = new Blob([action.payload.response], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
      const url = URL.createObjectURL(blob);
      try {
        let windowObjectReference = null;
        const strWindowFeatures = 'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
        // eslint-disable-next-line no-unused-vars
        windowObjectReference = window.open(url, 'ZIP_WindowName', strWindowFeatures);
      } catch (e) {
        console.log('error', e);
      }
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
};
