import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { MyAudiencesContainer } from './myaudiences.container';
import { selectMyAudiences, selectMyHearingDateLoading } from '../../../usecases/audience.selector';
import { toggleFinishRequest } from '../../../usecases/audience.actions';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
  audiences: selectMyAudiences(state),
  myHearingDateLoading: selectMyHearingDateLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  push: (value) => dispatch(push(value)),
  toggleFinishAudience: (audienceId) => dispatch(toggleFinishRequest(audienceId)),
});

const MyAudiences = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyAudiencesContainer);

export default MyAudiences;
