import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import {
  Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper,
} from '@mui/material';

const ButtonActionGroup = ({
  update, data, type, title, disabled,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <>
      <ButtonCustomForThisComponent
        ref={anchorRef}
        disabled={disabled}
        size="small"
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {title}
      </ButtonCustomForThisComponent>
      <PopperCustom open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <PaperCustom>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>

                  {data[type].map((e) => (
                    <MenuItem
                      onClick={() => {
                        update(e.value);
                        handleClose(e);
                      }}
                      id={e.label}
                      dense
                      key={e.value}
                    >
                      {e.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </PaperCustom>
          </Grow>
        )}
      </PopperCustom>
    </>
  );
};

const ButtonCustomForThisComponent = styled(Button)`
  text-transform: inherit !important;
  margin: 0 5px !important;
  background: ${(props) => (props.disabled ? '#e4e4e4' : '#172B4D')} !important;
  color: #ffffff !important;
  box-shadow: ${(props) => (props.disabled ? null : '0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)')} !important;
`;

const PopperCustom = styled(Popper)`
  z-index: 99999999 !important;
  width: auto !important;
  left: 0 !important;
`;

const PaperCustom = styled(Paper)`
  max-height: 300px !important;
  height: auto !important;
  overflow: auto !important;
`;

export {
  ButtonActionGroup,
};
