import { createTheme } from '@mui/material/styles';
import {
  deepPurple, green, lightBlue, lightGreen, orange, red,
} from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0052CC',
    },
    secondary: {
      main: '#172B4D',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Oxygen"',
      '"Ubuntu"',
      '"Cantarell"',
      'Fira Sans',
      ' "Droid Sans"',
      '"Helvetica Neue"',
      'sans-serif',
    ].join(','),
  },
  red: red[500],
  purple: deepPurple[600],
  default: '#f6f7f9',
  orange: orange[500],
  green: green[500],
  lightGreen: lightGreen[300],
  lightBlue: lightBlue[300],
  blue: '#0052CC',
  dark: '#172B4D',
});

export {
  theme,
};
