import React from 'react';
import { NavLink } from 'react-router-dom';
import { Item } from '@atlaskit/navigation-next';
import FolderIcon from '@atlaskit/icon/glyph/folder';
import DocumentsIcon from '@atlaskit/icon/glyph/documents';
import ScheduleIcon from '@atlaskit/icon/glyph/schedule';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import GraphLineIcon from '@atlaskit/icon/glyph/graph-line';

const NestedSectionWrapper = (props) => (
  <div
    {...props}
  />
);

function isCurrentUrl(url) {
  return window.location.pathname === url;
}

const Container = (props) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    }}
    {...props}
  />
);

function disableLinkIfCurrentUrl(e, url) {
  if (isCurrentUrl(url)) return e.preventDefault();
}

const getCurrentQueryParams = () => {
  return window.location.search;
};

const MenuSectionContainer = ({ handleSubMenuAudience, className }) => {
  const queryParams = getCurrentQueryParams();

  return (
    <Container>
      <NestedSectionWrapper>
        <div className={className} style={{ fontSize: '14px' }}>
          <NavLink
            to={`/dossiers${queryParams}`}
            activeClassName="selected-route"
            onClick={(e) => disableLinkIfCurrentUrl(e, `/dossiers${queryParams}`)}
          >
            <Item
              before={FolderIcon}
              text="Dossiers"
              isSelected={isCurrentUrl(`/dossiers`)}
            />
          </NavLink>
          <NavLink
            to={`/documents${queryParams}`}
            activeClassName="selected-route"
            onClick={(e) => disableLinkIfCurrentUrl(e, `/documents`)}
          >
            <Item
              before={DocumentsIcon}
              text="Documents"
              isSelected={isCurrentUrl(`/documents`)}
            />
          </NavLink>
          <NavLink
            to={`/convocation${queryParams}`}
            activeClassName="selected-route"
            onClick={(e) => disableLinkIfCurrentUrl(e, `/convocation`)}
          >
            <Item
              before={ScheduleIcon}
              text="Convocation"
              isSelected={isCurrentUrl(`/convocation`)}
            />
          </NavLink>
          <NavLink
            to={`/audiences${queryParams}`}
            activeClassName="selected-route"
            onClick={(e) => {
              handleSubMenuAudience();
              disableLinkIfCurrentUrl(e, `/audiences`);
            }}
          >
            <Item
              before={CalendarIcon}
              text="Audiences"
              isSelected={isCurrentUrl(`/audiences`)}
            />
          </NavLink>
          <NavLink
            to={`/rapports${queryParams}`}
            activeClassName="selected-route"
            onClick={(e) => disableLinkIfCurrentUrl(e, `/rapports`)}
          >
            <Item
              before={GraphLineIcon}
              text="Rapports"
              isSelected={isCurrentUrl(`/rapports`)}
            />
          </NavLink>
        </div>
      </NestedSectionWrapper>
    </Container>
  );
};


export {
  MenuSectionContainer,
};
