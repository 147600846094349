import { concat, of } from 'rxjs';
import {
  catchError, delay, map, mergeMap, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { getState } from '../../../convocationcontext/usescases/convocation.epics';
import {
  ADD_LOG_REQUEST,
  caseByIdFailed,
  errorSendingComment,
  GENERATE_DOCUMENT_REQUEST,
  generateDocumentsFailed,
  generateDocumentsResponse, GET_LOGS_AUTOMATICS,
  getCommentResponse,
  initCase,
  REMOVE_DOCUMENTS_REQUEST,
  removeDocumentsFailed,
  removeDocumentsResponse,
  RETRIEVE_CASE_ID_REQUEST,
  RETRIEVE_HEARING_DATES_REQUEST,
  retrieveCaseById,
  retrieveCaseByIdResponse,
  retrieveHearingDatesFailed,
  retrieveHearingDatesResponse, retrieveLogsAutomaticsResponse, retrieveResponseSyncWithAlgolia,
  retrieveResponseUpdateCase,
  retrieveResponseUpdateCaseFailed,
  SAVE_VALUE_CASE_REQUEST, SYNC_WITH_ALGOLIA_REQUEST,
  UPDATE_FIELD_CASE_STATE, UPDATE_STATUS_REQUEST, updateStatusClaimResponse,
} from './case.action';

export const retrieveCaseByIdEpic = (action$, store, { CaseListGateway }) => action$.pipe(
  ofType(RETRIEVE_CASE_ID_REQUEST),
  mergeMap((action) => CaseListGateway.retrieveCaseById(action.payload)
    .pipe(
      mergeMap((data) => concat(
        of(initCase()),
        of(retrieveCaseByIdResponse(data)),
      )),
      catchError((error) => of(caseByIdFailed(error.response))),
    )),
);

export const syncWithAlgolia = (action$, store, { CaseListGateway }) => action$.pipe(
  ofType(SYNC_WITH_ALGOLIA_REQUEST),
  mergeMap((action) => CaseListGateway.syncWithAlgolia(action.payload)
    .pipe(
      mergeMap((data) => concat(
        of(retrieveResponseSyncWithAlgolia(data)),
      )),
      catchError((error) => of(caseByIdFailed(error.response))),
    )),
);

export const updateSinglePassenger = (action$, store, { CaseListGateway }) => action$.pipe(
  ofType(UPDATE_FIELD_CASE_STATE),
  mergeMap((action) => CaseListGateway.updateSinglePassenger(action.payload._id, { [action.payload.field]: action.payload.value })
    .pipe(
      mergeMap((data) => concat(
        of(retrieveResponseUpdateCase(data)),
      )),
      catchError((error) => of(caseByIdFailed(error.response))),
    )),
);

export const updateSingleCase = (action$, store, { CaseListGateway }) => action$.pipe(
  ofType(SAVE_VALUE_CASE_REQUEST),
  mergeMap((action) => CaseListGateway.updateSingleCase(getState(store).case.case._id, { [action.payload.field]: action.payload.value })
    .pipe(
      mergeMap((data) => concat(
        of(retrieveResponseUpdateCase(data)),
        of(retrieveCaseById(getState(store).router.location.search)).pipe(delay(500)),
      )),
      catchError((error) => of(retrieveResponseUpdateCaseFailed(error.response))),
    )),
);

export const generateDocuments = (action$, store, { CaseListGateway }) => action$.pipe(
  ofType(GENERATE_DOCUMENT_REQUEST),
  switchMap((action) => CaseListGateway.generateDocuments(getState(store).case.case, action.payload)
    .pipe(
      mergeMap((data) => concat(
        of(generateDocumentsResponse(data.response)),
        of(retrieveCaseById(getState(store).router.location.search)).pipe(delay(1000)),
      )),
      catchError((error) => of(generateDocumentsFailed(error.response))),
    )),
);

export const removeDocuments = (action$, store, { CaseListGateway }) => action$.pipe(
  ofType(REMOVE_DOCUMENTS_REQUEST),
  mergeMap(() => CaseListGateway.removeDocuments(getState(store).case.case)
    .pipe(
      map((data) => removeDocumentsResponse(data)),
      catchError((error) => of(removeDocumentsFailed(error.response))),
    )),
);

export const addComment = (action$, store, { CaseListGateway }) => action$.pipe(
  ofType(ADD_LOG_REQUEST),
  switchMap((action) => CaseListGateway.addComment(getState(store).case.case, action.payload)
    .pipe(
      mergeMap((data) => concat(
        of(getCommentResponse(data.response)),
        of(retrieveCaseById(getState(store).router.location.search)).pipe(delay(1000)),
      )),
      catchError((error) => of(errorSendingComment(error.response))),
    )),
);

export const retrieveHearingDates = (action$, store, { CaseListGateway }) => action$.pipe(
  ofType(RETRIEVE_HEARING_DATES_REQUEST),
  mergeMap((action) => CaseListGateway.retrieveHearingDates(action.payload)
    .pipe(
      map((data) => retrieveHearingDatesResponse(data)),
      catchError((error) => of(retrieveHearingDatesFailed(error.response))),
    )),
);

export const updateStatusClaim = (action$, store, { CaseListGateway }) => action$.pipe(
  ofType(UPDATE_STATUS_REQUEST),
  mergeMap((action) => CaseListGateway.updateStatusClaim(action.payload.status, action.payload.id)
    .pipe(
      map((response) => updateStatusClaimResponse(response)),
      map(() => retrieveCaseById(getState(store).router.location.search)),
      catchError((error) => of(console.log(error))),
    )),
);

export const getAutomaticsLogs = (action$, store, { CaseListGateway }) => action$.pipe(
  ofType(GET_LOGS_AUTOMATICS),
  mergeMap((action) => CaseListGateway.getAutomaticsLogs(action.payload.claimId)
    .pipe(
      map((data) => retrieveLogsAutomaticsResponse(data.data)),
      catchError((error) => of(console.log(error.response))),
    )),
);
