import { format } from 'date-fns';
import { Workbook } from 'exceljs';
import React from 'react';
import * as fs from 'file-saver';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { ButtonCustom } from '../../../../common/button/button';

const ButtonGenerateExcelJuridiction = ({ list, Juridiction }) => {
  const title = (char) => {
    if (Juridiction) {
      return `Liste des dossiers envoyés à ${Juridiction} - ${format(new Date(), `dd${char}MM${char}yyyy`)}`;
    } return `Liste des dossiers - ${format(new Date(), `dd${char}MM${char}yyyy`)}`;
  };

  const downloadExcel = (data) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(title('-'));
    const header = ['Juridiction', 'Nom du demandeur', 'Adresse postale'];
    worksheet.getRow(3).values = header;
    const fName = title('/');

    worksheet.getRow(1).font = {
      size: 16,
      bold: true,
      color: { argb: 'FFFFFFFF' },
    };

    worksheet.getRow(3).alignment = { vertical: 'top', horizontal: 'left' };

    worksheet.getRow(3).font = {
      size: 14,
      bold: true,
    };

    worksheet.getCell('A1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF0052CC' },
    };

    worksheet.columns = [
      { width: 25 },
      { width: 40 },
      { width: 50 },
    ];

    for (const x1 of data) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of x2) {
        temp.push(x1[y]);
      }
      worksheet.addRow(temp);
    }

    worksheet.mergeCells('A1', 'C1');
    worksheet.getCell('A1').value = title('/');

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, `${fName}-${new Date().valueOf()}.xlsx`);
    });
  };

  const displayListPassengers = (e) => {
    const list = e.passengers.map((e) => e.fullName());
    return list.join().replaceAll(',', '\n');
  };

  const DataExcelAudience = (data) => data.map((e) => ({
    jurisdiction: e.jurisdiction,
    name: displayListPassengers(e),
    address: e.passengers[0].address ? e.passengers[0].address : '...',
  }));

  return (
    <ButtonCustom
      disabled={list.length === 0 || Juridiction === undefined}
      background="purple"
      text="Excel juridiction"
      startIcon={<LocationCityIcon />}
      onClick={() => downloadExcel(DataExcelAudience(list))}
    />
  );
};

export {
  ButtonGenerateExcelJuridiction,
};
