import React from 'react';
import { Breadcrumbs as BreadcrumbsCustom, Link } from '@mui/material';
import styled from 'styled-components';
import { Spacer } from '../spacer/Spacer';
import { Text } from '../text/Text';
import globe from '../../assets/globe.png';
import LinkIcon from '@mui/icons-material/Link';

const Breadcrumbs = ({text, location, fullName, id, from_id, folder}) => (
  <>
    <BreadcrumbsCustom>
      <LinkCustom color="inherit" key="RGAVOCATS">
        RGAVOCATS
      </LinkCustom>
      <LinkCustom color="inherit" onClick={() => window.location = location} key={text}>
        {text}
      </LinkCustom>
      {fullName
        && (
          <LinkCustom key={fullName} color="inherit">
            {fullName}
          </LinkCustom>
        )}
      {id
        && (
          <LinkCustom key={id} color="inherit">
            {id}
          </LinkCustom>
        )}
    </BreadcrumbsCustom>
    <Spacer small/>
    <Container>
      <Text color="secondary" variant="h5">{fullName}</Text>
      {folder && folder.type_court_file && folder.type_court_file === true
        && (
          <GlobeContainer>
            <img src={globe} alt="globe"/>
          </GlobeContainer>
        )}
    </Container>
    {from_id
      && (
        <LinkContainer>
          <Container>
            <LinkIcon fontSize="small" color="success" sx={{marginRight: '3px'}}/>
            <LinkAirHelp color="success.main" target="_blank"
                         href={`https://extlawyers.airhelp.com/claim_cases/${from_id}`}>
              Lemur
            </LinkAirHelp>
          </Container>
        </LinkContainer>
      )}
  </>
);

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const GlobeContainer = styled.div`
  margin-left: 10px;

  img {
    width: 25px;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
`;

const LinkCustom = styled(Link)`
  font-size: 14px;
  cursor: pointer;
  transition: opacity 0.3s ease 0s;
  text-decoration: none !important;
  opacity: 1;

  :hover {
    opacity: 0.8;
  }
`;

const LinkAirHelp = styled(Link)`
  font-size: 14px;
  transition: opacity 0.3s ease 0s;
  opacity: 1;
  text-decoration: none !important;

  :hover {
    opacity: 0.8;
    text-decoration: underline !important;
  }
`;

export {
  Breadcrumbs,
};
