import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { BarContentFiltersContainer } from './BarContentFiltersContainer';
import {
  retrieve_jurisdictions_request,
  retrieve_operated_airlines_request,
} from '../../../usescases/filtersList/filterlist.action';
import {
  selectFiltersListErrors,
  selectFiltersListLoading,
  selectJurisdictions,
  selectOperatingAirlines,
} from '../../../usescases/filtersList/filterslist.selector';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
  operating_airlines: selectOperatingAirlines(state),
  jurisdictions: selectJurisdictions(state),
  errors: selectFiltersListErrors(state),
  loading: selectFiltersListLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  push: (value) => dispatch(push(value)),
  retrieve_operated_airlines: () => dispatch(retrieve_operated_airlines_request()),
  retrieve_jurisdictions: () => dispatch(retrieve_jurisdictions_request()),
});

const BarContentFilters = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BarContentFiltersContainer);

export default BarContentFilters;
