import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';

const Lozenge = ({
  text, blue, red, yellow, green,
}) => {
  if (blue) {
    return (
      <TextCustomBlue>
        {text}
      </TextCustomBlue>
    );
  }
  if (red) {
    return (
      <TextCustomRed>
        {text}
      </TextCustomRed>
    );
  }
  if (yellow) {
    return (
      <TextCustomYellow>
        {text}
      </TextCustomYellow>
    );
  }
  if (green) {
    return (
      <TextCustomGreen>
        {text}
      </TextCustomGreen>
    );
  }
};

const TextCustomBlue = styled(Typography)`
  font-size: 11px !important;
  font-weight: 700 !important;
  display: inline-block !important;
  line-height: 1 !important;
  color: #0747A6 !important;
  padding: 2px 4px 3px 4px !important;
  background-color: #DEEBFF !important;
  border-radius: 3px !important;
  box-sizing: border-box !important;
  max-width: 100% !important;
  text-transform: uppercase !important;
  vertical-align: baseline !important;
`;

const TextCustomRed = styled(TextCustomBlue)`
  color: #be2605 !important;
  background-color: #ffebe7 !important;
`;

const TextCustomYellow = styled(TextCustomBlue)`
  color: #182c46 !important;
  background-color: #fff0b4 !important;
`;

const TextCustomGreen = styled(TextCustomBlue)`
  color: #066543 !important;
  background-color: #e2fcef !important;
`;

export {
  Lozenge,
};
