import React from 'react';
import {
  ContainerHeader, HeaderSection, Item, MenuSection,
} from '@atlaskit/navigation-next';
import ArrowLeftCircleIcon from '@atlaskit/icon/glyph/arrow-left-circle';
import EditorAlignLeftIcon from '@atlaskit/icon/glyph/editor/align-left';
import DocumentsIcon from '@atlaskit/icon/glyph/documents';

const AudiencesSubmenu = ({ goBack, navigateInAudience, audienceMenuState = 'LIST_AUDIENCES' }) => (
  <>
    <HeaderSection>
      {({ css }) => (
        <div style={{ ...css, paddingBottom: 20 }}>
          <ContainerHeader
            before={() => (<ArrowLeftCircleIcon primary="#0747A6" />)}
            text="Quitter les audiences"
            onClick={() => goBack()}
          />
        </div>
      )}
    </HeaderSection>
    <MenuSection>
      {({ className }) => (
        <div className={className}>
          <Item
            before={() => (<EditorAlignLeftIcon />)}
            onClick={() => navigateInAudience('LIST_AUDIENCES')}
            text="Listes des audiences"
            isSelected={audienceMenuState === 'LIST_AUDIENCES'}
          />
          <Item
            before={() => (<DocumentsIcon />)}
            onClick={() => navigateInAudience('MY_AUDIENCES')}
            text="Mes audiences"
            isSelected={audienceMenuState === 'MY_AUDIENCES'}
          />
        </div>
      )}
    </MenuSection>
  </>
);

export {
  AudiencesSubmenu,
};
