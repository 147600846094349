import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { BoxSession, CustomCtn, SquareContainer } from './cssForComponents';
import { GraphLine } from './graphLine';
import { getRandomColor, groupBy } from './statistics.container';
import { Text } from '../../common/text/Text';
import { useStatsList } from '../hooks/stat.hook';
import { Loader } from '../../common/loader/loader';

const PassengersInCourtByMonth = () => {
  const { passengersInCourtByMonth } = useStatsList();
  const { data, isLoading } = passengersInCourtByMonth;
  const [dataMed, setDataMed] = useState({ labels: [], datasets: [], total: null });
  const montString = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

  function fillArray2019(datas) {
    return [...Array(7).fill(0), ...datas];
  }

  useEffect(() => {
    if (!data || data.length === 0) return;

    async function getDataStat() {
      const groupByYear = groupBy(data.data, (d) => d._id.year);

      const grouped = [...groupByYear];
      grouped.sort((a, b) => a[0] - b[0])
      const labels = montString.map((e) => format(new Date(e), 'MMMM', { locale: fr }));
      const datasets = grouped.map((value) => {
        let datas = value[1].map((v) => v.count);
        if (value[0] === 2019) {
          datas = fillArray2019(datas);
        }
        const backgroundColor = getRandomColor();
        return {
          data: datas,
          label: value[0] || '?',
          fill: false,
          lineTension: 0.1,
          backgroundColor,
          borderColor: backgroundColor,
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: backgroundColor,
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: backgroundColor,
          pointHoverBorderColor: backgroundColor,
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          hidden: value[0] < (new Date().getFullYear() - 1),
        };
      });

      const total = grouped.reduce((v, c) => {
        const vl = typeof v === 'object' ? v[1].map((d) => d.count).reduce((a, b) => a + b) : v;
        const cl = c[1].map((d) => d.count).reduce((a, b) => a + b, 0);
        return vl + cl;
      }, 0);

      setDataMed({
        labels,
        total,
        datasets,
      });
    }

    getDataStat();
  }, [data]);

  return (
    <BoxSession>
      <Text component="h3" variant="subtitle2" color="primary">Passagers appelés en audiences :</Text>
      <Text component="h3" variant="subtitle2" color="secondary">
        <strong>Total :</strong>
        {' '}
        {dataMed.total}
      </Text>
      {isLoading ? <LoaderContainer><Loader /></LoaderContainer>
        : (
          <SquareContainer>
            <CustomCtn width="100%">
              <GraphLine
                data={dataMed}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            </CustomCtn>
          </SquareContainer>
        )}
    </BoxSession>
  );
};

const LoaderContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  PassengersInCourtByMonth,
};
