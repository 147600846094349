import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import {
  Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper,
} from '@mui/material';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const ButtonActionGroupPersonnes = ({
  titleTrigger, type, data, update, folder,
}) => {
  const [open, setOpen] = useState(false);
  const [check, setCheck] = useState(true);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  function checkIfGenerateSaisineIsAvailable(folder) {
    return folder.passengers.some((pass) => !pass.birthDate || !pass.job || !pass.birthPlace || !pass.nationality || (pass.principal && !pass.address));
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    const check = checkIfGenerateSaisineIsAvailable(folder);
    setCheck(check);
  }, [folder]);

  function getDisabled(e, check) {
    return e.label === 'Saisine' || e.label === 'Conciliation' ? check : false;
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  if (!folder) {
    return null;
  }

  return (
    <>
      <ButtonCustomForThisComponent
        ref={anchorRef}
        size="small"
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        endIcon={<KeyboardArrowDownIcon/>}
      >
        {titleTrigger}
      </ButtonCustomForThisComponent>
      <PopperCustom open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {data[type].map((e) => (
                    <MenuItem
                      onClick={() => {
                        update(e.value);
                        handleClose(e);
                      }}
                      id={e.label}
                      dense
                      disabled={getDisabled(e, check)}
                      key={e.value}
                    >
                      {e.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </PopperCustom>
    </>
  );
};

const ButtonCustomForThisComponent = styled(Button)`
  text-transform: inherit !important;
  margin: 0 5px !important;
  background: #f6f7f9 !important;
  color: #505F79 !important;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
`;

const PopperCustom = styled(Popper)`
  z-index: 99999999 !important;
`;

export {
  ButtonActionGroupPersonnes,
};
