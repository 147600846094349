import React from 'react';

const Spacer = ({
  small, medium, large, xLarge,
}) => {
  if (small) {
    return (
      <div style={{ height: '10px' }} />
    );
  }
  if (medium) {
    return (
      <div style={{ height: '20px' }} />
    );
  }
  if (large) {
    return (
      <div style={{ height: '40px' }} />
    );
  }
  if (xLarge) {
    return (
      <div style={{ height: '60px' }} />
    );
  }
};

export {
  Spacer,
};
