import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { getRandomColor, groupBy } from './statistics.container';
import {
  BoxSession, CustomCtn, SelectDiv, SquareContainer,
} from './cssForComponents';
import { Text } from '../../common/text/Text';
import { Loader } from '../../common/loader/loader';
import { useStatsList } from '../hooks/stat.hook';

const ClaimPaidByAirlinesByMonth = ({ status, title }) => {
  const current_year = Number(format(new Date(), 'yyyy'));
  const year_1 = current_year - 2;
  const year_2 = current_year - 1;

  const [dataCourt, setDataCourt] = useState({
    labels: [],
    datasets: [],
    total: null,
    date: '',
  });
  const [year, setYear] = useState({ value: current_year, label: current_year });
  const montString = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  const { claimPaidByAirlinesByMonth } = useStatsList({ year, status });
  const { data, isLoading } = claimPaidByAirlinesByMonth;

  useEffect(() => {
    function fillArray2019(data, value = 4) {
      return [...Array(value).fill([0, []]), ...data];
    }

    async function getDataStat() {
      if (!data || data.length === 0) return;

      const labels = montString.map((e) => format(new Date(e), 'MMMM', { locale: fr }));
      const groupByMonth = groupBy(data.data, (a) => a._id.month);
      const datas = fillArray2019(groupByMonth);
      const formats = groupBy(datas.map((elem) => elem[1]).flat(), (a) => a._id.airline[0]);

      const formatted = [...formats];
      const fresh = [];
      const formattedLabels = [...formats].map((e) => e[0]);
      data.labels.forEach((element) => {
        const currentIndex = formattedLabels.indexOf(element);
        const elem = formatted[currentIndex];
        fresh.push(elem);
      });

      const sort = fresh.map((f, i) => {
        const sorted = f[1];

        function FillArrayMonth(ok) {
          const data = [...ok];
          const defaultArray = ok.map((c) => c._id.month);
          const a = [...defaultArray];
          const count = 12;
          const missing = [];
          for (let i = 0; i <= count; i++) {
            if (a.indexOf(i) === -1) {
              missing.push(i);
            }
          }

          function insertAt(array, index, ...elementsArray) {
            array.splice(index, 0, ...elementsArray);
          }

          for (let i = 0; i < missing.length; i++) {
            const ind = missing[i];
            insertAt(data, ind, {
              _id: { month: ind },
              counts: 0,
            });
          }
          return data;
        }

        const fill = FillArrayMonth(sorted);
        if (year.label !== '2019') {
          fill.shift();
        }
        const findAirline = fill.find((f) => f._id.airline);
        const airline = findAirline._id.airline[0];
        const counts = fill.map((f) => f.counts);
        const backgroundColor = getRandomColor();
        return {
          data: counts,
          label: airline,
          fill: true,
          lineTension: 0.1,
          backgroundColor,
          borderColor: backgroundColor,
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: backgroundColor,
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: backgroundColor,
          pointHoverBorderColor: backgroundColor,
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          position: i,
          hidden: (airline !== data.labels[0] && airline !== data.labels[1] && airline !== data.labels[2]),
          pointHitRadius: 10,
        };
      });
      setDataCourt({
        labels,
        total: 0,
        datasets: sort,
      });
    }

    getDataStat();
  }, [year, data]);

  return (
    <BoxSession>
      <Text component="h3" variant="subtitle2" color="primary">{title}</Text>
      <SelectDiv>
        <Select
          value={year}
          options={[{ value: current_year, label: current_year }, { value: year_2, label: year_2 }, {
            value: year_1,
            label: year_1,
          }]}
          onChange={(val) => setYear(val)}
        />
      </SelectDiv>
      {isLoading ? <LoaderContainer><Loader /></LoaderContainer>
        : (
          <SquareContainer>
            <CustomCtn width="100%">
              <Line
                data={dataCourt}
                width={100}
                height={50}
                options={{
                  plugins: {
                    legend: {
                      display: true,
                    },
                  },
                  maintainAspectRatio: false,
                }}
              />
            </CustomCtn>
          </SquareContainer>
        )}
    </BoxSession>
  );
};

const LoaderContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  ClaimPaidByAirlinesByMonth,
};
