import React from 'react';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { FormControlCustom, ItemsContainer } from './styledCssCommon';
import { RateContainer } from './rateContainer';

const InputSelect = ({
  label, onChange, data, defaultValue, value, labelID, id, rate, rateLimit,
}) => (
  <ItemsContainer>
    {rate ? <RateContainer rate={rate} rateLimit={rateLimit} /> : null}
    <FormControlCustom fullWidth>
      <InputLabel
        id={labelID}
        style={{ fontWeight: 'bold' }}
        variant="standard"
      >
        {label}
      </InputLabel>
      <Select
        labelId={labelID}
        id={id}
        variant="standard"
        data={data}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        multiple={false}
      >
        {
            data.map((e, i) => (
              <MenuItem
                key={[i]}
                value={e.value}
              >
                {e.label}
              </MenuItem>
            ))
          }
      </Select>
    </FormControlCustom>
  </ItemsContainer>
);

export {
  InputSelect,
};
