import styled from 'styled-components';

export const MyLayoutFilters = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
`;
export const WrapperFilters = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0;
  flex-wrap: wrap;

  > div {
    min-width: 23%;
    width: auto;
    max-width: 23%;
    margin: 5px;

    > div {
      min-height: 40px;
      height: auto;
    }
  }

  div {
    height: auto;
  }
`;

export const WrapperInfoCaseList = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  min-height: 50px;

  > span {
    margin-right: 20px;
  }
`;

export const WrapperPaginationSize = styled.div`
  width: 100px;
  position: absolute;
  right: 0;
`;
