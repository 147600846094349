import React from 'react';
import { Pie } from 'react-chartjs-2';

const StatisticsMed = ({data, legend = false, tooltips = true}) => (
  <Pie
    data={data}
    options={{
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: legend,
          position: 'right',
        },
      },
      tooltips: {
        enabled: tooltips,
      },
    }}
  />
);

export {
  StatisticsMed,
};
