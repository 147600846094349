import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  AppBar, Autocomplete, Tab, Tabs, TextField,
} from '@mui/material';
import styled from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';
import { useStatsList } from '../hooks/stat.hook';
import { Text } from '../../common/text/Text';
import { BoxSession } from './cssForComponents';
import { Spacer } from '../../common/spacer/Spacer';
import { Loader } from '../../common/loader/loader';
import plane from '../../assets/plane.png';

const ListAirlines = ({ airline, setAirline }) => {
  const { airlinesList } = useStatsList();
  const { data } = airlinesList;

  return (
    <AirlineSelectorContainer>
      <Autocomplete
        id="airline-selector"
        fullWidth
        options={data?.data ?? []}
        value={airline}
        label="Airline"
        variant="outlined"
        onChange={(event, newValue) => {
          setAirline(newValue);
        }}
        style={{ width: '300px' }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Airline"
            variant="outlined"
          />
        )}
      />
    </AirlineSelectorContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const TabCompareAirlines = () => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = React.useState(0);
  const [yearSelected, setYearSelected] = useState(null);
  const [airline, setAirline] = useState(null);
  const [params, setParams] = useState({ airline: null });
  const { getStatsList } = useStatsList(params);
  const { data, isLoading } = getStatsList;

  useEffect(() => {
    setParams((current) => ({ ...current, airline }));
  }, [airline]);

  useEffect(() => {
    if (data) {
      const goodYears = data.find((e) => e?.label === yearSelected);
      if (goodYears) {
        const index = data.findIndex((elem) => elem?.label === goodYears.label);
        setYearSelected(goodYears?.label);
        setTabIndex(index);
      } else {
        setYearSelected(data[0]?.label);
        setTabIndex(0);
      }
    }
  }, [data]);

  const handleChange = (event, index) => {
    setTabIndex(index);
  };

  const columns = [
    { field: 'airline', headerName: 'Airline', width: 208 },
    {
      field: 'total_claim_received_this_year', headerName: 'Total Claims Année', type: 'number', width: 208,
    },
    {
      field: 'total', headerName: 'Total Claims', type: 'number', width: 208,
    },
    {
      field: 'paid', headerName: 'Total Payées', type: 'number', width: 208,
    },
    {
      field: 'percentage', headerName: 'Conversion %', type: 'number', width: 208,
    },
  ];
  const createRow = () => {
    const year = data.filter((obj) => obj?.label === yearSelected);
    return year[0].data.map((elem, i) => ({
      id: i,
      airline: elem.airline,
      total_claim_received_this_year: elem.total_claim_received_this_year,
      total: elem.total,
      paid: elem.paid,
      percentage: elem.percentage,
    }));
  };

  const rows = useMemo(() => {
    if (!data || !yearSelected || data[0].data.length === 0) return [];
    return createRow(yearSelected);
  }, [data, yearSelected]);

  const yearsSelector = (elem) => {
    setYearSelected(elem?.label);
  };

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  return (
    <BoxSession>
      <TitleContainer>
        <IconContainer>
          <img src={plane} alt="plane" />
          <Text component="h3" variant="subtitle2" color="primary">Top airlines scores :</Text>
        </IconContainer>
      </TitleContainer>
      <>
        <SelectorContainer>
          <ListAirlines airline={airline} setAirline={(e) => setAirline(e)} />
        </SelectorContainer>
        <Container>
          {!isLoading
            ? (
              <TabContainer>
                <div className={classes.root}>
                  <AppBar position="static" color="default">
                    <Tabs
                      value={tabIndex}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                    >
                      {
                        data && data.map((elem, i) => (
                          <Tab onClick={() => yearsSelector(elem)} key={i.toString() + elem?.label} label={elem?.label} {...a11yProps(i)} />
                        ))
                      }
                    </Tabs>
                  </AppBar>
                  <Spacer medium />
                  <div style={{ height: 628, width: '100%' }}>
                    <DataGrid rows={rows} columns={columns} />
                  </div>
                </div>
              </TabContainer>
            )
            : (
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            )}
        </Container>
      </>
    </BoxSession>
  );
};

const Container = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 20px 0;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TabContainer = styled.div`
  width: 95%;
`;

const SelectorContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AirlineSelectorContainer = styled.div`
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const IconContainer = styled.div`
  display: flex;
  width: 95%;

  img {
    padding-top: 5px;
    width: 50px;
  }
`;

export {
  TabCompareAirlines,
};
