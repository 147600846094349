import {
  ADD_AUDIENCE_INFORMATION,
  ADD_AUDIENCE_INFORMATION_ERROR,
  ADD_AUDIENCE_INFORMATION_RESPONSE,
  ADD_CONVOCATION_FROM_AUDIENCE_RESPONSE,
  ADD_ERRORS_AUDIENCE,
  ADD_JURISTS_REQUEST,
  ADD_JURISTS_RESPONSE,
  CLEAR_STATE_AUDIENCE,
  DISMISS_AUDIENCE_FLAG_ERROR,
  GENERATE_ZIP_AUDIENCE,
  GENERATE_ZIP_AUDIENCE_RESPONSE,
  GENERATE_ZIP_CCL,
  GENERATE_ZIP_CCL_RESPONSE,
  RETRIEVE_AUDIENCE_REQUEST,
  RETRIEVE_AUDIENCE_RESPONSE,
  RETRIEVE_AUDIENCES,
  RETRIEVE_AUDIENCES_RESPONSE,
  RETRIEVE_JURISTS_REQUEST,
  RETRIEVE_JURISTS_RESPONSE,
  RETRIEVE_MY_AUDIENCE_REQUEST,
  RETRIEVE_MY_AUDIENCE_RESPONSE,
  SELECT_ALL_CHECKBOX_AUDIENCE,
  SELECT_BOX_AUDIENCE, TOGGLE_FINISH_AUDIENCE_REQUEST, UPDATE_CANCELLING_AUDIENCE_LIST_REQUEST,
  UPDATE_STATUS_AUDIENCE_LIST_REQUEST,
  UPDATE_STATUS_CLAIMANT,
} from './audience.actions';

const {v4: uuidv4} = require('uuid');

const initialAudienceState = {
  loading: false,
  audiences: [],
  jurists: [],
  myAudiences: [],
  audience: null,
  loadingState: {
    information: false,
    zip: false,
    list: false,
    myHearingDate: false,
    audience: false,
  },
  errors: null,
  errorsInfo: [],
};

function getErrors(id, title, appearance) {
  return {
    created: Date.now(),
    id,
    key: id,
    title,
    appearance,
  };
}

export const audienceReducer = (state = initialAudienceState, action) => {
  switch (action.type) {
    case TOGGLE_FINISH_AUDIENCE_REQUEST: {
      return {
        ...state,
        myAudiences: state.myAudiences.map((e) => {
          if (e._id === action.payload) {
            return {
              ...e,
              status: e.status === 'IN PROGRESS' || !e.status ? 'DONE' : 'IN PROGRESS',
            };
          }
          return e;
        }),
      };
    }

    case CLEAR_STATE_AUDIENCE:
      return {
        ...initialAudienceState,
      };

    case RETRIEVE_AUDIENCES:
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          list: true,
        },
      };

    case RETRIEVE_AUDIENCES_RESPONSE:
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          list: false,
        },
        audiences: action.payload,
      };

    case RETRIEVE_JURISTS_REQUEST:
      return {
        ...state,
      };

    case RETRIEVE_JURISTS_RESPONSE:
      return {
        ...state,
        jurists: action.payload,
      };

    case ADD_JURISTS_REQUEST:
      const audiences = state.audiences.map((audience) => {
        if (action.payload.audienceId === audience._id) {
          const jurists = action.payload.jurists ? action.payload.jurists.map((jurist) => ({
            firstName: jurist.label,
            lastName: '',
            _id: jurist.value,
          })) : [];
          return {
            ...audience,
            jurists,
          };
        }
        return {
          ...audience,
        };
      });
      return {
        ...state,
        audiences,
      };

    case ADD_JURISTS_RESPONSE:
      return {
        ...state,
        errors: action.payload.response.success === false ? true : null,
      };

    case RETRIEVE_MY_AUDIENCE_REQUEST:
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          myHearingDate: true,
        },
        myAudiences: [],
      };

    case RETRIEVE_MY_AUDIENCE_RESPONSE:
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          myHearingDate: false,
        },
        myAudiences: [...action.payload],
      };

    case RETRIEVE_AUDIENCE_REQUEST:
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          audience: true,
        },
      };

    case RETRIEVE_AUDIENCE_RESPONSE:
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          audience: false,
        },
        audience: action.payload,
      };

    case SELECT_ALL_CHECKBOX_AUDIENCE:

      return {
        ...state,
        audience: {
          ...state.audience,
          passengers: state.audience.passengers.map((e) => ({
            ...e,
            checked: !e.checked,
          })),
        },
      };

    case SELECT_BOX_AUDIENCE:
      return {
        ...state,
        audience: {
          ...state.audience,
          passengers: state.audience.passengers.map((e) => {
            if (e._id === action.payload) {
              return {
                ...e,
                checked: !e.checked,
              };
            }
            return {
              ...e,
            };
          }),
        },
      };

    case ADD_AUDIENCE_INFORMATION:
      return {
        ...state,
        audience: {
          ...state.audience,
          information: action.payload,
        },
        loadingState: {
          ...state.loadingState,
          information: true,
        },
        errorsInfo: [],
      };

    case ADD_AUDIENCE_INFORMATION_RESPONSE: {
      const id = uuidv4();
      const errorsInfo = [...state.errorsInfo];

      errorsInfo.unshift(getErrors(id, 'Note sauvegardée avec succès !', 'success'));
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          information: false,
        },
        errorsInfo,
      };
    }

    case ADD_AUDIENCE_INFORMATION_ERROR: {
      const id = uuidv4();
      const errorsInfo = [...state.errorsInfo];

      errorsInfo.unshift(getErrors(id, 'Impossible de sauvegarder la note !', 'error'));

      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          information: false,
        },
        errorsInfo,
      };
    }
    case DISMISS_AUDIENCE_FLAG_ERROR:
      return {
        ...state,
        errorsInfo: state.errorsInfo.slice(1),
      };

    case UPDATE_STATUS_CLAIMANT: {
      const passengers = state.audience.passengers.map((passenger) => (passenger.checked ? ({
        ...passenger,
        record: {...passenger.record, status: action.payload},
      }) : ({...passenger})));
      return {
        ...state,
        audience: {
          ...state.audience,
          passengers,
        },
      };
    }

    case ADD_CONVOCATION_FROM_AUDIENCE_RESPONSE: {
      const id = uuidv4();
      const errorsInfo = [...state.errorsInfo];
      errorsInfo.unshift(getErrors(id, 'Audience renvoyée avec succès !', 'success'));
      return {
        ...state,
        errorsInfo,
      };
    }

    case ADD_ERRORS_AUDIENCE: {
      const id = uuidv4();
      const errorsInfo = [...state.errorsInfo];

      errorsInfo.unshift(getErrors(id, `${action.payload}`, 'warning'));
      return {
        ...state,
        errorsInfo,
      };
    }

    case GENERATE_ZIP_CCL: {
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          zip: true,
        },
      };
    }

    case GENERATE_ZIP_AUDIENCE: {
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          zip: true,
        },
      };
    }

    case GENERATE_ZIP_AUDIENCE_RESPONSE: {
      const blob = new Blob([action.payload.response], {type: 'application/zip'});
      const url = URL.createObjectURL(blob);
      const id = uuidv4();
      const errorsInfo = [...state.errorsInfo];

      try {
        let windowObjectReference = null;
        const strWindowFeatures = 'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
        // eslint-disable-next-line no-unused-vars
        windowObjectReference = window.open(url, 'ZIP_WindowName', strWindowFeatures);
      } catch (e) {
        errorsInfo.unshift(getErrors(id, 'Merci de désactiver ADBLOCK pour télécharger le zip.', 'error'));
      }
      return {
        ...state,
        errorsInfo,
        loadingState: {
          ...state.loadingState,
          zip: false,
        },
      };
    }

    case GENERATE_ZIP_CCL_RESPONSE: {
      const blob = new Blob([action.payload.response], {type: 'application/zip'});
      const url = URL.createObjectURL(blob);
      const id = uuidv4();
      const errorsInfo = [...state.errorsInfo];

      try {
        let windowObjectReference = null;
        const strWindowFeatures = 'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
        // eslint-disable-next-line no-unused-vars
        windowObjectReference = window.open(url, 'ZIP_WindowName', strWindowFeatures);
      } catch (e) {
        errorsInfo.unshift(getErrors(id, 'Merci de désactiver ADBLOCK pour télécharger le zip.', 'error'));
      }
      return {
        ...state,
        errorsInfo,
        loadingState: {
          ...state.loadingState,
          zip: false,
        },
      };
    }

    case UPDATE_STATUS_AUDIENCE_LIST_REQUEST: {
      return {
        ...state,
        audience: {
          ...state.audience,
          passengers: state.audience.passengers.map((e) => {
            if (e.checked) {
              return {
                ...e,
                record: {
                  ...e.record,
                  status: action.payload,
                },
              };
            }
            return {
              ...e,
            };
          }),
        },
      };
    }

    case UPDATE_CANCELLING_AUDIENCE_LIST_REQUEST: {
      console.log(action.payload)
      console.log(state.audience.passengers)
      return {
        ...state,
        audience: {
          ...state.audience,
          passengers: state.audience.passengers.map((e) => {
            if (e.checked) {
              return {
                ...e,
                cancelling: action.payload
              };
            }
            return {
              ...e,
            };
          }),
        },
      };
    }

    default:
      return state;
  }
};
