import { ofType } from 'redux-observable';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  ADD_CONVOCATION_REQUEST,
  addConvocationResponse,
  SEARCH_REQUEST_CONVOCATION,
  searchErrorsConvocation,
  searchResponseConvocation, UPDATE_CONVOCATION, updateConvocationResponse,
} from './convocation.actions';

export const searchQueryConvocation = (action$, store, {ConvocationGateway}) => action$.pipe(
  ofType(SEARCH_REQUEST_CONVOCATION),
  mergeMap((action) => ConvocationGateway.query(action.payload)
    .pipe(
      map((response) => searchResponseConvocation(response.data)),
      catchError((error) => of(searchErrorsConvocation(error.response))),
    )),
);

export function getState(store) {
  let state;
  store.subscribe((s) => state = s);
  return state;
}

export const addConvocation = (action$, store, {ConvocationGateway}) => action$.pipe(
  ofType(ADD_CONVOCATION_REQUEST),
  mergeMap(() => ConvocationGateway.add(
    getState(store).convocation.selected,
    getState(store).convocation.datetime,
    getState(store).convocation.jurisdiction,
    getState(store).convocation.type,
    getState(store).convocation.jurists,
  )
    .pipe(
      map((response) => addConvocationResponse(response)),
      catchError((error) => of(console.log(error))),
    )),
);

export const updateConvocation = (action$, store, {ConvocationGateway}) => action$.pipe(
  ofType(UPDATE_CONVOCATION),
  mergeMap(() => ConvocationGateway.update(
    getState(store).convocation.selected,
    getState(store).convocation.datetime,
    getState(store).convocation.jurisdiction,
    getState(store).convocation.type,
    getState(store).audience.audience._id,
    getState(store).convocation.jurists,
  )
    .pipe(
      map((response) => updateConvocationResponse(response)),
      catchError((error) => of(console.log(error))),
    )),
);
