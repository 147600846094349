import React, { Fragment } from 'react';
import {
  ContainerHeader, HeaderSection, Item, MenuSection,
} from '@atlaskit/navigation-next';
import ArrowLeftCircleIcon from '@atlaskit/icon/glyph/arrow-left-circle';

import DocumentsIcon from '@atlaskit/icon/glyph/documents';
import ListIcon from '@atlaskit/icon/glyph/bullet-list';
import * as qs from 'query-string';

const AudienceSubMenu = ({
  push, navigateInAudience, audienceMenuState = 'LIST', search,
}) => {
  const redirect = () => {
    const query = qs.parse(search);
    const fresh = { ...query };

    delete fresh.id;
    delete fresh.from;
    if (query.from === 'audience') {
      delete fresh.audienceId;
      fresh.id = query.audienceId;
    }
    const querystring = qs.stringify(fresh);
    push(`/${`${query.from}?${querystring}`}`);
  };

  return (
    <>
      <HeaderSection>
        {({ css }) => (
          <div style={{ ...css, paddingBottom: 20 }}>
            <ContainerHeader
              before={() => (
                <ArrowLeftCircleIcon primary="#0747A6" />
              )}
              text="Quitter l'audience"
              onClick={() => redirect()}
            />
          </div>
        )}
      </HeaderSection>
      <MenuSection>
        {({ className }) => (
          <div className={className}>
            <Item
              before={() => (<ListIcon size="large" />)}
              onClick={() => navigateInAudience('LIST')}
              text="Liste"
              isSelected={audienceMenuState === 'LIST'}
            />
            <Item
              before={() => (<DocumentsIcon />)}
              onClick={() => navigateInAudience('NOTE')}
              text="Note d'audience"
              isSelected={audienceMenuState === 'NOTE'}
            />
          </div>
        )}
      </MenuSection>
    </>
  );
};

export {
  AudienceSubMenu,
};
