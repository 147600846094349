import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { selectCase } from '../../../../../usescases/case/case.selector';
import {
  saveValueCase,
  updateValueCase,
} from '../../../../../usescases/case/case.action';
import { Details } from './details.container';
import { retrieve_jurisdictions_request, retrieve_operated_airlines_request } from '../../../../../usescases/filtersList/filterlist.action';
import { selectJurisdictions, selectOperatingAirlines } from '../../../../../usescases/filtersList/filterslist.selector';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
  folder: selectCase(state),
  airlines: selectOperatingAirlines(state),
  jurisdictions: selectJurisdictions(state),
});

const mapDispatchToProps = (dispatch) => ({
  push: (value) => dispatch(push(value)),
  updateCase: (field, value) => dispatch(updateValueCase(field, value)),
  saveValueInCase: (field, value) => dispatch(saveValueCase(field, value)),
  retrieve_airlines: () => dispatch(retrieve_operated_airlines_request()),
  retrieve_jurisdictions: () => dispatch(retrieve_jurisdictions_request()),
});

const DetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Details);

export default DetailsContainer;
