import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { DocumentsContainer } from './documents.container';
import {
  selectDocumentsList,
  selectDocumentsListSize,
  selectDocumentsLoading,
} from '../../usescases/documents.selector';
import {
  generateDocumentsListRequest,
  generateGreffeRequest,
  generateZipList,
  initListDocs,
  retrieveDocumentsList,
  selectAllElemInList,
  selectElementInList,
} from '../../usescases/documents.action';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
  list: selectDocumentsList(state),
  loading: selectDocumentsLoading(state),
  listSize: selectDocumentsListSize(state),
});

const mapDispatchToProps = (dispatch) => ({
  push: (value) => dispatch(push(value)),
  retrieveDocsList: (search) => dispatch(retrieveDocumentsList(search)),
  selectElement: (elem) => dispatch(selectElementInList(elem)),
  selectAll: (isChecked) => dispatch(selectAllElemInList(isChecked)),
  generateZip: (type) => dispatch(generateZipList(type)),
  initList: () => dispatch(initListDocs()),
  generateDocumentsList: (type) => dispatch(generateDocumentsListRequest(type)),
  generateGreffe: (airline_name, jurisdiction) => dispatch(generateGreffeRequest(airline_name, jurisdiction)),
});

const DocumentsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentsContainer);

export default DocumentsPage;
