import {
  RETRIEVE_JURISDICTIONS_REQUEST,
  RETRIEVE_JURISDICTIONS_RESPONSE,
  RETRIEVE_OPERATING_AIRLINES_ERROR,
  RETRIEVE_OPERATING_AIRLINES_REQUEST,
  RETRIEVE_OPERATING_AIRLINES_RESPONSE,
} from './filterlist.action';

const initialFiltersListState = {
  operating_airlines: null,
  jurisdictions: null,
  loading: false,
  errors: null,
};

export const filtersListReducer = (state = initialFiltersListState, action) => {
  switch (action.type) {
    case RETRIEVE_OPERATING_AIRLINES_REQUEST:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case RETRIEVE_OPERATING_AIRLINES_RESPONSE:
      return {
        ...state,
        loading: false,
        operating_airlines: action.payload.data,
      };

    case RETRIEVE_OPERATING_AIRLINES_ERROR:
      return {
        ...state,
      };
    case RETRIEVE_JURISDICTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case RETRIEVE_JURISDICTIONS_RESPONSE:
      return {
        ...state,
        loading: false,
        jurisdictions: action.payload.data,
      };
    default:
      return state;
  }
};
