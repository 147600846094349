import React, { Fragment, useEffect } from 'react';
import { GlobalContainerDashboard } from '../../../../common/layout/ContainerDashboard';
import { FlagGroupError } from '../../../../common/error/error';
import InformationsPage from './informations';
import PassengersPages from './passengers';
import DocumentsPages from './documents';

const CaseContainer = ({
  retrieveCaseById, search, initCase, stateCase, errors, dismissError,
}) => {
  const errorsClean = [...errors.generateDocs];

  useEffect(() => {
    initCase();
  }, []);

  useEffect(() => {
    retrieveCaseById(search);
  }, [search]);

  const dismissFlag = () => {
    dismissError();
  };

  return (
    <GlobalContainerDashboard>
      <FlagGroupError data={errorsClean} dismissFlag={dismissFlag} />
      <>
        {stateCase === 'INFO' && <InformationsPage />}
        {stateCase === 'PASSENGERS' && <PassengersPages />}
        {stateCase === 'DOCS' && <DocumentsPages />}
      </>
    </GlobalContainerDashboard>
  );
};

export {
  CaseContainer,
};
