import { Workbook } from 'exceljs';
import { format } from 'date-fns';
import React from 'react';
import * as fs from 'file-saver';
import EventIcon from '@mui/icons-material/Event';
import { ButtonCustom } from '../../../../common/button/button';

const ButtonGenerateExcel = ({ audience }) => {
  const passengers = audience.passengers.filter((p) => p.checked);

  const DataExcelAudience = (data) => data.map((e) => ({
    rg_number: e.rg_number,
    name: `${e.firstName} ${e.lastName}`,
    booking_ref: e.record.booking_ref,
    airline: e.record.ca_name_operated,
    country: e.nationality,
    flight_date: format(new Date(e.record.flight_date), 'dd/MM/yyyy'),
    flight_number: e.record.flight_number,
    city_departure: e.record.airport_departure,
    city_arrival: e.record.airport_arrival,
    compensation: e.compensation_received,
    article_700: e.article_700,
  }));

  const downloadExcel = (data) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(`Audience du ${format(new Date(audience.hearingDate), 'dd-MM-yyyy')} à ${audience.jurisdiction}`);
    const header = ['Numéro RG', 'Dossier', 'Booking ref', 'Compagnie aérienne', 'Lieu de résidence', 'Date du vol', 'Numéro de vol', 'Aéroport\nde départ', 'Aéroport\nd\'arrivée', 'Montant\nIndemnité\nEC261', 'Article 700'];
    worksheet.getRow(3).values = header;
    const fName = `Audience du ${format(new Date(audience.hearingDate), 'dd-MM-yyyy')} à ${audience.jurisdiction}`;

    worksheet.getRow(1).font = {
      size: 16,
      bold: true,
      color: { argb: 'FFFFFFFF' },
    };

    worksheet.getRow(3).font = {
      size: 14,
      bold: true,
    };

    worksheet.getRow(3).alignment = { vertical: 'top', horizontal: 'left' };

    worksheet.getCell('A1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF0052CC' },
    };

    worksheet.columns = [
      { width: 14 },
      { width: 24 },
      { width: 17 },
      { width: 24 },
      { width: 20 },
      { width: 14 },
      { width: 17 },
      { width: 13 },
      { width: 13 },
      { width: 13 },
      { width: 13 },
    ];

    for (const x1 of data) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of x2) {
        temp.push(x1[y]);
      }
      worksheet.addRow(temp);
    }

    worksheet.getColumn(10).numFmt = '#,##0.00" €";[Red]-#,##0.00" €"';
    worksheet.getColumn(11).numFmt = '#,##0.00" €";[Red]-#,##0.00" €"';
    worksheet.mergeCells('A1', 'K1');

    worksheet.getCell('A1').value = `RG Avocats - Date de ${audience.type} le ${new Date(audience.hearingDate).toLocaleString('fr')} à ${audience.jurisdiction}`;
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, `${fName}-${new Date().valueOf()}.xlsx`);
    });
  };
  return (
    <ButtonCustom
      disabled={passengers.length === 0}
      background="lightGreen"
      text="Excel audience"
      startIcon={<EventIcon />}
      onClick={() => downloadExcel(DataExcelAudience(passengers))}
    />
  );
};

export {
  ButtonGenerateExcel,
};
