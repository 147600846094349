import React, { useEffect, useState } from 'react';
import * as qs from 'query-string';
import styled from 'styled-components';
import DynamicTable from '@atlaskit/dynamic-table';
import axios from 'axios';
import { useMutation } from 'react-query';
import { Typography } from '@mui/material';
import { GlobalContainerDashboard } from '../../../common/layout/ContainerDashboard';
import { FlagGroupError } from '../../../common/error/error';
import { PageHeaderList } from '../../../common/pageheaderlist/pageheaderlist';
import { ListWrapper } from '../../../common/layout/wrapper';
import { Breadcrumbs } from '../../../common/breadcrumbs/breadcrumbs';
import { createHeadTableDocuments, createRowsTableDocuments } from './tableutils';
import BarContentFilters from '../../../casescontext/adapters/primary/filterCaseList';
import { ButtonActionGroup } from '../../../casescontext/adapters/primary/listcases/buttonactiongroup';
import { data } from '../../../casescontext/adapters/primary/filterCaseList/dataFilters';
import { LoadMore } from '../../../casescontext/adapters/primary/listcases/listcasescontainer';
import { ButtonCustom } from '../../../common/button/button';

export const DocumentsContainer = ({ ...props }) => {
  const dismissFlag = () => null;
  const {
    list,
    loading,
    retrieveDocsList,
    selectElement,
    selectAll,
    generateZip,
    search,
    listSize,
    initList,
    generateGreffe,
    generateDocumentsList,
  } = props;

  const [loadingOcr, setLoadingOcr] = useState(false);
  const [total, setTotal] = useState(null);
  const [current, setCurrent] = useState(null);

  const postIdClaimOcrRequest = async (listSelected) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/passengers/ocr`,
      {
        claimIds: listSelected.map((e) => e._id),
      },
    );
    return data;
  };

  const postIdClaimFreeOcrRequest = async (listSelected) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/free/ocr`,
      {
        claimIds: listSelected.map((e) => e._id),
      },
    );
    return data;
  };

  const getUpdateTask = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/record/ocr/pendingTask?task=${id}`);
    return data;
  };

  const { mutate: idClaimOcrRequest, isLoading } = useMutation(postIdClaimOcrRequest, {
    onSettled: (response) => {
      setLoadingOcr(true);
      localStorage.setItem('queue-running', response.queue._id);
    },
  });

  const { mutate: freeOcrRequest, isLoadingFreeOcr } = useMutation(postIdClaimFreeOcrRequest, {
    onSettled: (response) => {
      console.log({ response, isLoadingFreeOcr })
    },
  });

  useEffect(() => {
    initList();
  }, []);

  useEffect(() => {
    const query = qs.parse(search);
    const fresh = { ...query };
    if ('paginateSize' in fresh) {
      retrieveDocsList(search);
    }
  }, [search]);

  useEffect(() => {
    let timer;
    if (loadingOcr) {
      timer = setInterval(() => {
        const queueId = localStorage.getItem('queue-running');
        getUpdateTask(queueId).then((response) => {
          console.log(response.data.isFinish);
          if (response.data.isFinish) {
            setLoadingOcr(false);
            setTotal(null);
            setCurrent(null);
            clearInterval(timer);
          } else {
            console.log('d', response.data);
            setTotal(response.data.total);
            setCurrent(response.data.current);
          }
        });
      }, 3000);
    } else {
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
  }, [loadingOcr]);

  function hasOnePassengerSelected() {
    return list.some((l) => l.selected);
  }

  return (
    <GlobalContainerDashboard>
      <FlagGroupError
        errors={[]}
        name="updateStatus"
        dismissFlag={dismissFlag}
        appearance="error"
      />
      <ListWrapper>
        {list && (
          <PageHeaderList
            actionsContent={(
              <ActionsContentDocsList
                update={(e) => generateZip(e)}
                generateDocuments={(e) => generateDocumentsList(e)}
                data={data}
                type="ZIP"
                generateGreffe={generateGreffe}
                search={search}
                list={list}
                idClaimOcrRequest={idClaimOcrRequest}
                freeOcrRequest={freeOcrRequest}
                selectedPassengers={hasOnePassengerSelected()}
              />
            )}
            breadcrumbs={(
              <Breadcrumbs
                text="Documents"
                key="DocumentsKey"
                location="/documents?paginateSize=50"
              />
            )}
            bottomBar={(
              <BarContentFilters
                listSize={listSize}
                jurisdiction
                status
                airline
                start_status
                end_status
                log_status
                oag
                start
                end
                claimant_country
                ocr_confidence
                list={list}
                initList={initList}
                med30
              />
            )}
            title="Documents"
            copyPaste
          />
        )}
      </ListWrapper>
      {loadingOcr && total && current && (
        <Typography>
          {`Total : ${current} / ${total}`}
        </Typography>
      )}
      <ListWrapper>
        <DynamicTable
          head={createHeadTableDocuments(true, selectAll)}
          rows={createRowsTableDocuments(list, selectElement, search)}
          loadingSpinnerSize="large"
          isLoading={loading || isLoading || loadingOcr}
          isFixedSize
          paginationi18n={{
            prev: 'Précédent',
            next: 'Suivant',
          }}
        />
      </ListWrapper>
      <LoadMore list={list} listSize={listSize} {...props} />
    </GlobalContainerDashboard>
  );
};

const ActionsContentDocsList = ({
  update,
  generateDocuments,
  data,
  type,
  generateGreffe,
  search,
  list,
  selectedPassengers,
  idClaimOcrRequest,
  freeOcrRequest
}) => {
  const query = qs.parse(search);
  const [error, setError] = useState(false);
  const [role, setRole] = useState(null);
  const listSelected = list.filter((p) => p.selected);

  useEffect(() => {
    setRole(JSON.parse(localStorage.getItem('user')));
  }, []);

  function generateCourt() {
    if ('jurisdiction' in query && selectedPassengers) {
      setError(false);
      generateGreffe(query.jurisdiction);
    } else {
      setError(true);
    }
  }

  return (
    <div>
      {role && role.role && role.role === 'ADMIN'
        && (
          <>
            <ButtonActionGroup update={generateDocuments} data={data} type="FILES" title="Générer documents" />
            <ButtonCustom
              disabled={!selectedPassengers}
              onClick={() => idClaimOcrRequest(listSelected)}
              background="purple"
              text="API OCR"
            />
            <ButtonCustom
              disabled={!selectedPassengers}
              onClick={() => freeOcrRequest(listSelected)}
              background="green"
              text="FREE OCR"
            />
          </>
        )}

      <ButtonCustom background="default" onClick={generateCourt} text="Générer courrier greffe" />
      <ButtonActionGroup update={update} data={data} type={type} title="Générer un zip" />
      {error && (
        <Error>
          <span>
            Afin de générer le courrier au greffe, merci de séléctionner les passagers
            concernés, la compagnie aérienne ainsi que la juridiction compétente
          </span>
        </Error>
      )}
    </div>
  );
};

const Error = styled.div`
  position: fixed;
  height: auto;
  width: 400px;
  background: rgb(255, 86, 48);
  z-index: 100000;
  right: 15px;
  bottom: 15px;
  padding: 15px 10px;
  border-radius: 15px;
  color: #fff;
  white-space: normal;
  border-style: solid;
  overflow: auto;
  text-align: left;
`;