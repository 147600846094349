import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { SearchDrawerContainer } from './search.container';
import { searchClaimRequest, searchRequest, triggerSearchDrawer } from '../../usescases/search.actions';
import { selectSearchDrawerIsOpen, selectSearchedData, selectSearchValue } from '../../usescases/search.selector';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
  isOpen: selectSearchDrawerIsOpen(state),
  data: selectSearchedData(state),
  value: selectSearchValue(state),
});

const mapDispatchToProps = (dispatch) => ({
  push: (value) => dispatch(push(value)),
  triggerDrawer: () => dispatch(triggerSearchDrawer()),
  query: (query) => dispatch(searchRequest(query)),
  searchClaimRequest: (query) => dispatch(searchClaimRequest(query)),
});

const Search = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchDrawerContainer);

export default Search;
