import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ButtonCustom } from './button/button';
import { Text } from './text/Text';
import { Spacer } from './spacer/Spacer';

const NotFoundPage = ({ history }) => {
  useEffect(() => {
    history.push('/dossiers');
  }, []);
  return (
    <ContainerNotFound>
      <Text
        marginBottom
        color="initial"
        variant="h4"
      >
        Nous n'avons pas réussi à trouver la page que vous cherchez
      </Text>
      <Spacer xLarge />
      <ButtonCustom
        background="orange"
        text="Revenir à la page d'accueil"
        onClick={() => history.push('/dossiers')}
      />
    </ContainerNotFound>
  );
};

const ContainerNotFound = styled.div`
background: url('https://www.theuselesswebindex.com/static/videos/error.jpg') no-repeat;
height: 100vh;
background-size: cover;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`;

export {
  NotFoundPage,
};
