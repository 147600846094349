import { combineEpics } from 'redux-observable';
import { retrieveAirHelpFinalCompensation, retrieveCaseList, updateStatusCaseList } from './caseList/caselist.epic';
import { retrieveFilterOperatinAirlineList, retrieveJurisdictionsList } from './filtersList/filterslist.epic';
import {
  addComment,
  generateDocuments, getAutomaticsLogs,
  removeDocuments,
  retrieveCaseByIdEpic,
  retrieveHearingDates, syncWithAlgolia,
  updateSingleCase,
  updateSinglePassenger,
  updateStatusClaim,
} from './case/case.epics';

const rootCaseEpics = combineEpics(
  retrieveCaseList,
  retrieveFilterOperatinAirlineList,
  retrieveJurisdictionsList,
  updateStatusCaseList,
  retrieveCaseByIdEpic,
  updateSinglePassenger,
  updateSingleCase,
  generateDocuments,
  removeDocuments,
  addComment,
  retrieveHearingDates,
  updateStatusClaim,
  getAutomaticsLogs,
  syncWithAlgolia,
  retrieveAirHelpFinalCompensation
);

export {
  rootCaseEpics,
};
