import React from 'react';
import { Route, Switch } from 'react-router';
import Login from '../usercontext/adapters/primary/login';

const UnAuthenticatedApp = () => (
  <Switch>
    <Route
      path="/"
      exact
      component={Login}
    />
    <Route component={Login} />
  </Switch>
);

export {
  UnAuthenticatedApp,
};
