import React, { useState } from 'react';
import styled from 'styled-components';
import CancelIcon from '@mui/icons-material/Cancel';
import SendIcon from '@mui/icons-material/Send';
import DoneIcon from '@mui/icons-material/Done';
import { ButtonCustom } from '../../../../../common/button/button';
import { AutocompleteCustom } from '../../../../../common/input/autocomplete';
import { data } from '../../../../../casescontext/adapters/primary/filterCaseList/dataFilters';
import { TimePickerWithHour } from '../../../../../common/input/inputCalendarWithHour';

const RecallToOtherDate = ({
                             saveRecall,
                             handleErrors,
                             type,
                             audience,
                           }) => {
  const [recall, setRecall] = useState(false);
  const [recallDate, setRecallDate] = useState(null);
  const [typeInternal, setInternalType] = useState({value: type, label: type});

  return (
    <ParamsContainer>
      <ButtonCustom
        background={recall ? 'red' : 'blue'}
        text={recall ? 'Annuler' : 'Renvoyer'}
        width="100"
        endIcon={recall ? <CancelIcon/> : <SendIcon/>}
        onClick={() => setRecall((current) => !current)}
      />
      {recall && (
        <CalendarContainer>
          <TimePickerWithHour
            withLabel={false}
            onChange={(val) => setRecallDate(val)}
            placeholder="Choisir une date"
            padding={false}
            dateDefault={audience?.hearingDate}
            minDate={new Date(audience?.hearingDate)}
          />
          <AutocompleteCustom
            withLabel={false}
            id="juridiction-selector"
            options={data.CONVOCATION_TYPE}
            value={typeInternal}
            onChange={setInternalType}
            placeholder="Choisir un type"
            padding={false}
          />
          <ButtonCustom
            background="blue"
            text="Valider"
            endIcon={<DoneIcon/>}
            width="100"
            onClick={() => saveRecall(recallDate, handleErrors, typeInternal)}
          />
        </CalendarContainer>
      )}
    </ParamsContainer>
  );
};

const ParamsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0;
  height: 30px;
`;

const CalendarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 580px;
`;

export {
  RecallToOtherDate,
};
