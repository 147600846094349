export const SEARCH_REQUEST_CONVOCATION = 'SEARCH_REQUEST_CONVOCATION';
export const SEARCH_RESPONSE_CONVOCATION = 'SEARCH_RESPONSE_CONVOCATION';
export const SEARCH_REQUEST_ERRORS_CONVOCATION = 'SEARCH_REQUEST_ERRORS_CONVOCATION';
export const SELECT_PASSENGER = 'SELECT_PASSENGER';
export const REMOVE_PASSENGER = 'REMOVE_PASSENGER';
export const UPDATE_PASSENGER_FIELD = 'UPDATE_PASSENGER_FIELD';
export const SELECT_JURISDICTION = 'SELECT_JURISDICTION';
export const ADD_CONVOCATION_REQUEST = 'ADD_CONVOCATION_REQUEST';
export const ADD_DATE_TIME = 'ADD_DATE_TIME';
export const ADD_CONVOCATION_RESPONSE = 'ADD_CONVOCATION_RESPONSE';
export const DISMISS_ERROR_CONVOCATION = 'DISMISS_ERROR_CONVOCATION';
export const ADD_TYPE = 'ADD_TYPE';
export const CLEAR_CONVOCATION = 'CLEAR_CONVOCATION';
export const UPDATE_CONVOCATION = 'UPDATE_CONVOCATION';
export const UPDATE_CONVOCATION_RESPONSE = 'UPDATE_CONVOCATION_RESPONSE';
export const UPDATE_CONVOCATION_ERROR = 'UPDATE_CONVOCATION_ERROR';
export const ADD_JURISTS_CONVOCATION = 'ADD_JURISTS_CONVOCATION';

export const updateConvocation = () => ({
  type: UPDATE_CONVOCATION,
});

export const addJurists = (value) => ({
  type: ADD_JURISTS_CONVOCATION,
  payload: value,
});

export const updateConvocationResponse = (response) => ({
  type: UPDATE_CONVOCATION_RESPONSE,
  payload: response,
});

export const updateConvocationError = (error) => ({
  type: UPDATE_CONVOCATION_ERROR,
  payload: error,
});

export const clearConvocation = () => ({
  type: CLEAR_CONVOCATION,
});
export const searchRequestConvocation = (query) => ({
  type: SEARCH_REQUEST_CONVOCATION,
  payload: query,
});

export const addType = (type) => ({
  type: ADD_TYPE,
  payload: type,
});

export const searchResponseConvocation = (response) => ({
  type: SEARCH_RESPONSE_CONVOCATION,
  payload: response,
});

export const searchErrorsConvocation = (errors) => ({
  type: SEARCH_REQUEST_ERRORS_CONVOCATION,
  payload: errors,
});

export const selectPassenger = (passenger) => ({
  type: SELECT_PASSENGER,
  payload: passenger,
});

export const removePassenger = (passenger) => ({
  type: REMOVE_PASSENGER,
  payload: passenger,
});

export const updateFieldPassenger = (_id, field, value) => ({
  type: UPDATE_PASSENGER_FIELD,
  payload: { _id, field, value },
});

export const selectJurisdiction = (jurisdiction) => ({
  type: SELECT_JURISDICTION,
  payload: jurisdiction,
});

export const addConvocationRequest = () => ({
  type: ADD_CONVOCATION_REQUEST,
});

export const addDateToConvocation = (datetime) => ({
  type: ADD_DATE_TIME,
  payload: datetime,
});

export const addConvocationResponse = (success) => ({
  type: ADD_CONVOCATION_RESPONSE,
  payload: success,
});

export const dissmissError = () => ({
  type: DISMISS_ERROR_CONVOCATION,
});
