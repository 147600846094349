import React, { useEffect } from 'react';
import Select from '@atlaskit/select';
import Textfield from '@atlaskit/textfield';
import { format } from 'date-fns';
import { retrieveSourceString } from '../../../listcases/tableutils';
import {
  GlobalWrapperDetails, InputWrapper, Label, Text, WrapperDoubleDiv,
} from '../../common/wrapper';

const { data } = require('../../../filterCaseList/dataFilters');

function activePassengers(p) {
  return p.active;
}

const Details = ({
  retrieve_airlines,
  updateCase,
  saveValueInCase,
  folder,
  airlines,
  jurisdictions,
  retrieve_jurisdictions,
}) => {
  useEffect(() => {
    retrieve_airlines();
    retrieve_jurisdictions();
  }, []);

  const _updateValueCase = (e) => {
    const { name, value } = e.target;
    updateCase(name, value);
  };

  const _saveValue = (e) => {
    const { name, value } = e.target;
    saveValueInCase(name, value);
  };

  if (!folder) return null;

  const valueArticle700 = folder.passengers.reduce((a, c) => (a + c.article_700), 0);
  const valueInterest = folder.passengers.reduce((a, c) => (a + (c.interest || 0)), 0);
  const final_compensation = folder?.final_compensation;
  const receivedCompensation = folder.passengers.filter(activePassengers).reduce((a, c) => (a + (c.compensation_received || 0)), 0);
  const compensation = folder.passengers.filter(activePassengers).reduce((a, c) => (a + (c.compensation || 0)), 0);
  const total = folder.compensation_total_ca;
  const placeholderClaimantCountry = data.CLAIMANT_COUNTRY.find((e) => e.value === folder.claimant_country)?.label ?? 'Non défini';
  const emailPax = folder?.passengers.find((p) => p.principal)?.email;
  return (
    <GlobalWrapperDetails>
      <WrapperDoubleDiv>
        <Text
          text={Number(total)}
          label="Total à payer par la CA"
        />
        <Text
          text={Number(compensation)}
          label="Indemnité prévue totale"
        />
        <Text
          text={Number(receivedCompensation)}
          label="Indemnité reçue totale"
        />
        <Text
          text={Number(valueArticle700)}
          label="Total article 700"
        />
        <Text
          text={Number(valueInterest)}
          label="Montant total des dommages et intérêts"
        />
        <Text
          text={final_compensation || 'Non renseigné'}
          label="Compensation finale AH"
        />
        <Text
          text={emailPax || '❌'}
          label="Adresse e-mail"
        />
        <InputWrapper>
          <Label label="Pays de résidence" />
          {data && placeholderClaimantCountry
            && (
              <Select
                className="select-info"
                classNamePrefix="react-select"
                options={data.CLAIMANT_COUNTRY}
                defaultValue={folder.claimant_country}
                placeholder={placeholderClaimantCountry}
                value={folder.claimant_country}
                onChange={(event) => {
                  saveValueInCase('claimant_country', event.value);
                }}
              />
            )}
        </InputWrapper>

      </WrapperDoubleDiv>
      <WrapperDoubleDiv>
        <Text
          text={`${retrieveSourceString(folder.source)} ${folder.legal_entity || ''}`}
          label="Source"
        />
        <Text
          text={`${folder.contract_type || null}`}
          label="Type de contrat"
        />
        <InputWrapper>
          <Label label="Langue du dossier" />
          <Textfield
            appearance="subtle"
            isDisabled={false}
            name="language"
            onChange={_updateValueCase}
            onBlur={_saveValue}
            value={folder.language}
          />
        </InputWrapper>
        <InputWrapper>
          <Label label="Numéro de vol" />
          <Textfield
            appearance="subtle"
            isDisabled={false}
            value={folder.flight_number}
            onChange={_updateValueCase}
            onBlur={_saveValue}
            name="flight_number"
          />
        </InputWrapper>
        <InputWrapper>
          <Label label="Booking réf" />
          <Textfield
            appearance="subtle"
            isDisabled={false}
            value={folder.booking_ref}
            onChange={_updateValueCase}
            onBlur={_saveValue}
            name="booking_ref"
          />
        </InputWrapper>
        <Text
          text={folder.airport_departure}
          label="Aéroport de départ"
        />
        <Text
          text={folder.airport_arrival}
          label="Aéroport d'arrivée"
        />
        <Text
          text={folder.ca_address}
          label="Adresse CA"
        />
      </WrapperDoubleDiv>
      <WrapperDoubleDiv>
        <Text
          text={folder.fullNameMainPassenger()}
          label="Client"
        />
        <Text
          text={folder.from_id}
          label="Référence client"
        />
        <Text
          text={folder.passengers.length}
          label="Nombre de passagers"
        />
        <Text
          text={format(new Date(folder.flight_date), 'dd/MM/yyyy HH:mm')}
          label="Date du vol"
        />
        <Text
          text={`${folder.distance}kms`}
          label="Distance parcouru"
        />
        <InputWrapper>
          <Label label="Nom operated CA" />
          {airlines && (
            <Select
              className="select-info"
              classNamePrefix="react-select"
              options={airlines}
              defaultValue={folder.ca_name_operated}
              placeholder={folder.ca_name_operated}
              value={folder.ca_name_operated}
              onChange={(event) => {
                saveValueInCase('ca_name_operated', event.value);
              }}
            />
          )}
        </InputWrapper>
        <InputWrapper>
          <Label label="Statut du vol" />
          <Select
            className="select-info"
            classNamePrefix="react-select"
            options={[{ label: 'sur-réservé', value: 'overbook' }, {
              label: 'annulé',
              value: 'annulation',
            }, { label: 'retardé', value: 'retard' }]}
            defaultValue={{ label: folder.flight_status, value: folder.flight_status }}
            placeholder={folder.flight_status}
            value={folder.flight_status}
            onChange={(event) => {
              saveValueInCase('flight_status', event.value);
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <Label label="Juridiction" />
          {jurisdictions && (
            <Select
              className="select-info"
              classNamePrefix="react-select"
              options={jurisdictions}
              defaultValue={folder.jurisdiction}
              placeholder={folder.jurisdiction}
              value={folder.jurisdiction}
              onChange={(event) => {
                saveValueInCase('jurisdiction', event.value);
              }}
            />
          )}
        </InputWrapper>
      </WrapperDoubleDiv>
    </GlobalWrapperDetails>
  );
};

export {
  Details,
};
