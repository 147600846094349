import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Button, FormControl, Input, InputLabel, Typography,
} from '@mui/material';
import { HeaderImg } from './LogoLogin';
import { Spacer } from '../../../../common/spacer/Spacer';
import { Loader } from '../../../../common/loader/loader';

const LoginContainer = ({
  login, errors, resetState, loading,
}) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  useEffect(() => {
    resetState();
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();
    await login({ email, password });
  };

  return (
    <MyLayoutLogin>
      <HeaderImg />
      <FormContainer>
        <Form
          method="post"
          id="loginForm"
          onSubmit={(e) => submitForm(e)}
        >
          <FormControl
            fullWidth
            required
            color="secondary"
            size="medium"
            style={{ width: '300px' }}
            variant="outlined"
            margin="dense"
          >
            <InputLabel focused>Email</InputLabel>
            <Input
              value={email || ''}
              type="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </FormControl>
          <Spacer medium />
          <FormControl
            fullWidth
            required
            color="secondary"
            size="medium"
            variant="outlined"
            margin="dense"
          >
            <InputLabel focused>Mot de passe</InputLabel>
            <Input
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              type="password"
              value={password || ''}
            />
          </FormControl>
          <FormControl
            size="medium"
            margin="dense"
          >
            <Button
              color="secondary"
              type="submit"
              variant="contained"
              form="loginForm"
            >
              Valider
            </Button>
          </FormControl>
          <Spacer medium />
          <ErrorMessageContainer>
            {errors
            && <Typography align="center" variant="body2" component="h6" color="error">{t(`${errors}`)}</Typography>}
          </ErrorMessageContainer>
        </Form>
        <Spacer large />
        <LoaderContainer>
          {loading && <Loader />}
        </LoaderContainer>
      </FormContainer>
    </MyLayoutLogin>
  );
};

const MyLayoutLogin = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const FormContainer = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Form = styled.form`
  width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
const ErrorMessageContainer = styled.div`
  height: 20px;
`;

const LoaderContainer = styled.div`
  height: 60px;
`;

export {
  LoginContainer,
};
