import {
  SEARCH_CLAIM_REQUEST,
  SEARCH_REQUEST,
  SEARCH_REQUEST_ERRORS,
  SEARCH_RESPONSE,
  TRIGGER_SEARCH_DRAWER,
} from './search.actions';

const initialSearchState = {
  isOpen: false,
  loading: false,
  errors: null,
  data: [],
  value: '',
};

export const searchReducer = (state = initialSearchState, action) => {
  switch (action.type) {
    case TRIGGER_SEARCH_DRAWER:
      return {
        ...state,
        isOpen: !state.isOpen,
      };

    case SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        value: action.payload,
      };

    case SEARCH_CLAIM_REQUEST:
      return {
        ...state,
        loading: true,
        value: action.payload,
      };

    case SEARCH_RESPONSE:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case SEARCH_REQUEST_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload.message,
      };
    default:
      return state;
  }
};
