import React from 'react';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';
import { ListWrapper } from '../../../../common/layout/wrapper';
import { createHeadTable, createRowsTable } from './tableutils';

const ListAudiences = ({
  audiences, loading = true, jurists, addJurists, push, search,
}) => {
  const options = jurists.map((jurist) => ({
    label: `${jurist.firstName} ${jurist.lastName}`,
    value: jurist._id,
  }));

  function selectJurist(jurists, audienceId) {
    addJurists(jurists, audienceId);
  }

  return (
    <div>
      <ListWrapper>
        <DynamicTableStateless
          head={createHeadTable(true)}
          rows={createRowsTable(audiences, options, selectJurist, push, search)}
          loadingSpinnerSize="large"
          isLoading={loading}
          isFixedSize
          paginationi18n={{
            prev: 'Précédent',
            next: 'Suivant',
          }}
        />
      </ListWrapper>
    </div>
  );
};

export {
  ListAudiences,
};
