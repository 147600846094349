import React from 'react';
import { LayoutManager, NavigationProvider } from '@atlaskit/navigation-next';
import { MyProductNavigation } from '../navigation';
import GlobalNavigation from '../globalnavigation';
import Search from '../../../searchcontext/adapters/primary';
import DossierContainer from '../submenu/containerdossier';
import { AudiencesSubmenu } from '../submenu/audience/audiences.submenu';
import { AudienceSubMenu } from '../submenu/audience/audience.submenu';

function renderMenuContent(pathname) {
  if (pathname === '/dossier') return 'DOSSIER';
  if (pathname === '/audiences') return 'AUDIENCES';
  if (pathname === '/audience') return 'AUDIENCE';
  return 'DEFAULT_MENU';
}

export const DashboardLayoutContainer = ({
  navigateInCase,
  pathname,
  dossier,
  children,
  handleSubMenuAudience,
  isOpen,
  navigateInAudience,
  audienceMenuState,
  audiencesMenuState,
  navigateInAudiences,
  push,
  search,
}) => {
  function getContainerNavigation() {
    if (renderMenuContent(pathname) === 'DOSSIER') {
      // eslint-disable-next-line react/no-unstable-nested-components,func-names
      return function () {
        return dossier ? (
          <DossierContainer
            navigateInCase={navigateInCase}
            dossier={dossier}
          />
        ) : null;
      };
    }
    if (renderMenuContent(pathname) === 'AUDIENCES' && isOpen) {
      // eslint-disable-next-line react/no-unstable-nested-components,func-names
      return function () {
        return (
          <AudiencesSubmenu
            goBack={handleSubMenuAudience}
            navigateInAudience={navigateInAudiences}
            audienceMenuState={audiencesMenuState}
          />
        );
      };
    }
    if (renderMenuContent(pathname) === 'AUDIENCE') {
      // eslint-disable-next-line react/no-unstable-nested-components,func-names
      return function () {
        return (
          <AudienceSubMenu
            push={push}
            search={search}
            navigateInAudience={navigateInAudience}
            audienceMenuState={audienceMenuState}
          />
        );
      };
    }
    return null;
  }

  return (
    <NavigationProvider>
      <LayoutManager
        collapseToggleTooltipContent={() => ({ text: 'Fermer', char: 'F' })}
        globalNavigation={() => <GlobalNavigation />}
        productNavigation={() => <MyProductNavigation handleSubMenuAudience={handleSubMenuAudience} />}
        containerNavigation={getContainerNavigation()}
      >
        <div style={{ padding: '0px 20px' }}>
          <Search />
          {children}
        </div>
      </LayoutManager>
    </NavigationProvider>
  );
};
