import React from 'react';
import styled from 'styled-components';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Tooltip } from '@mui/material';

const CopyPaste = ({ content, onClick }) => (
  <Tooltip title={content} onClick={onClick}>
    <IconButtonCustom color="primary">
      <ContentCopyIcon />
    </IconButtonCustom>
  </Tooltip>
);

const IconButtonCustom = styled(IconButton)`
  padding: 0 10px !important;
`;

export {
  CopyPaste,
};
