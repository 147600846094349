import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { CaseContainer } from './case.container';
import {
  selectCaseErrors,
  selectStateCase,
} from '../../../usescases/case/case.selector';
import {
  dismissErrorCase, initCase,
  retrieveCaseById,
} from '../../../usescases/case/case.action';
import { selectUser } from '../../../../usercontext/usescases/login/user.selector';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
  stateCase: selectStateCase(state),
  errors: selectCaseErrors(state),
  user: selectUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  push: (value) => dispatch(push(value)),
  retrieveCaseById: (id) => dispatch(retrieveCaseById(id)),
  dismissError: () => dispatch(dismissErrorCase()),
  initCase: () => dispatch(initCase()),
});

const CasePage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CaseContainer);

export default CasePage;
