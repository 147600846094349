import React from 'react';
import { Button } from '@mui/material';
import styled from 'styled-components';
import { withTheme } from '@mui/styles';

const ButtonCustom = ({
  text,
  margin = 'true',
  background,
  endIcon,
  type = 'button',
  onClick,
  startIcon,
  size = 'small',
  fullWidth = false,
  width = 'auto',
  height,
  disabled = false,
}) => (
  <ButtonCustomELement
    onClick={onClick}
    type={type}
    margin={margin}
    startIcon={startIcon}
    endIcon={endIcon}
    variant="contained"
    size={size}
    background={background}
    fullWidth={fullWidth}
    width={width}
    height={height}
    disabled={disabled}
  >
    {text}
  </ButtonCustomELement>
);

const ButtonCustomELement = withTheme(styled(Button)`
  background-color: ${({ background, theme, disabled }) => (disabled ? '#e4e4e4' : theme[background])} !important;
  color: ${(props) => (props.disabled || props.background !== 'default' ? '#ffffff' : '#505F79')} !important;
  text-transform: inherit !important;
  margin: ${(props) => (props.margin ? '0 5px !important' : '0')};
  width: ${(props) => (props.width ? `${props.width}px` : null)} !important;
  height: ${(props) => (props.height ? `${props.height}px` : null)} !important;
`);

export {
  ButtonCustom,
};
