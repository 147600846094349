import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { StatisticsContainer } from './statistics.container';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
});

const mapDispatchToProps = (dispatch) => ({
  push: (value) => dispatch(push(value)),
});

const Statistics = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StatisticsContainer);

export default Statistics;
