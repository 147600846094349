import {
  ADD_AIRHELP_COMPENSATION_LIST_ERROR,
  ADD_AIRHELP_COMPENSATION_LIST_REQUEST, ADD_AIRHELP_COMPENSATION_LIST_RESPONSE,
  DISMISS_ERROR_CASE_LIST,
  INIT_CASE_LIST,
  RETRIEVE_CASE_LIST_ERROR,
  RETRIEVE_CASE_LIST_REQUEST,
  RETRIEVE_CASE_LIST_RESPONSE,
  SELECT_ALL_ELEM,
  SELECT_ELEM_IN_LIST,
  UPDATE_STATUS_LIST_FAILED,
  UPDATE_STATUS_LIST_REQUEST,
  UPDATE_STATUS_LIST_RESPONSE,
} from './caselist.action';
import { getDateInfo } from "../../adapters/primary/listcases/tableutils";

const { v4: uuidv4 } = require('uuid');


export function getPeremptedClaim(flightDate) {
  const now = new Date();
  const { month, year, day } = getDateInfo(now);
  const fiveYearsAgo = new Date(year - 5, month, day);
  const soon = new Date(year - 5, month + 1, day);
  const flight = new Date(flightDate);
  return flight < fiveYearsAgo ? 'P' : flight <= soon && flight >= fiveYearsAgo
    ? "S" : "V"
}

const initialCaseListState = {
  list: [],
  loading: false,
  listSize: 0,
  errors: {
    list: null,
    updateStatus: [],
  },
  updateSuccess: null,
};

function updateStatus(item, status) {
  if (!item.selected) return item;
  return {
    ...item,
    status,
  };
}

export const caseListReducer = (state = initialCaseListState, action) => {
  switch (action.type) {
    case INIT_CASE_LIST:
      return {
        ...state,
        list: [],
      };
    case RETRIEVE_CASE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {
          list: null,
          updateStatus: [],
        },
      };

    case RETRIEVE_CASE_LIST_RESPONSE:
      return {
        ...state,
        list: [...state.list, ...action.payload.data],
        loading: false,
        listSize: action.payload.size,
      };

    case SELECT_ELEM_IN_LIST:
      const freshList = {
        ...state,
        list: state.list.map((elem) => ({
          ...elem,
          selected: elem.from_id === action.payload.elem.from_id ? !elem.selected : elem.selected,
        })),
      };
      return {
        ...freshList,
      };

    case RETRIEVE_CASE_LIST_ERROR:
      return {
        ...state,
        list: [],
        errors: {
          ...state.errors,
          list: action.payload.message,
        },
        loading: false,
      };

    case SELECT_ALL_ELEM:
      const freshState = {
        ...state,
        list: state.list.map((elem) => ({
          ...elem,
          selected: action.payload,
        })),
      };
      return {
        ...freshState,
      };

    case UPDATE_STATUS_LIST_REQUEST:
      const status = action.payload;
      const previousList = state.list;
      const list = state.list.map((item) => updateStatus(item, status));
      return {
        ...state,
        previousList,
        list,
      };

    case ADD_AIRHELP_COMPENSATION_LIST_REQUEST:
      return {
        ...state,
        loading: true
      }

    case ADD_AIRHELP_COMPENSATION_LIST_RESPONSE:
      setTimeout(() => window.location.reload(), 0)
      return {
        ...state,
        loading: false
      }

    case ADD_AIRHELP_COMPENSATION_LIST_ERROR:
      return {
        ...state,
        loading: false
      }

    case UPDATE_STATUS_LIST_RESPONSE:
      return {
        ...state,
        updateSuccess: action.payload,
      };

    case UPDATE_STATUS_LIST_FAILED:
      const errorsUpdate = {
        ...state.errors,
      };

      const id = uuidv4();
      errorsUpdate.updateStatus.unshift({
        created: Date.now(),
        description: `Message: ${action.payload.message}`,
        id,
        key: id,
        title: 'Ooopsy, une erreur est survenue !',
      });

      return {
        ...state,
        list: state.previousList,
        previousList: [],
        errors: errorsUpdate,
      };

    case DISMISS_ERROR_CASE_LIST:
      return {
        ...state,
        errors: {
          ...state.errors,
          updateStatus: state.errors.updateStatus.slice(1),
        },
      };

    default:
      return state;
  }
};