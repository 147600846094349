import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import {
  BoxSession, CustomCtn, SelectDiv, SquareContainer,
} from './cssForComponents';
import { getRandomColor, groupBy } from './statistics.container';
import { Text } from '../../common/text/Text';
import { useStatsList } from '../hooks/stat.hook';
import { Loader } from '../../common/loader/loader';

const StatisticHide = () => {
  const current_year = Number(format(new Date(), 'yyyy'));
  const year_1 = current_year - 2;
  const year_2 = current_year - 1;
  const { statisticHide } = useStatsList();
  const { data, isLoading } = statisticHide;
  const [dataCourt, setDataCourt] = useState({
    labels: [],
    datasets: [],
    total: null,
    date: '',
  });

  const [year, setYear] = useState({ value: 1, label: current_year });

  const montString = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  useEffect(() => {
    if (!data || data.data.length === 0) return;

    function fillArray2019(data, value = 4) {
      return [...Array(value).fill([0, []]), ...data];
    }

    async function getDataStat() {
      const domain = data.data.map((elem) => ({ ...elem, _id: { user: 'bot', ...elem._id } }));

      const groupByYear = groupBy(domain, (d) => d._id.year);
      const grouped = [...groupByYear][Number(year.value)];
      const labels = montString.map((e) => format(new Date(e), 'MMMM', { locale: fr }));
      const groupByMonth = groupBy(grouped[1], (a) => a._id.month);
      const datas = fillArray2019(groupByMonth);
      const formats = groupBy(datas.map((elem) => elem[1]).flat(), (a) => a._id.user);
      const sort = [...formats].map((f) => {
        const sorted = f[1].sort((a, b) => a._id.month - b._id.month);

        function FillArrayMonth(ok) {
          const data = [...ok];
          const defaultArray = ok.map((c) => c._id.month);
          const a = [...defaultArray];
          const count = 12;
          const missing = [];

          for (let i = 0; i <= count; i++) {
            if (a.indexOf(i) === -1) {
              missing.push(i);
            }
          }

          function insertAt(array, index, ...elementsArray) {
            array.splice(index, 0, ...elementsArray);
          }

          for (let i = 0; i < missing.length; i++) {
            const ind = missing[i];
            insertAt(data, ind, {
              _id: { month: ind },
              count: 0,
            });
          }
          return data;
        }

        const fill = FillArrayMonth(sorted);
        if (year.label !== '2019') {
          fill.shift();
        }

        const user = {
          '5cee556cf401440017513d23': 'W', // GG
          '5ddfacd4050b2500172526be': 'END', // Aurelie
          '5cece41bc04259001767099a': 'U', // Justine
          '5cece453c04259001767099b': 'LL', // LL
          '5cece4fdc04259001767099d': 'I', // Victoria
          '5cd02c47cc5b830017e9597f': 'R', // Jeff
          '5cece4b8c04259001767099c': 'H', // Thy
          '5cee54bc6112110017c5b65a': 'L', // Elo
          bot: 'bot',
        };

        const findAirline = fill.find((f) => f._id.user);
        const airline = user[findAirline._id.user];
        const counts = fill.map((f) => f.count);
        const backgroundColor = getRandomColor();

        return {
          data: counts,
          label: airline,
          fill: false,
          lineTension: 0.1,
          backgroundColor,
          borderColor: backgroundColor,
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: backgroundColor,
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: backgroundColor,
          pointHoverBorderColor: backgroundColor,
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
        };
      });
      setDataCourt({
        labels,
        total: 0,
        datasets: sort,
      });
    }

    getDataStat();
  }, [year, data]);

  return (
    <BoxSession>
      <Text component="h3" variant="subtitle2" color="primary">Transactions audiences :</Text>
      <SelectDiv>
        <Select
          value={year}
          options={[{ value: 1, label: current_year }, { value: 0, label: year_2 }, { value: 2, label: year_1 }]}
          onChange={(val) => setYear(val)}
        />
      </SelectDiv>
      {isLoading ? <LoaderContainer><Loader /></LoaderContainer>
        : (
          <SquareContainer>
            <CustomCtn width="100%">
              <Line
                data={dataCourt}
                width={100}
                height={50}
                options={{
                  plugins: {
                    legend: {
                      display: true,
                    },
                  },
                  maintainAspectRatio: false,
                }}
              />
            </CustomCtn>
          </SquareContainer>
        )}
    </BoxSession>
  );
};

const LoaderContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  StatisticHide,
};
