import {
  CLEAR_STATE,
  FAILED_TO_SEND_EMAIL,
  LOGIN_FAILED,
  LOGIN_REQUEST,
  LOGIN_RESPONSE,
  SEND_EMAIL_RESET_REQUEST,
  SEND_EMAIL_RESET_RESPONSE,
} from './user.action';

const initialLoginState = {
  token: localStorage.getItem('admin-token') || null,
  user: null,
  emailIsSend: false,
  loading: false,
  loginSuccess: false,
  errors: {
    email: null,
    login: null,
  },
};

export const userReducer = (state = initialLoginState, action) => {
  switch (action.type) {
    case CLEAR_STATE:
      return {
        token: localStorage.getItem('admin-token') || null,
        user: null,
        emailIsSend: false,
        loading: false,
        errors: {
          email: null,
          login: null,
        },
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        loginSuccess: false,
      };

    case LOGIN_RESPONSE:
      localStorage.setItem('admin-token', action.payload.token);
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        token: action.payload.token,
        loginSuccess: action.payload.success,
        errors: {
          login: null,
        },
      };

    case LOGIN_FAILED:
      const errorsLogin = {
        ...state.errors,
        login: action.payload?.validation?.body.message || action.payload.message,
      };

      return {
        ...state,
        loading: false,
        errors: errorsLogin,
      };

    case SEND_EMAIL_RESET_REQUEST:
      return {
        ...state,
        loading: true,
        emailIsSend: false,
      };

    case SEND_EMAIL_RESET_RESPONSE:
      return {
        ...state,
        loading: false,
        emailIsSend: action.payload.success,
      };

    case FAILED_TO_SEND_EMAIL:
      const errorsEmail = {
        ...state.errors,
        email: [action.payload.errors],
      };
      return {
        ...state,
        loading: false,
        emailIsSend: false,
        errors: errorsEmail,
      };

    default:
      return state;
  }
};
