import React from 'react';
import { Link } from 'react-router-dom';
import { green } from '@mui/material/colors';
import { format } from 'date-fns';
import { Text } from '../../../../common/text/Text';
import { CheckBoxCustom } from '../../../../common/input/inputCheckbox';

export const createHeadTable = (withWidth) => ({
  cells: [
    {
      key: 'juridiction',
      content: 'Juridiction',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 30 : undefined,
    },
    {
      key: 'datetime',
      content: 'Date',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 30 : undefined,
    },
    {
      key: 'nombre-de-passagers',
      content: 'Nombre de passagers',
      shouldTruncate: true,
      isSortable: false,
      width: withWidth ? 30 : undefined,
    },
    {
      key: 'juriste',
      content: 'Juriste assigné',
      shouldTruncate: true,
      isSortable: false,
      width: withWidth ? 30 : undefined,
    },
    {
      key: 'is-checked',
      content: 'Traité',
      shouldTruncate: true,
      isSortable: false,
      width: withWidth ? 30 : undefined,
    },
  ],
});

export function createKey(input) {
  return input ? input.toString().trim() : Math.random() * (200000);
}

export const createRowsTable = (list, search, toggleFinishAudience) => list.map((audience, index) => ({
  key: `${createKey(audience?._id)}`,
  cells: [
    {
      key: createKey(audience.jurisdiction),
      content: (<RenderGoodLinkList caseElement={audience} search={search} />),
    },
    {
      key: createKey(audience.hearingDate),
      content: (
        <Text color="secondary" variant="body2">{format(new Date(audience.hearingDate), 'dd/MM/yyy HH:mm')}</Text>),
    },
    {
      key: createKey(audience?._id),
      content: (<Text color="secondary" variant="body2">{audience.passengers.length}</Text>),
    },
    {
      key: createKey(`${Math.random()}-${index}`),
      // eslint-disable-next-line react/no-array-index-key
      content: (<ul>{audience.jurists.map((val, i) => (<li key={i}>{val.firstName}</li>))}</ul>),
    },
    {
      key: createKey(`${Math.random()}-${index}`),
      content: <CheckBoxCustom
        color={green[500]}
        onChange={() => toggleFinishAudience(audience?._id)}
        checked={audience.status === 'DONE'}
      />,
    },
  ],
}));

export const RenderGoodLinkList = ({ caseElement, search }) => (
  <Link to={{ pathname: '/audience', search: `?id=${caseElement?._id}&from=audiences&${search.slice(1)}` }}>
    <Text color="secondary" variant="body2">{caseElement.jurisdiction}</Text>
  </Link>
);
