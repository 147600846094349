import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import DynamicTable from '@atlaskit/dynamic-table';
import Select from '@atlaskit/select';
import { format } from 'date-fns';
import TextArea from '@atlaskit/textarea';
import DetailsContainer from './details';
import { createHeadCaseTable, rowsCaseTable } from './flight_information/flight_information.container';
import { GlobalWrapper } from '../common/GlobalWrapper';
import { PageHeaderList } from '../../../../../common/pageheaderlist/pageheaderlist';
import 'react-vertical-timeline-component/style.min.css';
import { Breadcrumbs } from '../../../../../common/breadcrumbs/breadcrumbs';
import { retrieveStatusOption, retrieveStatusString } from '../../listcases/tableutils';
import { ColorsTimeline, ErrorBoundary } from './components/timeline';
import { Lozenge } from '../../../../../common/lozenge/Lozenge';
import { Text } from '../../../../../common/text/Text';
import { ButtonCustom } from '../../../../../common/button/button';

const { data } = require('../../filterCaseList/dataFilters');

const retrieveStatusStringInLogs = (status) => data.STATUS_LEGACY.find((e) => e.value === Number(status)).label;

const retrieveHearingDate = (folder, type) => {
  const passengers = folder.passengers.map((date) => ({
    date: date.hearings ? date.hearings.filter((d) => String(d.type).toUpperCase() === String(type).toUpperCase()) : [],
    name: date.fullName(),
  }));
  return (
    <div style={{ padding: '0 10px', marginBottom: '20px' }}>
      {passengers && passengers.map((passenger) => (
        <div key={`${passenger.name.trim()}index`}>
          {passenger.date && passenger.date.length > 0 && (
            <div>
              <Text color="secondary" variant="body2">{passenger.name}</Text>
              {passenger.date.map((d, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <p key={`${i}-index-${new Date()}`} style={{ margin: '3px 0' }}>
                  <DateLink
                    target="_blank"
                    style={{ color: '#0065FF' }}
                    href={`/audience?id=${d._id}&paginateSize=50&from=dossiers`}
                  >
                    {format(new Date(d.hearingDate), 'dd/MM/yyyy HH:mm')}
                  </DateLink>
                </p>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const retrieveDate = (e, value) => {
  if (e.action.hasOwnProperty('status') && value.includes(e.action.status.n)) {
    return e;
  }
};

function getDateForParticularStatus(logs, status) {
  if (logs.filter((l) => l.action).some((e) => retrieveDate(e, status))) {
    return format(new Date(logs.find((e) => retrieveDate(e, status)).date), 'dd/MM/yyyy HH:mm');
  }
  return '';
}

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: '#0052CC',
    ':hover': {
      color: '#0052CC',
    },
    svg: {
      color: '#fff',
    },
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#fff',
  }),
  input: (styles) => ({ ...styles, color: '#fff' }),
  placeholder: (styles) => ({ ...styles, color: '#fff' }),
};

const InformationContainer = ({
  retrieveLogsAutomatics,
  automaticsLogs,
  folder,
  sendComment,
  retrieveHearingDates,
  updateStatusClaim,
  syncWithAlgolia,
}) => {
  const [comment, setComment] = useState('');
  useEffect(() => {
    if (folder) {
      retrieveLogsAutomatics(folder._id);
      if (folder.passengers.some((v) => v.hasOwnProperty('hearings'))) {
        return;
      }
      const ids = folder.passengers.map((p) => p._id);
      for (const id of ids) {
        retrieveHearingDates(id);
      }
    }
  }, [folder]);

  if (!folder) return null;

  return (
    <GlobalWrapper>
      <ListWrapper>
        <PageHeaderList
          actionsContent={null}
          breadcrumbs={(
            <Breadcrumbs
              text="Dossiers"
              folder={folder}
              key="DossiersKey"
              location="/dossiers"
              fullName={folder.fullNameMainPassenger()}
              from_id={folder.from_id}
            />
          )}
          bottomBar={null}
        />
      </ListWrapper>
      <WrapperTwoBlock>
        <ContainerLeft>
          <ListContainerLeft>
            <TitleContainer>
              <Text variant="h6" component="h5" color="primary">Details</Text>
            </TitleContainer>
            <ContentContainerLeft>
              <DetailsContainer />
            </ContentContainerLeft>
          </ListContainerLeft>
          <ButtonCustom
            background="purple"
            text="Synchroniser avec la barre de recherche"
            onClick={() => syncWithAlgolia(folder._id)}
          />
          <ListContainerLeft>
            <TitleContainer>
              <Text variant="h6" component="h5" color="primary">Informations sur le vol</Text>
            </TitleContainer>
            <ContentContainerLeft>
              <DynamicTable
                caption="Liste des vols sur ce dossier"
                head={createHeadCaseTable(true)}
                rows={rowsCaseTable(folder.flightInfos || [])}
                loadingSpinnerSize="large"
                isLoading={false}
                isFixedSize
              />
            </ContentContainerLeft>
          </ListContainerLeft>
          <ListContainerLeft>
            <TitleContainer>
              <Text variant="h6" component="h5" color="primary">Activités</Text>
            </TitleContainer>
            <ContentContainerLeft>
              <div>
                <WrapperInputComment>
                  <TextArea
                    placeholder="Écrire un commentaire"
                    resize="auto"
                    onChange={(event) => setComment(event.target.value)}
                    value={comment}
                  />
                  <ButtonCustom
                    height="56"
                    background="blue"
                    onClick={() => sendComment(comment)}
                    disabled={!comment}
                    text="Envoyer"
                  />
                </WrapperInputComment>
                <VerticalTimeline
                  layout="1-column"
                  animate={false}
                >
                  {folder.logs && folder.logs.map((e) => (
                    <GetVerticalTimelineElement key={e._id} action={e.action} user={e.user} date={e.date} />))}
                </VerticalTimeline>
              </div>
            </ContentContainerLeft>
          </ListContainerLeft>
        </ContainerLeft>
        <ContainerRight>
          <ListContainerRight>
            <TitleContainer>
              <Text variant="h6" component="h5" color="primary">Vie du dossier</Text>
            </TitleContainer>
            <ContentContainerRight>
              <Text variant="body2" component="p">
                Med envoyée :
                {getDateForParticularStatus(folder.logs, [2])}
              </Text>
              <Text variant="body2" component="p">
                Conciliation envoyée
                :
                {getDateForParticularStatus(folder.logs, [29])}
              </Text>
              <Text variant="body2" component="p">
                Saisine envoyée
                :
                {getDateForParticularStatus(folder.logs, [8])}
              </Text>
              <Text variant="body2" component="p">Date de conciliation fixée :</Text>
              <div>{folder.passengers.some((p) => p.hasOwnProperty('hearings')) && retrieveHearingDate(folder, 'conciliation')}</div>
              <Text variant="body2" component="p">Date de jugement fixée :</Text>
              <div>{folder.passengers.some((p) => p.hasOwnProperty('hearings')) && retrieveHearingDate(folder, 'jugement')}</div>
              <Text variant="body2" component="p">
                Dossier clos
                :
                {getDateForParticularStatus(folder.logs, [16, 17, 6])}
              </Text>
            </ContentContainerRight>
          </ListContainerRight>
          <ListContainerRightWhite>
            <TitleContainer>
              <Text variant="h6" component="h5" color="primary">Statut</Text>
            </TitleContainer>
            <ContentContainerRight>
              <Select
                styles={colourStyles}
                className="select-info"
                classNamePrefix="react-select"
                options={data.STATUS}
                defaultValue={retrieveStatusString(folder.status)}
                placeholder={retrieveStatusString(folder.status)}
                value={retrieveStatusOption(folder.status)}
                onChange={(event) => {
                  updateStatusClaim(event.value, folder._id);
                }}
              />
            </ContentContainerRight>
          </ListContainerRightWhite>
          <ListContainerRightWhite>
            <TitleContainer>
              <Text variant="h6" component="h5" color="primary">Tâches</Text>
            </TitleContainer>
            <ContentContainerRight>
              <ListTasksWrapper>
                {retrieveStatusOption(folder.status).tasks.map((task) => (
                  <li key={task.trim()}>{task}</li>))}
              </ListTasksWrapper>
            </ContentContainerRight>
          </ListContainerRightWhite>
          {automaticsLogs
          && (
            <ListContainerRightWhite>
              <TitleContainer>
                <Text variant="h6" component="h5" color="primary">Automatisation email</Text>
              </TitleContainer>
              <ContentContainerRight>
                <ErrorBoundary>
                  <ColorsTimeline data={automaticsLogs} />
                </ErrorBoundary>
              </ContentContainerRight>
            </ListContainerRightWhite>
          )}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text variant="caption">
              {folder.created_at ? `Crée le : ${format(new Date(folder.created_at), 'dd/MM/yyyy HH:mm')}` : 'Impossible de récupérer la date sur Airhelp'}
            </Text>
            <Text variant="caption">
              {`Mis à jour le : ${format(new Date(folder.updated_at), 'dd/MM/yyyy HH:mm')}`}
            </Text>
          </div>
        </ContainerRight>
      </WrapperTwoBlock>
    </GlobalWrapper>
  );
};

const GetVerticalTimelineElement = ({ action, date, user = null }) => (
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    iconStyle={{
      background: 'rgb(33, 150, 243)',
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      lineHeight: '40px',
      fontWeight: 'bold',
      fontSize: '20px',
    }}
    icon={<p>{user ? `${user.firstName.substring(0, 1)}` : 'C'}</p>}
  >
    <Lozenge yellow text={user ? `${user.firstName} ${user.lastName}` : 'CRM BOT'} />
    <strong
      style={{ marginLeft: 10, fontSize: 10, color: '#C1C7D0' }}
    >
      {format(new Date(date), 'dd/MM/yyyy HH:mm')}
    </strong>
    {action && action.hasOwnProperty('status')
    && (
      <StepContainer>
        {'A modifié le status '}
        <Lozenge red text={retrieveStatusStringInLogs(action.status.p) || 0} />
        {' à '}
        <Lozenge green text={retrieveStatusStringInLogs(action.status.n) || 0} />
      </StepContainer>
    )}
    {action.hasOwnProperty('airline')
    && (
      <StepContainer>
        {'A modifié la compagnie aérienne '}
        <Lozenge red text={action.airline.p} />
        {' à '}
        <Lozenge green text={action.airline.n} />
      </StepContainer>
    )}
    {action.hasOwnProperty('jurisdiction')
    && (
      <StepContainer>
        {'A modifié la juridiction '}
        <Lozenge red text={action.jurisdiction.p} />
        {' à '}
        <Lozenge green text={action.jurisdiction.n} />
      </StepContainer>
    )}
    {action.hasOwnProperty('custom') && (
      <StepContainer>
        {`Message : ${action.custom.message}`}
      </StepContainer>
    )}
  </VerticalTimelineElement>
);

const ListTasksWrapper = styled.ul`
  padding-bottom: 20px;
  margin: 0px;
`;

const ListWrapper = styled.div`
  min-width: 600px;
  width: 100%;
  padding: 0;
`;

const WrapperInputComment = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  input {
    min-width: 300px;
  }

  button {
    align-self: flex-end;
    margin-left: 20px;
  }
`;

const WrapperTwoBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const ContainerLeft = styled.div`
  width: 65%;
`;

const ContainerRight = styled.div`
  width: 30%;
`;

const ListContainerLeft = styled.div`
  background: #ffffff;
  box-shadow: none !important;
  margin-bottom: 35px !important;
`;

const ListContainerRight = styled.div`
  background: #fafafa !important;
  box-shadow: none !important;
  margin-bottom: 35px !important;
  border-radius: 6px;
`;

const ListContainerRightWhite = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TitleContainer = styled.div`
  padding: 20px 0 0 20px;

  h5 {
    text-decoration: underline;
    font-size: 15px !important;
    font-weight: bold;
  }
`;

const ContentContainerLeft = styled.div`
  padding: 20px;

  > div {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  td {
    font-size: 14px;
    color: #172B4D;
  }
`;

const ContentContainerRight = styled.div`
  padding: 20px;

  p {
    font-size: 14px;
    margin: 5px 0;
    font-weight: 500;
  }
`;

const DateLink = styled.a`
  :hover {
    text-decoration: underline !important;
  }
`;

const StepContainer = styled.div`
  margin: 0 !important;
  font-size: 12px !important;
  font-weight: 500;
`;

export {
  InformationContainer,
  retrieveStatusStringInLogs,
};
