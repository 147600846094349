import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PriorityHighestIcon from '@atlaskit/icon-priority/glyph/priority-highest';
import PriorityLowestIcon from '@atlaskit/icon-priority/glyph/priority-lowest';
import PriorityMediumIcon from '@atlaskit/icon-priority/glyph/priority-medium';
import Tooltip from '@atlaskit/tooltip';
import { data } from '../filterCaseList/dataFilters';
import { Text } from '../../../../common/text/Text';
import { CheckBoxCustom } from '../../../../common/input/inputCheckbox';
import TimerIcon from "@mui/icons-material/Timer";

export const createHeadTable = (withWidth, checkAll) => ({
  cells: [
    {
      key: 'selected',
      content: (
        <div>
          <CheckBoxCustom
            onChange={(event) => checkAll(event.target.checked)}
            name="checkbox-basic"
          />
        </div>),
      shouldTruncate: true,
      isSortable: false,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'fullName',
      content: 'Prénom Nom',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 30 : undefined,
    },
    {
      key: 'ca_name_operated',
      content: 'CA',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 25 : undefined,
    },
    {
      key: 'juridiction',
      content: 'Juridiction',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 25 : undefined,
    },
    {
      key: 'amount',
      content: 'Montant',
      shouldTruncate: false,
      isSortable: true,
      width: withWidth ? 25 : undefined,
    },
    {
      key: 'status',
      content: 'Statut',
      shouldTruncate: true,
      isSortable: true,
      width: withWidth ? 25 : undefined,
    },
    {
      key: 'created',
      content: 'Date de création',
      shouldTruncate: false,
      isSortable: true,
      width: withWidth ? 25 : undefined,
    },
    {
      key: 'updated_at',
      content: 'Dernière modification',
      shouldTruncate: false,
      isSortable: true,
      width: withWidth ? 25 : undefined,
    },
    {
      key: 'DocumentsConfidence',
      content: 'Confidence',
      shouldTruncate: false,
      isSortable: true,
      width: withWidth ? 25 : undefined,
    },
    {
      key: 'final_compensation',
      content: 'FC',
      shouldTruncate: false,
      isSortable: true,
      width: withWidth ? 10 : undefined,
    },
    {
      key: 'claim_perempted',
      content: 'État',
      shouldTruncate: false,
      isSortable: true,
      width: withWidth ? 15 : undefined,
    }, {
      key: 'medWasSentDuringTheLastThirtyDays',
      content: 'MED30',
      shouldTruncate: false,
      isSortable: true,
      width: withWidth ? 10 : undefined,
    },
  ],
});

export function createKey(input) {
  return input ? input.toString().trim() : Math.random() * (200000);
}

function finalCompensationIcon(value, compare) {
  if (value) {
    if (`${value}` === `${compare}`) {
      return (
        <RotatePriorityIcon>
          <Tooltip content="final comp. = comp. total">
            <PriorityLowestIcon
              size="medium"
            />
          </Tooltip>
        </RotatePriorityIcon>
      )
    } else
      return (
        <RotatePriorityIcon>
          <Tooltip content="final comp. ≠ comp. total">
            <PriorityHighestIcon
              size="medium"
            />
          </Tooltip>
        </RotatePriorityIcon>
      );
  } else return (
    <PriorityMediumIcon
      size="medium"
    />
  )
}


function getDocumentsConfidenceIcon(index) {
  if (index === 'FAILED') {
    return (
      <RotatePriorityIcon>
        <Tooltip content="Documents incomplets pour saisine">
          <PriorityHighestIcon
            size="medium"
            primaryColor="blue"
          />
        </Tooltip>
      </RotatePriorityIcon>
    );
  }
  if (index === 'SUCCESS') {
    return (
      <RotatePriorityIcon>
        <Tooltip content="Documents complets pour saisine">
          <PriorityLowestIcon
            size="medium"
            primaryColor="blue"
          />
        </Tooltip>
      </RotatePriorityIcon>
    );
  }
  if (index === 'DEFAULT') {
    return (
      <Tooltip content="Documents non taggués"><PriorityMediumIcon size="medium" primaryColor="blue" /></Tooltip>);
  }
  return (<Tooltip content="La claim n'a pas encore été analysé"><PriorityMediumIcon size="medium" primaryColor="blue" /></Tooltip>);
}

export const createDate = (date) => new Date(date).toLocaleDateString('fr-FR');

export const retrieveSourceString = (source) => data.SOURCE[source].label;

export const retrieveStatusString = (status) => data.STATUS.find((e) => e.value === Number(status))?.label;
export const retrieveStatusCancelling = (status) => data.DESISTEMENT.find((e) => e.value === Number(status))?.label;

export const retrieveStatusOption = (status) => data.STATUS.find((e) => e.value === Number(status));

export const RenderGoodLinkList = ({ caseElement, path }) => (
  <Link to={{ pathname: '/dossier', search: `?id=${caseElement._id}&from=dossiers&${path.slice(1)}` }}>
    <Text variant="body2">{caseElement.fullNameMainPassenger()}</Text>
  </Link>
);

export function getDateInfo(now) {
  const month = now.getMonth();
  const year = now.getFullYear();
  const day = now.getDate();
  return { month, year, day };
}

export function getPeremptedClaim(flightDate) {
  const now = new Date();
  const { month, year, day } = getDateInfo(now);
  const fiveYearsAgo = new Date(year - 5, month, day);
  const soon = new Date(year - 5, month + 4, day);
  const flight = new Date(flightDate);
  return flight < fiveYearsAgo ? <p style={{ color: 'red' }}>P</p> : flight <= soon && flight >= fiveYearsAgo
    ? <p style={{ color: 'orange' }}>S</p> : <p style={{ color: 'green' }}>V</p>;
}

function activePassengers(p) {
  return p.active;
}

export const createRowsTable = (list, selectElemInList, path) => list.map((caseElement, index) => {
  const receivedCompensation = caseElement.passengers.filter(activePassengers).reduce((a, c) => (a + (c.compensation_received || 0)), 0);

  return ({
    key: `row-${index}-${caseElement.fullNameMainPassenger().trim()}`,
    cells: [
      {
        key: createKey(caseElement._id),
        content: (<CheckBoxCustom
          checked={caseElement.selected}
          onChange={() => selectElemInList(list, caseElement)}
          name="checkbox-basic"
        />),
      },
      {
        key: createKey(caseElement.fullNameMainPassenger()),
        content: (<RenderGoodLinkList caseElement={caseElement} path={path} />),
      },
      {
        key: createKey(caseElement.ca_name_operated),
        content: (<Text color="secondary" variant="body2">{caseElement.ca_name_operated}</Text>),
      },
      {
        key: createKey(caseElement.jurisdiction),
        content: (<Text color="secondary" variant="body2">{caseElement.jurisdiction}</Text>),
      },
      {
        key: createKey(caseElement.compensation),
        content: (
          <Text color="secondary" variant="body2">
            {parseInt(caseElement.compensation, 10)}
            €
          </Text>),
      },
      {
        key: createKey(caseElement.status),
        content: (<Text color="secondary" variant="body2">{retrieveStatusString(caseElement.status)}</Text>),
      },
      {
        key: caseElement.created,
        content: (<Text color="secondary" variant="body2">{createDate(caseElement.created)}</Text>),
      },
      {
        key: caseElement.updated_at,
        content: (
          <Text color="secondary" variant="body2">{createDate(caseElement.updated_at)}</Text>),
      },
      {
        key: createKey(caseElement.documentsState),
        content: (getDocumentsConfidenceIcon(caseElement.documentsState)),
      },
      {
        key: createKey(caseElement.final_compensation),
        content:
          (
            <Text color="secondary" variant="caption">
              {finalCompensationIcon(caseElement.final_compensation, receivedCompensation)}
            </Text>
          )
      },
      {
        key: createKey(caseElement.flight_date),
        content: (getPeremptedClaim(caseElement.flight_date)),
      },
      {
        key: createKey(caseElement._id),
        content: (caseElement?.medWasSentDuringTheLastThirtyDays ? <TimerIcon fontSize="small" /> : null),
      },
    ],
  })
});

const RotatePriorityIcon = styled.div`
  transform: rotate(180deg);
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
