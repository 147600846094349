export function debounce(a, b, c) {
  let d;
  let e;
  // eslint-disable-next-line func-names
  return function () {
    function h() {
      // eslint-disable-next-line no-use-before-define
      d = c || (e = a.apply(f, g));
    }

    let f = this;
    // eslint-disable-next-line prefer-rest-params
    let g = arguments;
    // eslint-disable-next-line no-sequences
    return clearTimeout(d), d = setTimeout(h, b), c && !d && (e = a.apply(f, g)), e;
  };
}
