import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { userReducer } from '../usercontext/usescases/login/user.reducer';
import { caseListReducer } from '../casescontext/usescases/caseList/caselist.reducer';
import { filtersListReducer } from '../casescontext/usescases/filtersList/filterslist.reducer';
import { searchReducer } from '../searchcontext/usescases/search.reducer';
import { caseReducer } from '../casescontext/usescases/case/case.reducer';
import { documentsReducer } from '../documentscontext/usescases/documents.reducer';
import { convocationReducer } from '../convocationcontext/usescases/convocation.reducer';
import { audienceReducer } from '../audiencecontext/usecases/audience.reducer';
import { navigationReducer } from '../navigationcontext/usecases/navigation.reducer';

export default (history) => combineReducers({
  router: connectRouter(history),
  user: userReducer,
  caseList: caseListReducer,
  case: caseReducer,
  filterList: filtersListReducer,
  search: searchReducer,
  documents: documentsReducer,
  convocation: convocationReducer,
  audience: audienceReducer,
  navigation: navigationReducer,
});
