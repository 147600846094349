import * as React from 'react';
import { Autocomplete, InputLabel, TextField } from '@mui/material';
import styled from 'styled-components';
import { Spacer } from '../spacer/Spacer';

const AutocompleteCustom = ({
  label,
  options,
  value,
  onChange,
  placeholder,
  id,
  withLabel = true,
  multiple = false,
  padding = true,
  width = '250px',
  variant = 'outlined',
}) => (
  <Container padding={padding}>
    {withLabel && <InputLabel>{label}</InputLabel>}
    {withLabel && <Spacer small />}
    {options && (
      <Autocomplete
        id={id}
        fullWidth
        size="small"
        options={options}
        value={value}
        multiple={multiple}
        onChange={(event, value) => {
          onChange(value);
        }}
        style={{ width }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder}
            variant={variant}
          />
        )}
      />
    )}
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.padding ? '0 20px' : '0 5px')};
  height: ${(props) => (props.padding ? '90px' : null)};

`;

export {
  AutocompleteCustom,
};
