import React, { useEffect, useState } from 'react'
import { BoxSession, CustomCtn, SelectDiv, SquareContainer } from "./cssForComponents";
import { Text } from "../../common/text/Text";
import { Bar } from 'react-chartjs-2';
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { UseStatsGetStatPaidClaimByLegalEntity } from "../hooks/stat.hook";
import { Loader } from "../../common/loader/loader";
import styled from "styled-components";
import Select from "react-select";
import { Spacer } from "../../common/spacer/Spacer";

function PaidClaimByLegalEntity() {
  const currentYear = format(new Date(), 'yyyy')
  const [year, setYear] = useState({label: currentYear, value: currentYear})
  const queryInfo = UseStatsGetStatPaidClaimByLegalEntity(year)
  const montString = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  const labels = montString.map((e) => format(new Date(e), 'MMMM', {locale: fr}));

  const options = {
    plugins: {
      tooltip: {
        display: true,
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const retrieveDatas = (type) => {
    let data = []
    queryInfo?.data?.data?.forEach((e) => {
      if (e?.legal_entity[0] === type) {
        data.push(e.count)
      }
    })
    return data
  }

  const datasets = {
    labels,
    datasets: [
      {
        label: 'HK',
        data: retrieveDatas("HK"),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'GmbH',
        data: retrieveDatas("GmbH"),
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: 'Autres',
        data: retrieveDatas(null),
        backgroundColor: 'rgba(53, 162, 100, 1)',
      }
    ]
  }

  useEffect(() => {
    queryInfo.refetch().then(r => r)
  }, [year])

  return (
    <BoxSession>
      <Text component="h3" variant="subtitle2" color="primary">Passager appelée en audience par entité :</Text>
      <SelectDiv>
        <Select
          value={year}
          options={[
            {value: currentYear, label: currentYear},
            {value: currentYear - 1, label: currentYear - 1},
            {value: currentYear - 2, label: currentYear - 2},
            {value: currentYear - 3, label: currentYear - 3},
          ]}
          onChange={(val) => setYear(val)}
        />
      </SelectDiv>
      <Spacer medium/>
      <SquareContainer>
        <CustomCtn width="100%">
          {queryInfo.isFetching ?
            <LoaderContainer><Loader/></LoaderContainer>
            : <Bar
              data={datasets}
              options={options}
              />
          }
        </CustomCtn>
      </SquareContainer>
    </BoxSession>
  )
}

const LoaderContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  PaidClaimByLegalEntity
}
