import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { ConvocationContainer } from './convocation.container';
import {
  addConvocationRequest,
  addDateToConvocation,
  addType,
  clearConvocation,
  dissmissError,
  removePassenger,
  searchRequestConvocation,
  selectJurisdiction,
  selectPassenger,
  updateConvocation,
  updateFieldPassenger,
  addJurists,
} from '../../usescases/convocation.actions';
import {
  selectAddConvocationResponse,
  selectConvocationSearchedData,
  selectConvocationSearchedSelected,
  selectConvocationSearchErrors,
  selectConvocationSearchLoading,
  selectConvocationType,
  selectDateTimeConvocation,
  selectedJuristConvocation,
  selectJurisdictionConvocation,
} from '../../usescases/convocation.selector';
import { retrieve_jurisdictions_request } from '../../../casescontext/usescases/filtersList/filterlist.action';
import { selectJurisdictions } from '../../../casescontext/usescases/filtersList/filterslist.selector';
import {
  clearAudience,
  retrieveAudienceRequest,
  retrieveJurists,
} from '../../../audiencecontext/usecases/audience.actions';
import { selectAudience, selectJurists } from '../../../audiencecontext/usecases/audience.selector';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
  data: selectConvocationSearchedData(state),
  loading: selectConvocationSearchLoading(state),
  selected: selectConvocationSearchedSelected(state),
  jurisdictions: selectJurisdictions(state),
  selectedJurisdiction: selectJurisdictionConvocation(state),
  datetime: selectDateTimeConvocation(state),
  success: selectAddConvocationResponse(state),
  errors: selectConvocationSearchErrors(state),
  type: selectConvocationType(state),
  audience: selectAudience(state),
  jurists: selectJurists(state),
  selectedJurist: selectedJuristConvocation(state),
});

const mapDispatchToProps = (dispatch) => ({
  push: (route) => dispatch(push(route)),
  query: (value) => dispatch(searchRequestConvocation(value)),
  selectPassenger: (passenger) => dispatch(selectPassenger(passenger)),
  remove: (passenger) => dispatch(removePassenger(passenger)),
  updatePassenger: (_id, field, value) => dispatch(updateFieldPassenger(_id, field, value)),
  retrieve_jurisdictions: () => dispatch(retrieve_jurisdictions_request()),
  selectJurisdiction: (value) => dispatch(selectJurisdiction(value)),
  addConvocation: () => dispatch(addConvocationRequest()),
  addDateToConvocation: (datetime) => dispatch(addDateToConvocation(datetime)),
  dissmissError: () => dispatch(dissmissError()),
  addType: (type) => dispatch(addType(type)),
  retrieveAudience: (id) => dispatch(retrieveAudienceRequest(id)),
  clearAudience: () => dispatch(clearAudience()),
  clearConvocation: () => dispatch(clearConvocation()),
  updateConvocation: () => dispatch(updateConvocation()),
  getJurists: () => dispatch(retrieveJurists()),
  addJurists: (jurists) => dispatch(addJurists(jurists)),
});

const ConvocationPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConvocationContainer);

export default ConvocationPage;
