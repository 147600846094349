import React from 'react';
import { Workbook } from 'exceljs';
import { format } from 'date-fns';
import * as fs from 'file-saver';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { ButtonCustom } from '../../../../../common/button/button';

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return Object.fromEntries(map);
}

const ButtonGenerateExcelRibAudience = ({ audience }) => {
  const passengers = audience.passengers.filter((p) => p.checked);

  function getAge(DOB) {
    const today = new Date();
    const birthDate = new Date(DOB);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age -= 1;
    }
    return age;
  }

  const DataExcelRib = () => {
    const data = [];
    const group = groupBy(passengers, (passenger) => passenger.record.from_id);

    const hasMainPax = (value) => value.some((v) => v.principal);
    for (const [, value] of Object.entries(group)) {
      if (value.length === 0) alert('Il y a une erreur pour un des passagers');
      if (value.length === 1) {
        data.push({
          customer: value[0].fullName(),
          opponent: value[0].record.ca_name_operated,
          nature: 'Responsabilité',
          amount: value[0].compensation_received + value[0].article_700 + value[0].interest,
          transmitter: value[0].record.ca_name_operated,
          quality: 'Adversaire',
          source_of_funds: 'France',
        });
      } else if (value.length > 1) {
        if (hasMainPax(value)) {
          const main = value.find((p) => p.principal);
          const sum = value.reduce((total, next) => {
            total += next.compensation_received + next.article_700 + next.interest;
            return total;
          }, 0);
          data.push({
            customer: main.fullName(),
            opponent: main.record.ca_name_operated,
            nature: 'Responsabilité',
            amount: sum,
            transmitter: main.record.ca_name_operated,
            quality: 'Adversaire',
            source_of_funds: 'France',
          });
        } else {
          const isMajor = value.find((p) => getAge(p.hearingDate) >= 18);
          const notMain = isMajor || value[0];
          const sum = value.reduce((total, next) => {
            total += next.compensation_received + next.article_700 + next.interest;
            return total;
          }, 0);
          data.push({
            customer: notMain.fullName(),
            opponent: notMain.record.ca_name_operated,
            nature: 'Responsabilité',
            amount: sum,
            transmitter: notMain.record.ca_name_operated,
            quality: 'Adversaire',
            source_of_funds: 'France',
          });
        }
      }
    }
    return data;
  };

  const downloadExcel = (data) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(`RIB CARPA - ${format(new Date(audience.hearingDate), 'dd-MM-yyyy')} à ${audience.jurisdiction}`);
    const header = ['Client/Vendeur', 'Adversaire/Acquéreur', 'Nature', 'Montant', 'Nom de l\'émetteur', 'Qualité de l\'émetteur', 'Provenance des fonds'];
    worksheet.getRow(3).values = header;
    const fName = `RIB CARPA - ${format(new Date(audience.hearingDate), 'dd-MM-yyyy')} à ${audience.jurisdiction}`;

    worksheet.getRow(1).font = {
      size: 16,
      bold: true,
      color: { argb: 'FFFFFFFF' },
    };

    worksheet.getRow(3).alignment = { vertical: 'top', horizontal: 'left' };

    worksheet.getRow(3).font = {
      size: 14,
      bold: true,
    };

    worksheet.getCell('A1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF0052CC' },
    };

    worksheet.columns = [
      { width: 24 },
      { width: 24 },
      { width: 17 },
      { width: 11 },
      { width: 24 },
      { width: 24 },
      { width: 24 },
    ];
    for (const x1 of data) {
      const x2 = Object.keys(x1);
      const temp = [];
      for (const y of x2) {
        temp.push(x1[y]);
      }
      worksheet.addRow(temp);
    }
    worksheet.getColumn(4).numFmt = '#,##0.00" €";[Red]-#,##0.00" €"';
    worksheet.mergeCells('A1', 'G1');
    worksheet.getCell('A1').value = `Demandes de RIB CARPA - ${new Date(audience.hearingDate).toLocaleString('fr')} à ${audience.jurisdiction}`;
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, `${fName}-${new Date().valueOf()}.xlsx`);
    });
  };
  return (
    <ButtonCustom
      disabled={passengers.length === 0}
      background="lightBlue"
      text="Excel CARPA"
      startIcon={<AccountBalanceIcon />}
      onClick={() => downloadExcel(DataExcelRib())}
    />
  );
};

export {
  ButtonGenerateExcelRibAudience,
};
