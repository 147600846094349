import React from 'react';
import { Input, InputLabel } from '@mui/material';
import { FormControlCustom, ItemsContainer } from './styledCssCommon';

const InputNumber = ({
  label, onBlur, defaultValue, name, disabled = false,
}) => (
  <ItemsContainer>
    <FormControlCustom fullWidth>
      <InputLabel variant="standard" style={{ fontWeight: 'bold' }}>
        {label}
      </InputLabel>
      <Input
        disabled={disabled}
        onBlur={onBlur}
        variant="standard"
        type="number"
        defaultValue={defaultValue}
        name={name}
      />
    </FormControlCustom>
  </ItemsContainer>
);

export {
  InputNumber,
};
