import React from 'react';
import { format } from 'date-fns';
import { Tooltip, Typography } from '@mui/material';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h3>Une erreur est survenue.</h3>;
    }

    return this.props.children;
  }
}

const ColorsTimeline = ({ data }) => {
  const hasHearing = (data) => {
    const hearing = data.find((d) => d.type === 'PAX_EMAIL_HEARING_DATE_NOTIFICATION');
    return {
      success: hearing ? hearing.gmail && hearing.gmail.length > 0 ? 'primary' : 'secondary' : 'grey',
      date: hearing ? hearing.created_at : null,
      email: hearing ? hearing.gmail && hearing.gmail.envelope && hearing.gmail.envelope.to : null,
    };
  };

  const hasCourt = (data) => {
    const court = data.find((d) => d.type === 'PAX_EMAIL_COURT_NOTIFICATION');
    return {
      success: court ? court.gmail && court.gmail.length > 0 ? 'primary' : 'secondary' : 'grey',
      date: court ? court.created_at : null,
      email: court ? court.gmail && court.gmail.envelope && court.gmail.envelope.to : null,
    };
  };

  const hasDelayConfirmation = (data) => {
    const delayConfirmation = data.find((d) => d.type === 'PAX_DELAY_CONFIRMATION');
    return {
      success: delayConfirmation ? delayConfirmation.gmail && delayConfirmation.gmail.length > 0 ? 'primary' : 'secondary' : 'grey',
      date: delayConfirmation ? delayConfirmation.created_at : null,
      email: delayConfirmation ? delayConfirmation.gmail && delayConfirmation.gmail.envelope && delayConfirmation.gmail.envelope.to : null,
    };
  };

  const hasMed = (data) => {
    const med = data.find((d) => d.type === 'AUTOMATIC_MED');
    return {
      success: med ? med.message === 'MED SUCCESSFULLY SENT' || med.message === 'MED SUCCESSFULLY SENT TO VOLOTEA FORM' || med.message === 'MED SUCCESSFULLY SENT TO AIR AUSTRAL FORM' ? 'primary' : 'secondary' : 'grey',
      date: med ? med.created_at : null,
      email: med ? med.gmail && med.gmail.envelope.to : null,
    };
  };

  const hearing = React.useMemo(() => hasHearing(data), [data]);
  const court = React.useMemo(() => hasCourt(data), [data]);
  const med = React.useMemo(() => hasMed(data), [data]);
  const confirmation = React.useMemo(() => hasDelayConfirmation(data), [data]);

  return (
    <Timeline position="alternate">
      <TimelineItem>
        <TimelineOppositeContent>
          {med.date
            ? (
              <Typography
                variant="overline"
                color="textSecondary"
              >
                {format(new Date(med.date), 'dd/MM/yyyy')}
              </Typography>
            )
            : null}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color={med.success} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          {med.email ? (
            <Tooltip title={`${med.email}`} arrow>
              <Typography variant="overline" color="textSecondary">MED</Typography>
            </Tooltip>
          ) : <Typography variant="overline" color="textSecondary">MED</Typography>}
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          {confirmation.date
            ? (
              <Typography
                variant="overline"
                color="textSecondary"
              >
                {format(new Date(confirmation.date), 'dd/MM/yyyy')}
              </Typography>
            )
            : null}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color={confirmation.success} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          {confirmation.email ? (
            <Tooltip title={`${confirmation.email}`} arrow>
              <Typography variant="overline" color="textSecondary">Confirmation</Typography>
            </Tooltip>
          ) : <Typography variant="overline" color="textSecondary">Confirmation</Typography>}
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          {court.date ? (
            <Typography
              variant="overline"
              color="textSecondary"
            >
              {format(new Date(court.date), 'dd/MM/yyyy')}
            </Typography>
          ) : null}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color={court.success} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          {court.email ? (
            <Tooltip title={`${court.email}`} arrow>
              <Typography variant="overline" color="textSecondary">Saisine</Typography>
            </Tooltip>
          ) : <Typography variant="overline" color="textSecondary">Saisine</Typography>}
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          {hearing.date ? (
            <Typography
              variant="overline"
              color="textSecondary"
            >
              {format(new Date(hearing.date), 'dd/MM/yyyy')}
            </Typography>
          ) : null}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color={hearing.success} />
        </TimelineSeparator>
        <TimelineContent>
          {hearing.email ? (
            <Tooltip title={`${hearing.email}`} arrow>
              <Typography variant="overline" color="textSecondary">Audience</Typography>
            </Tooltip>
          ) : <Typography variant="overline" color="textSecondary">Audience</Typography>}
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};

export {
  ErrorBoundary,
  ColorsTimeline,
};
