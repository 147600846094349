import React from 'react';
import { format } from 'date-fns';
import {
  retrieveStatusCancelling,
  retrieveStatusString
} from '../../../../casescontext/adapters/primary/listcases/tableutils';
import { Text } from '../../../../common/text/Text';
import { CheckBoxCustom } from '../../../../common/input/inputCheckbox';

function toCapitalize(string) {
  const stringToFormat = string.split(' ');
  let newString = '';
  // eslint-disable-next-line array-callback-return
  stringToFormat.map((word) => {
    newString += word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    newString += ' ';
  });
  return newString;
}

function createKey(input) {
  return input ? input.toString().trim() : Math.random() * (200000);
}

const createHeadTableAudience = (witdhWidth, selectAll) => ({
  cells: [
    {
      key: 'checkbox',
      content: (<CheckBoxCustom
        onChange={selectAll}
        name="checkbox-basic"
      />),
      width: witdhWidth ? 3 : undefined,
    },
    {
      key: 'name',
      content: 'Prénom Nom',
      shouldTruncate: true,
      isSortable: true,
      width: witdhWidth ? 15 : undefined,
    },
    {
      key: 'rg',
      content: 'RG',
      shouldTruncate: true,
      isSortable: true,
      width: witdhWidth ? 13 : undefined,
    },
    {
      key: 'idAH',
      content: 'ID AirHelp',
      shouldTruncate: true,
      isSortable: true,
      width: witdhWidth ? 10 : undefined,
    },
    {
      key: 'ca_name_operated',
      content: 'Airline',
      shouldTruncate: true,
      isSortable: true,
      width: witdhWidth ? 20 : undefined,
    },
    {
      key: 'statut',
      content: 'statut',
      shouldTruncate: true,
      isSortable: true,
      width: witdhWidth ? 20 : undefined,
    },
    {
      key: 'intention',
      content: 'intention',
      shouldTruncate: true,
      isSortable: true,
      width: witdhWidth ? 20 : undefined,
    },
    {
      key: 'date 1',
      content: 'Date de renvoi',
      shouldTruncate: true,
      isSortable: true,
      width: witdhWidth ? 10 : undefined,
    },
    {
      key: 'date 2',
      content: 'Date précédente',
      shouldTruncate: true,
      isSortable: true,
      width: witdhWidth ? 10 : undefined,
    },
    {
      key: 'date 3',
      content: 'Date précédente 2',
      shouldTruncate: true,
      isSortable: true,
      width: witdhWidth ? 10 : undefined,
    },
  ],
});

function ifIsNotDated(string) {
  return !string ? ' ' : format(new Date((string)), 'dd/MM/yyyy');
}

export const createRowsTableAudience = (list, currentAudience, selectBox, push, audienceId) => list.map((passenger) => {
  const nextDate = ifIsNotDated(passenger.hearingDate.sort((a, b) => new Date(a) - new Date(b)).filter((f) => f > currentAudience)[0]);
  const prevDate = ifIsNotDated(passenger.hearingDate.sort((a, b) => new Date(a) - new Date(b)).filter((f) => f < currentAudience)[passenger.hearingDate.sort((a, b) => new Date(a) - new Date(b)).filter((f) => f < currentAudience).length - 1]);
  const prevDate2 = ifIsNotDated(passenger.hearingDate.sort((a, b) => new Date(a) - new Date(b)).filter((f) => f < currentAudience)[passenger.hearingDate.sort((a, b) => new Date(a) - new Date(b)).filter((f) => f < currentAudience).length - 2]);

  return ({
    key: `${createKey(passenger._id)}`,
    cells: [
      {
        key: 'checkbox',
        content: (<CheckBoxCustom
          checked={passenger.checked}
          onChange={() => selectBox(passenger._id)}
          name="checkbox-basic"
        />),
      },
      {
        key: createKey(passenger.firstName),
        content: (
          <a
            href={`/dossier?id=${passenger?.record?._id}&paginateSize=50&from=audience&audienceId=${audienceId}`}
          >
            {toCapitalize(passenger.fullName())}
          </a>),
      },
      {
        key: createKey(passenger.rg_number),
        content: (<Text color="secondary" variant="body2">{passenger.rg_number}</Text>),
      },
      {
        key: createKey(passenger?.record?.from_id),
        content: (
          <a
            target="_blank"
            href={`https://cockpit.airhelp.com/claims/${passenger?.record?.from_id}#claim-info`}
            rel="noreferrer"
          >
            {passenger?.record?.from_id}
          </a>),
      },
      {
        key: createKey(passenger?.record?.ca_name_operated),
        content: (<Text color="secondary" variant="body2">{passenger?.record?.ca_name_operated}</Text>),
      },
      {
        key: createKey(passenger?.record?.status),
        content: (<Text color="secondary" variant="body2">{retrieveStatusString(passenger?.record?.status)}</Text>),
      },
      {
        key: createKey(passenger?.cancelling),
        content: (<Text color="secondary" variant="body2">{retrieveStatusCancelling(passenger?.cancelling)}</Text>),
      },
      {
        key: createKey(currentAudience),
        content: (<Text color="secondary" variant="body2">{nextDate}</Text>),
      },
      {
        key: createKey(passenger.hearingDate[0]),
        content: (
          <Text color="secondary" variant="body2">
            {prevDate}
          </Text>),
      },
      {
        key: createKey(passenger.hearingDate[1]),
        content: (
          <Text color="secondary" variant="body2">
            {prevDate2}
          </Text>),
      },
    ],
  });
});

export {
  createHeadTableAudience,
};
