import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import { Case } from '../../../../casescontext/domain/case';

function mapCaseList(cases) {
  if (cases.message === 'Token is not valid') {
    localStorage.clear();
    return window.location = '/';
  }
  return {
    ...cases,
    data: cases.data.map((el) => Case({ ...el })),
  };
}

function isSelected(item) {
  return item.selected;
}

function mapListOptions(options) {
  return {
    ...options,
    data: options.data.map((option) => ({
      label: option,
      value: option,
    })),
  };
}

export function DocumentsGatewayRest() {
  return {
    retrieveCaseList(queryString) {
      return ajax.getJSON(`${process.env.REACT_APP_API_URL}/api/record/getList${queryString === undefined ? '' : queryString}`, {
        'x-access-token': localStorage.getItem('admin-token'),
      })
        .pipe(
          map((cases) => mapCaseList(cases)),
        );
    },
    retrieveOperatingAirlines() {
      return ajax.getJSON(`${process.env.REACT_APP_API_URL}/api/record/getAirlines`, {
        'x-access-token': localStorage.getItem('admin-token'),
      }).pipe(
        map((airlines) => mapListOptions(airlines)),
      );
    },
    retrieveJurisdictions() {
      return ajax.getJSON(`${process.env.REACT_APP_API_URL}/api/record/getJurisdiction`, {
        'x-access-token': localStorage.getItem('admin-token'),
      }).pipe(
        map((jurisdictions) => mapListOptions(jurisdictions)),
      );
    },
    generateDocumentsList(list, type) {
      const recordsId = list.filter(isSelected).map((item) => item._id);

      return ajax({
        url: `${process.env.REACT_APP_API_URL}/api/generate/${type}/list`,
        method: 'POST',
        body: {
          recordsId,
        },
        headers: {
          'x-access-token': localStorage.getItem('admin-token'),
          'Content-Type': 'application/json',
        },
      });
    },
    generateZipList(list, type) {
      const recordsId = list.filter(isSelected).map((item) => item._id);
      if (type === 'zip_debit_authorization') {
        return ajax({
          url: `${process.env.REACT_APP_API_URL}/api/generate/accounting/zip`,
          method: 'POST',
          responseType: 'arraybuffer',
          progressObserver: '',
          body: {
            recordsId,
          },
          headers: {
            'x-access-token': localStorage.getItem('admin-token'),
            'Content-Type': 'application/json',
          },
        });
      }
      return ajax({
        url: `${process.env.REACT_APP_API_URL}/api/download/zip`,
        method: 'POST',
        responseType: 'arraybuffer',
        progressObserver: '',
        body: {
          recordsId,
          type,
        },
        headers: {
          'x-access-token': localStorage.getItem('admin-token'),
          'Content-Type': 'application/json',
        },
      });
    },
    generateCourtDocument(list, params) {
      const filteredList = list.filter((l) => l.selected);
      const passengers = filteredList.map((p) => {
        // eslint-disable-next-line no-sequences
        if (p.fullNameMainPassenger(), p.passengers.some((c) => c.principal && c.active)) {
          return {
            pax: p.passengers.find((p) => p.principal),
            airline: p.ca_name_operated,
          };
        }
        return {
          pax: p.passengers.find((p) => p.active),
          airline: p.ca_name_operated,
        };
      });

      let isSaisine = true;
      filteredList.forEach((elem) => {
        if (!elem.documents.some((doc) => doc.type === 'saisine')) {
          isSaisine = false;
        }
      });

      return ajax({
        url: `${process.env.REACT_APP_API_URL}/api/generate/greffe`,
        method: 'POST',
        responseType: 'arraybuffer',
        progressObserver: '',
        body: {
          airline_name: params.airline_name,
          passengers,
          jurisdiction: params.jurisdiction,
          type: isSaisine,
        },
        headers: {
          'x-access-token': localStorage.getItem('admin-token'),
          'Content-Type': 'application/json',
        },
      });
    },
  };
}
