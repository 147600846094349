import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .sc-XhUPp.ceSpUV {
    z-index: 1 !important;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  }

  .link-form {
    font-size: 12px;
    text-decoration: underline;
  }

  .link-form:hover {
    color: #0052CC;
    transition: all .3s ease;
  }

  a {
    text-decoration: none;
  }

  .title-rg {
    font-weight: 700;
  }

  label {
    font-size: 14px;
  }

  p {
    font-size: 14px;
  }

  .box-input-search {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #FFFFFF;
    color: #42526E;
    display: flex;
    height: 36px;
    padding-bottom: 2px;
    border-bottom: 2px solid #2684FF;
    z-index: 10;
    width: 90%;
  }

  .div-input-inside {
    padding-right: 16px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-grow: 1;
  }

  .input-search {
    background-color: #FFFFFF;
    border: 0;
    color: #42526E;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    font-size: 1.4em;
    outline: 0;
  }

  .collapse-card-user-info {
    width: 100% !important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap !important;
    background: transparent !important;
    box-shadow: none !important;
  }

  .collapse-card-user-info > div:nth-child(1) {
    justify-content: start;
  }

  .collapse-card-user-info > div:nth-child(1) > h5 {
    width: 100px;
  }

  .select-info div:nth-child(1) {
  }

  tr td p {
    margin: 0;
  }

  .vertical-timeline-element--work {
    background: #fff;
  }

  .vertical-timeline-element-content {
    background: #fff !important;
    box-shadow: 0 4px 9px rgba(0, 0, 0, 0.1) !important;
  }

  .vertical-timeline-element-content:before {
    border-right: 10px solid #fff !important;
  }

  .vertical-timeline-text {
    font-size: 12px !important;
    margin: 0 !important;
  }

  .vertical-timeline-element-title {
    font-size: 15px !important;
    margin: 0 !important;
  }

  .vertical-timeline-element-icon {
    box-shadow: inherit !important;
    background: #0052CC !important;
  }

  .selected-route:hover, a:hover {
    text-decoration: inherit !important;
  }
`;

export {
  GlobalStyle,
};
