import { connect } from 'react-redux';
import {
  selectCase, selectDocumentLoading,
} from '../../../../usescases/case/case.selector';
import { DocummentsContainer } from './documents.container';
import {
  generateDocumentRequest,
  removeDocumentsRequest,
  retrieveCaseById,
  selectAllDocuments,
  selectDocumentsFiles,
} from '../../../../usescases/case/case.action';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
  folder: selectCase(state),
  documentLoading: selectDocumentLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  selectDocuments: (documents) => dispatch(selectDocumentsFiles(documents)),
  selectAllDocs: () => dispatch(selectAllDocuments()),
  generateDocuments: (type) => dispatch(generateDocumentRequest(type)),
  removeSelectedDocuments: () => dispatch(removeDocumentsRequest()),
  retrieveCase: (id) => dispatch(retrieveCaseById(id)),
});

const DocumentsPages = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocummentsContainer);

export default DocumentsPages;
