const translations = {
  en: {
    translation: {
      'Incorrect password, please verify or reset your password!': 'Incorrect password, please verify or reset your password !',
      'No user found with this email!': 'No user found with this email !',
      '"email" must be a valid email': 'Please insert a valid email.',
    },
  },
  fr: {
    translation: {
      'Incorrect password, please verify or reset your password!': 'Mot de passe incorrect !',
      'No user found with this email!': 'Aucun utilisateur trouvé avec cet e-mail !',
      '"email" must be a valid email': 'Merci de saisir un e-mail valide !',
    },
  },
};

export {
  translations,
};
