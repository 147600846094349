import React, { useState } from 'react';
import styled from 'styled-components';
import { Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ButtonActionGroupPersonnes } from './buttonactiongroup';
import { ButtonCustom } from '../../../../../../common/button/button';
import { Loader } from '../../../../../../common/loader/loader';
import { FileUploaderMenuItem } from '../../../../../../common/button/fileUploaderMenuItem';
import alarm from "../../../../../../assets/sound/alarm.mp4";
import WarningIcon from '@mui/icons-material/Warning';

const { data } = require('../../../filterCaseList/dataFilters');

const ActionsContentCaseDocumentList = ({
  fileLoader,
  setFileLoader,
  retrieveFiles,
  generateDocuments,
  removeSelectedDocuments,
  removeIsDisabled,
  folder,
  documentLoading,
}) => (
  <div style={{ display: 'flex' }}>
    {
      fileLoader ?
        <LoaderContainerAbsolute>
          <Loader />
        </LoaderContainerAbsolute>
        :
        <>
          <QuittanceSelectionMenu retrieveFiles={(e) => retrieveFiles(e)} setFileLoader={setFileLoader} />
          <ImportFileMenu retrieveFiles={(e) => retrieveFiles(e)} setFileLoader={setFileLoader} />
        </>
    }
    {
      documentLoading
        ? (
          <LoaderContainer>
            <Loader size={20} />
          </LoaderContainer>
        )
        : (
          <ButtonActionGroupPersonnes
            folder={folder}
            titleTrigger="Générer un document"
            data={data}
            type="DOCUMENTS"
            update={(type) => generateDocuments(type)}
          />
        )
    }
    <DeleteSelectionMenu
      remove={() => removeSelectedDocuments()}
      removeIsDisabled={removeIsDisabled}
    />
  </div>
);

const DeleteSelectionMenu = ({ remove, removeIsDisabled }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <ButtonCustom
        id="demo-positioned-button"
        onClick={handleClick}
        text="..."
        endIcon={<KeyboardArrowDownIcon />}
        background="default"
      />
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem disabled>Action rapide</MenuItem>
        <MenuItem disabled={removeIsDisabled} onClick={() => remove()}>Supprimer les documents sélectionnés</MenuItem>
      </Menu>
    </div>
  );
};

const QuittanceSelectionMenu = ({ retrieveFiles, setFileLoader }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);

  };
  return (
    <div>
      <ButtonCustom
        id="demo-positioned-button"
        onClick={handleClick}
        text="Importer quittance"
        startIcon={<WarningIcon />}
        endIcon={<KeyboardArrowDownIcon />}
        background="red"
      />
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <audio
          style={{ display: "none" }}
          autoPlay
          src={alarm}>
          Your browser does not support the
          <code>audio</code> element.
        </audio>
        <MenuItem disabled>Action dangereuse</MenuItem>
        <FileUploaderMenuItem
          setFileLoader={setFileLoader}
          onChange={(e) => retrieveFiles(e)}
          text="Cliquez seulement en connaissance de cause !"
          name="quittance"
          multiple
          id="fileUpload"
        />
      </Menu>
    </div>
  );
};

function ImportFileMenu({ retrieveFiles, setFileLoader }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const data = [{
    text: 'Saisine',
    name: 'saisine'
  }, {
    text: 'MED',
    name: 'med'
  }, {
    text: 'Document',
    name: 'document'
  }, {
    text: 'Passeport ou ID',
    name: 'passport_or_id'
  }, {
    text: 'E-tickets',
    name: 'eticket_for_all_passengers'
  }, {
    text: 'Pièces',
    name: 'pdf'
  }]

  return (
    <div>
      <ButtonCustom
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleClick}
        background="blue"
        text="Importer un document"
      />
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {
          data.map((e, i) => (
            <div key={e + i.toString()}>
              <FileUploaderMenuItem
                onChange={(e) => retrieveFiles(e)}
                text={e.text}
                name={e.name}
                multiple
                id="fileUpload"
                setFileLoader={setFileLoader}
              />
            </div>
          ))
        }
      </Menu>
    </div>
  );
}

const LoaderContainer = styled.div`
  width: 144.86px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 5px
`;


const LoaderContainerAbsolute = styled.div`
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 9000000000000;
  background: black;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  ActionsContentCaseDocumentList,
};
