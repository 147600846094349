export const RETRIEVE_AUDIENCES = 'RETRIEVE_AUDIENCES';
export const RETRIEVE_AUDIENCES_RESPONSE = 'RETRIEVE_AUDIENCES_RESPONSE';
export const RETRIEVE_AUDIENCES_ERROR = 'RETRIEVE_AUDIENCES_ERROR';
export const RETRIEVE_JURISTS_REQUEST = 'RETRIEVE_JURISTS_REQUEST';
export const RETRIEVE_JURISTS_RESPONSE = 'RETRIEVE_JURISTS_RESPONSE';
export const RETRIEVE_JURISTS_ERRORS = 'RETRIEVE_JURISTS_ERRORS';
export const ADD_JURISTS_REQUEST = 'ADD_JURISTS_REQUEST';
export const ADD_JURISTS_RESPONSE = 'ADD_JURISTS_RESPONSE';
export const RETRIEVE_MY_AUDIENCE_REQUEST = 'RETRIEVE_MY_AUDIENCE_REQUEST';
export const RETRIEVE_MY_AUDIENCE_RESPONSE = 'RETRIEVE_MY_AUDIENCE_RESPONSE';
export const RETRIEVE_AUDIENCE_REQUEST = 'RETRIEVE_AUDIENCE_REQUEST';
export const RETRIEVE_AUDIENCE_RESPONSE = 'RETRIEVE_AUDIENCE_RESPONSE';
export const SELECT_ALL_CHECKBOX_AUDIENCE = 'SELECT_ALL_CHECKBOX_AUDIENCE';
export const SELECT_BOX_AUDIENCE = 'SELECT_BOX_AUDIENCE';
export const CLEAR_STATE_AUDIENCE = 'CLEAR_STATE_AUDIENCE';
export const ADD_AUDIENCE_INFORMATION = 'ADD_AUDIENCE_INFORMATION';
export const ADD_AUDIENCE_INFORMATION_RESPONSE = 'ADD_AUDIENCE_INFORMATION_RESPONSE';
export const ADD_AUDIENCE_INFORMATION_ERROR = 'ADD_AUDIENCE_INFORMATION_ERROR';
export const DISMISS_AUDIENCE_FLAG_ERROR = 'DISMISS_AUDIENCE_FLAG_ERROR';
export const UPDATE_STATUS_CLAIMANT = 'UPDATE_STATUS_CLAIMANT';
export const ADD_CONVOCATION_FROM_AUDIENCE = 'ADD_CONVOCATION_FROM_AUDIENCE';
export const ADD_CONVOCATION_FROM_AUDIENCE_RESPONSE = 'ADD_CONVOCATION_FROM_AUDIENCE_RESPONSE';
export const ADD_CONVOCATION_FROM_AUDIENCE_ERROR = 'ADD_CONVOCATION_FROM_AUDIENCE_ERROR';
export const ADD_ERRORS_AUDIENCE = 'ADD_ERRORS_AUDIENCE';
export const GENERATE_ZIP_CCL = 'GENERATE_ZIP_CCL';
export const GENERATE_ZIP_CCL_RESPONSE = 'GENERATE_ZIP_CCL_RESPONSE';
export const GENERATE_ZIP_CCL_ERROR = 'GENERATE_ZIP_CCL_ERROR';
export const GENERATE_ZIP_AUDIENCE = 'GENERATE_ZIP_AUDIENCE';
export const GENERATE_ZIP_AUDIENCE_RESPONSE = 'GENERATE_ZIP_AUDIENCE_RESPONSE';
export const GENERATE_ZIP_AUDIENCE_ERROR = 'GENERATE_ZIP_AUDIENCE_ERROR';
export const UPDATE_STATUS_AUDIENCE_LIST_REQUEST = 'UPDATE_STATUS_AUDIENCE_LIST_REQUEST';
export const UPDATE_CANCELLING_AUDIENCE_LIST_REQUEST = 'UPDATE_CANCELLING_AUDIENCE_LIST_REQUEST';
export const UPDATE_STATUS_AUDIENCE_LIST_RESPONSE = 'UPDATE_STATUS_AUDIENCE_LIST_RESPONSE';
export const TOGGLE_FINISH_AUDIENCE_REQUEST = 'TOGGLE_FINISH_AUDIENCE_REQUEST';
export const TOGGLE_FINISH_AUDIENCE_RESPONSE = 'TOGGLE_FINISH_AUDIENCE_RESPONSE';

export const toggleFinishRequest = (audienceId) => ({
  type: TOGGLE_FINISH_AUDIENCE_REQUEST,
  payload: audienceId,
});

export const toggleFinishResponse = () => ({
  type: TOGGLE_FINISH_AUDIENCE_RESPONSE,
});

export const clearAudience = () => ({
  type: CLEAR_STATE_AUDIENCE,
});
export const retrieveAudiences = (query) => ({
  type: RETRIEVE_AUDIENCES,
  payload: query,
});

export const retrieveAudiencesResponse = (audiences) => ({
  type: RETRIEVE_AUDIENCES_RESPONSE,
  payload: audiences,
});

export const retrieveAudiencesError = (err) => ({
  type: RETRIEVE_AUDIENCES_ERROR,
  payload: err,
});

export const retrieveJurists = () => ({
  type: RETRIEVE_JURISTS_REQUEST,
});

export const retrieveJuristsResponse = (jurists) => ({
  type: RETRIEVE_JURISTS_RESPONSE,
  payload: jurists,
});

export const retrieveJuristsErrors = (error) => ({
  type: RETRIEVE_JURISTS_ERRORS,
  payload: error,
});

export const addJurists = (jurists, audienceId) => ({
  type: ADD_JURISTS_REQUEST,
  payload: { jurists, audienceId },
});

export const addJuristsResponse = (response) => ({
  type: ADD_JURISTS_RESPONSE,
  payload: response,
});

export const retrieveMyAudiences = (search) => ({
  type: RETRIEVE_MY_AUDIENCE_REQUEST,
  payload: search,
});

export const retrieveMyAudiencesResponse = (response) => ({
  type: RETRIEVE_MY_AUDIENCE_RESPONSE,
  payload: response,
});

export const retrieveAudienceRequest = (id) => ({
  type: RETRIEVE_AUDIENCE_REQUEST,
  payload: id,
});

export const retrieveAudienceResponse = (audience) => ({
  type: RETRIEVE_AUDIENCE_RESPONSE,
  payload: audience,
});

export const selectAll = () => ({
  type: SELECT_ALL_CHECKBOX_AUDIENCE,
});

export const selectBox = (id) => ({
  type: SELECT_BOX_AUDIENCE,
  payload: id,
});

export const saveAudienceInformation = (value) => ({
  type: ADD_AUDIENCE_INFORMATION,
  payload: value,
});

export const saveAudienceInformationResponse = (response) => ({
  type: ADD_AUDIENCE_INFORMATION_RESPONSE,
  payload: response,
});

export const saveAudienceInformationError = (error) => ({
  type: ADD_AUDIENCE_INFORMATION_ERROR,
  payload: error,
});

export const removeFlag = () => ({
  type: DISMISS_AUDIENCE_FLAG_ERROR,
});

export const addConvocationFromAudience = (convocation) => ({
  type: ADD_CONVOCATION_FROM_AUDIENCE,
  payload: convocation,
});

export const addConvocationFromAudienceResponse = (response) => ({
  type: ADD_CONVOCATION_FROM_AUDIENCE_RESPONSE,
  payload: response,
});

export const addConvocationFromAudienceError = (error) => ({
  type: ADD_CONVOCATION_FROM_AUDIENCE_ERROR,
  payload: error,
});

export const addErrorsAudience = (error) => ({
  type: ADD_ERRORS_AUDIENCE,
  payload: error,
});

export const generateZipCCL = (passengers) => ({
  type: GENERATE_ZIP_CCL,
  payload: passengers,
});

export const generateZipCCLResponse = (response) => ({
  type: GENERATE_ZIP_CCL_RESPONSE,
  payload: response,
});

export const generateZipCCLError = (error) => ({
  type: GENERATE_ZIP_CCL_ERROR,
  payload: error,
});

export const generateZipAudience = (passengers, type) => ({
  type: GENERATE_ZIP_AUDIENCE,
  payload: { passengers, type },
});

export const generateZipAudienceResponse = (response) => ({
  type: GENERATE_ZIP_AUDIENCE_RESPONSE,
  payload: response,
});

export const generateZipAudienceError = (error) => ({
  type: GENERATE_ZIP_AUDIENCE_ERROR,
  payload: error,
});

export const updateStatusSelectedCaseCourtRequest = (status) => ({
  type: UPDATE_STATUS_AUDIENCE_LIST_REQUEST,
  payload: status,
});

export const updateCancellingSelectedCaseCourtRequest = (cancelling) => ({
  type: UPDATE_CANCELLING_AUDIENCE_LIST_REQUEST,
  payload: cancelling,
});

export const updateStatusSelectedCaseCourtResponse = (response) => ({
  type: UPDATE_STATUS_AUDIENCE_LIST_RESPONSE,
  payload: response,
});
