import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import * as PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import { Button, IconButton, InputBase } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ConstructionIcon from '@mui/icons-material/Construction';
import { GlobalContainerDashboard } from '../../../common/layout/ContainerDashboard';
import { PageHeaderList } from '../../../common/pageheaderlist/pageheaderlist';
import { Breadcrumbs } from '../../../common/breadcrumbs/breadcrumbs';
import { SearchPassengersInput, WrapperButtonGroup } from '../../../searchcontext/adapters/primary/search.container';
import { debounce } from '../../../searchcontext/adapters/primary/debounce';
import { FlagGroupError } from '../../../common/error/error';
import { Label } from '../../../casescontext/adapters/primary/case/common/wrapper';
import { CopyPaste } from '../../../common/copypaste/copypaste';
import { ButtonCustom } from '../../../common/button/button';
import { Text } from '../../../common/text/Text';
import { AutocompleteCustom } from '../../../common/input/autocomplete';
import { data as datas } from '../../../casescontext/adapters/primary/filterCaseList/dataFilters';
import { TimePickerWithHour } from '../../../common/input/inputCalendarWithHour';

const queryString = require('query-string');

const ActionsContentConvocation = (
  {
    addConvocation,
    isDisabled,
    isUpdate,
    updateConvocation,
    returnOnAudience,
  }) => {
  if (isUpdate) {
    return (
      <>
        <ButtonCustom
          background="orange"
          text="Retourner sur l'audience"
          onClick={returnOnAudience}
        />
        <ButtonCustom
          background="blue"
          text="Modifier la convocation"
          onClick={updateConvocation}
          endIcon={<ConstructionIcon/>}
          disabled={isDisabled}
        />
      </>
    );
  }
  return (
    <ButtonCustom
      background="blue"
      text="Ajouter la convocation"
      onClick={addConvocation}
      endIcon={<AddIcon/>}
      disabled={isDisabled}
    />
  );
};

const ListWrapper = styled.div`
  min-width: 600px;
  width: 100%;
  padding: 0;
`;

const ConvocationContainer = ({
                                query,
                                data,
                                addDateToConvocation,
                                selectPassenger,
                                selected,
                                remove,
                                updatePassenger,
                                jurisdictions,
                                retrieve_jurisdictions,
                                selectedJurisdiction,
                                selectJurisdiction,
                                addConvocation,
                                datetime,
                                errors,
                                dissmissError,
                                addType,
                                type,
                                search,
                                retrieveAudience,
                                audience,
                                clearAudience,
                                updateConvocation,
                                clearConvocation,
                                push,
                                getJurists,
                                jurists,
                                selectedJurist,
                                addJurists,
                              }) => {
  const {id} = queryString.parse(search);

  const options = useMemo(() => jurists.map((jurist) => ({
    label: `${jurist.firstName} ${jurist.lastName}`,
    value: jurist._id,
  })), [jurists, audience]);

  useEffect(() => {
    getJurists();
  }, []);

  useEffect(() => {
    retrieve_jurisdictions();
  }, []);

  useEffect(() => {
    clearAudience();
    clearConvocation();

    if (id) {
      retrieveAudience(id);
    }
  }, [id]);

  useEffect(() => {
    if (audience && id && jurisdictions) {
      const juridiction = jurisdictions.filter((j) => j.value === audience.jurisdiction);
      addDateToConvocation(new Date(audience.hearingDate));
      selectJurisdiction(juridiction[0]);
      addType({value: audience.type, label: audience.type});
      audience.passengers.forEach((d) => selectPassenger({
        claim: d.record,
        passenger: d,
      }));
    }

    if (audience && id && 'jurists' in audience && audience.jurists) {
      addJurists(audience.jurists.map((j) => ({value: j._id, label: `${j.firstName} ${j.lastName}`})));
    }
  }, [audience, jurisdictions]);

  function returnOnAudience() {
    const {id} = queryString.parse(search);
    push(`/audience?id=${id}&from=audiences`);
  }

  function checkIfDateIsValid(date) {
    const currentYear = new Date().getFullYear();
    const currentDate = new Date(date).getFullYear();
    return currentDate < currentYear;
  }

  function isDisabled() {
    if (selected.length === 0) return true;
    if (!datetime || checkIfDateIsValid(datetime)) return true;
    return !selectedJurisdiction;
  }

  const isDisabledButton = React.useMemo(() => isDisabled(), [selected, datetime, selectedJurisdiction]);

  function addConvocationWithCheckOnJurisdiction() {
    const allEqual = (arr) => arr.every((v) => v.claim.jurisdiction === selected[0].claim.jurisdiction);
    const equal = allEqual(selected);
    if (equal === false && !selectedJurisdiction) {
      return alert('Attention les juridictions ne sont pas toutes similaires entre les passagers');
    }
    addConvocation();
  }

  function Copy() {
    const clipboard = selected.map(({passenger}) => `${passenger.firstName} ${passenger.lastName} - ${passenger.rg_number}\n`).join('');
    copy(clipboard);
  }

  function handleChange(value) {
    if (addDateToConvocation) {
      addDateToConvocation(new Date(value));
    }
  }

  return (
    <GlobalContainerDashboard>
      <FlagGroupError data={errors} dismissFlag={dissmissError}/>
      <ListWrapper>
        <PageHeaderList
          actionsContent={(
            <ActionsContentConvocation
              addConvocation={addConvocationWithCheckOnJurisdiction}
              isDisabled={isDisabledButton}
              isUpdate={!!id}
              returnOnAudience={returnOnAudience}
              updateConvocation={updateConvocation}
            />
          )}
          breadcrumbs={(
            <Breadcrumbs
              text="Convocation"
              key=""
              location="/convocation"
              fullName={null}
              from_id={null}
            />
          )}
          bottomBar={(
            <CopyPaste
              content="Copier : Prénom Nom - N° RG"
              onClick={() => Copy()}
            />
          )}
        />
      </ListWrapper>
      <AddConvocation>
        <ContainerTop>
          <AutocompleteCustom
            label="Juridiction"
            id="juridiction-selector"
            options={jurisdictions}
            value={selectedJurisdiction}
            onChange={selectJurisdiction}
            placeholder="Choisir une juridiction"
          />
          <TimePickerWithHour
            label="Date d'audience"
            onChange={(value) => handleChange(value)}
            dateDefault={datetime}
          />
          <AutocompleteCustom
            label="Type"
            id="juridiction-selector"
            options={datas.CONVOCATION_TYPE}
            value={type}
            onChange={(value) => addType(value)}
            placeholder="Choisir un type"
          />
        </ContainerTop>
        {jurists && (
          <AutocompleteCustom
            placeholder="Juriste"
            id="jurist-selector"
            options={options}
            value={selectedJurist}
            multiple
            onChange={(value) => addJurists(value)}
          />
        )}
        <SearchContainerConvocation>
          <SearchPassenger
            query={query}
            selectPassenger={selectPassenger}
            loading={false}
            data={data}
            list={selected}
          />
          <ListPassengersSelected
            list={selected}
            remove={remove}
            updatePassenger={updatePassenger}
          />
        </SearchContainerConvocation>
      </AddConvocation>
    </GlobalContainerDashboard>
  );
};

const SearchContainerConvocation = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContainerTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

const WrapperButtonListSearch = (props) => {
  const [isClicked, setIsClicked] = useState(false);

  const onClick = () => {
    props.onClick();
    setIsClicked(true);
  };
  return (
    <WrapperButton onClick={onClick} isClicked={isClicked}>
      {`${props.passenger.fullName()} - ${props.claim.ca_name_operated || 'Airline'} - ${props.rgNumber} - ${props.claim.jurisdiction}`}
    </WrapperButton>
  );
};

WrapperButtonListSearch.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
  passenger: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
  claim: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
  rgNumber: PropTypes.any,
};

const SearchPassenger = ({
                           loading = false, query, data, selectPassenger,
                         }) => {
  const [searchQuery, setSearchQuery] = React.useState('');

  const search = debounce((value) => {
    query(value);
  }, 200);

  function handleChange(value) {
    setSearchQuery(value);
    search(value);
  }

  function getRgNumber(passenger) {
    const rg = passenger.rg_number;
    if (Number(rg) === 0 || !rg) {
      return 'Non défini';
    }
    return rg;
  }

  function TransformData(passengers) {
    const reformated = [];
    for (const data in passengers) {
      const passenger = {
        claim: passengers[data],
      };
      for (const perso in passengers[data].passengers) {
        const withpassenger = {
          passenger: passengers[data].passengers[perso],
          claim: passenger.claim,
        };
        reformated.push(withpassenger);
      }
    }
    return reformated;
  }

  return (
    <SearchContainer>
      <Label label="Rechercher un passager"/>
      <SearchPassengersInput
        loading={loading}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        value={searchQuery}
      />
      <WrapperResult>
        {TransformData(data).map(({passenger, claim}) => (
          <WrapperButtonListSearch
            onClick={() => {
              selectPassenger({passenger, claim});
            }}
            key={passenger._id}
            passenger={passenger}
            claim={claim}
            rgNumber={getRgNumber(passenger)}
          />
        ))}
      </WrapperResult>
    </SearchContainer>
  );
};

const WrapperResult = styled(WrapperButtonGroup)`
  max-height: 300px;
  overflow: auto;
  background: #fff;

  button {
    background: #fff;

    &:hover {
      span {
        color: #0052CC !important;
      }
    }
  }
`;

const ListPassengersSelected = ({list, remove, updatePassenger}) => (
  <div>
    <Label label="Passagers séléctionnés"/>
    <ul style={{paddingLeft: '0'}}>
      {list && list.map(({passenger}, index) => (
        <li style={{listStyle: 'none'}} key={[`${passenger.fullName()}-${index}`]}>
          <Container>
            <Text color="secondary" variant="subtitle2">N° RG :</Text>
            <BootstrapInput
              placeholder="N° RG"
              onChange={(event) => updatePassenger(passenger._id, 'rg_number', event.target.value)}
              value={passenger.rg_number}
            />
            <Text color="secondary" variant="subtitle2">{passenger.fullName()}</Text>
            <ButtonDeleteContainer>
              <IconButtonCustom onClick={() => remove(passenger._id)}>
                <ClearIconCustom color="error" fontSize="small"/>
              </IconButtonCustom>
            </ButtonDeleteContainer>
          </Container>
        </li>
      ))}
    </ul>
  </div>
);

const WrapperButton = styled(Button)(({isClicked}) => ({
  background: isClicked ? '#e4e4e4 !important' : 'whitesmoke !important',
  width: '100% !important',
  opacity: '0.9 !important',
  fontSize: '14px !important',
  textAlign: 'left !important',
  lineHeight: 'inherit !important',
  textTransform: 'inherit !important',
  height: '30px !important',
  display: 'flex !important',
  alignItems: 'flex-start !important',
  justifyContent: 'flex-start !important',
  borderRadius: '3px !important',
  borderBottom: '1px solid white !important',
  marginBottom: '2px !important',
  color: 'rgb(66, 82, 110) !important',
  '&:hover': {
    color: '#0052CC !important',
  },
}));

const SearchContainer = styled.div`
  padding: 30px 0;

  .div-input-inside {
    width: 400px !important;
    margin: 0 0 30px;
    height: 40px;

    input {
      padding-left: 10px;
      font-size: 15px !important;

      &::placeholder {
        font-size: 15px !important;
      }
    }
  }
`;
const AddConvocation = styled.div`
  width: 97%;
  height: auto;
  padding: 1rem;
  background: #fafafa;

  .single-select {
    width: 300px;
    margin-right: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
  padding: 4px 4px;
  background: #f5f6f5;
  border-radius: 4px;
  position: relative;
  transition: all 0.3s;

  :hover {
    background: #e7e7e7;
  }
`;

const ButtonDeleteContainer = styled.div`
  position: absolute;
  right: 0;
`;

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 14,
    width: 100,
    padding: '3px 10px',
    margin: '0 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const IconButtonCustom = styled(IconButton)`
  padding: 3px !important;
`;

const ClearIconCustom = styled(ClearIcon)`
  opacity: 0.9 !important;
`;

export {
  ActionsContentConvocation,
  ConvocationContainer,
  WrapperButton,
};
