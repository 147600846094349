/*
 * Developed by quentinmalguy on 7/23/19 1:13 AM from Malguy Technology.
 * Last modified 7/23/19 1:12 AM.
 */

export const HANDLE_SUB_MENU = 'HANDLE_SUB_MENU';
export const NAVIGATE_IN_AUDIENCE = 'NAVIGATE_IN_AUDIENCE';
export const NAVIGATE_IN_AUDIENCES = 'NAVIGATE_IN_AUDIENCES';

export const handleSubMenu = () => ({
  type: HANDLE_SUB_MENU,
});

export const navigateInAudiences = (state) => ({
  type: NAVIGATE_IN_AUDIENCES,
  payload: state,
});

export const navigateInAudience = (state) => ({
  type: NAVIGATE_IN_AUDIENCE,
  payload: state,
});
