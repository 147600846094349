import {
  ContainerHeader, HeaderSection, Item, MenuSection,
} from '@atlaskit/navigation-next';
import React, { Fragment } from 'react';
import EditorAlignLeftIcon from '@atlaskit/icon/glyph/editor/align-left';
import ArrowLeftCircleIcon from '@atlaskit/icon/glyph/arrow-left-circle';
import PeopleIcon from '@atlaskit/icon/glyph/people';
import DocumentsIcon from '@atlaskit/icon/glyph/documents';
import styled from 'styled-components';
import { RedirectToPreviousList } from '../../redirect/redirect';
import { AvatarDefault } from '../../../../common/avatar/avatar';

export const MyContainerDossiersNavigation = ({
  dossier, push, navigateInCase, state, search,
}) => (
  <>
    <HeaderSection>
      {({ css }) => (
        <div style={{ ...css, paddingBottom: 20 }}>
          <ContainerHeader
            text={dossier.fullNameMainPassenger()}
            before={() => (
              <AvatarDefault
                name="medium"
                size="medium"
              />
            )}
            subText={`Dossier n° ${dossier.from_id}`}
          />
          <RedirectToPreviousList
            text="quitter le dossier"
            push={push}
            search={search}
            before={() => (
              <ArrowLeftCircleIcon primary="#0747A6" />
            )}
          />
        </div>
      )}
    </HeaderSection>
    <MenuSection>
      {({ className }) => (
        <div className={className}>
          <Item
            before={() => (<EditorAlignLeftIcon />)}
            onClick={() => navigateInCase('INFO')}
            isSelected={state === 'INFO'}
            text="Informations"
          />
          <Item
            before={() => (<PeopleIcon />)}
            isSelected={state === 'PASSENGERS'}
            onClick={() => navigateInCase('PASSENGERS')}
            text="Passagers"
            after={() => (<NotificationWrapper>{dossier.passengers.length}</NotificationWrapper>)}
          />
          <Item
            before={() => (<DocumentsIcon />)}
            onClick={() => navigateInCase('DOCS')}
            isSelected={state === 'DOCS'}
            text="Documents"
          />
        </div>
      )}
    </MenuSection>
  </>
);

const NotificationWrapper = styled.span`
height: 20px;
width: 20px;
background-color: pink;
color: #000;
border-radius: 10px;
display: flex;
align-items: center;
justify-content: center;
`;
