import React, { useEffect, useState } from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import Select from '@atlaskit/select';
import { Field } from '@atlaskit/form';
import styled from 'styled-components';
import * as qs from 'query-string';
import copy from 'copy-to-clipboard';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { createHeadTable, createRowsTable } from './tableutils';
import { ActionsContentCaseList } from './header';
import BarContentFilters from '../filterCaseList';
import { PageHeaderList } from '../../../../common/pageheaderlist/pageheaderlist';
import { GlobalContainerDashboard } from '../../../../common/layout/ContainerDashboard';
import { FlagGroupError } from '../../../../common/error/error';
import { ListWrapper } from '../../../../common/layout/wrapper';
import { Breadcrumbs } from '../../../../common/breadcrumbs/breadcrumbs';
import { ButtonCustom } from '../../../../common/button/button';

const ListCasesContainer = ({
  retrieveCaseList,
  search,
  initList,
  updateStatusList,
  list,
  dismissError,
  loading,
  selectElemInList,
  checkAllCase,
  errors,
  listSize,
  push,
  pathname,
  addAirhelpCompensationFinal
}) => {
  useEffect(() => {
    initList();
  }, []);

  useEffect(() => {
    const query = qs.parse(search);
    const fresh = { ...query };
    if ('paginateSize' in fresh) {
      retrieveCaseList(search);
    }
  }, [search]);

  const updateStatus = (status) => {
    updateStatusList(status, list);
  };

  const dismissFlag = () => {
    dismissError();
  };

  const copyToClipboard = () => {
    const clipboard = list.map((e) => e.fullNameMainPassenger()).sort().map((e) => `Dossier ${e}\n`).join('');
    copy(clipboard);
  };

  const copyToClipboardSaisineAll = () => {
    const clipboard = list.map((e) => `${e.fullNameMainPassenger()} - ${e.ca_name_operated}`).sort().map((e) => `Dossier ${e}\n`).join('');
    copy(clipboard);
  };

  const copyToClipboardCloseByAh = () => {
    const clipboard = list.map((e) => `${e.fullNameMainPassenger()} - ${e.from_id}`).sort().map((e) => `Dossier ${e}\n`).join('');
    copy(clipboard);
  };

  const copyToClipboardDocsExcel = () => {
    const clipboard = list.map((e) => `${e.fullNameMainPassenger()};${e.booking_ref};${e.flight_number};${new Date(e.flight_date).toLocaleString('fr-FR')}`).sort().map((e) => `${e}\n`).join('');
    copy(clipboard);
  };

  const actions = [
    {
      content: 'Fermer',
      onClick: dismissFlag,
    },
  ];

  const addFinalCompensation = (list) => {
    const airhelpIds = list.map(claim => claim.from_id)
    addAirhelpCompensationFinal(airhelpIds)
  }

  if (!list) return null;

  return (
    <GlobalContainerDashboard>
      <FlagGroupError
        errors={errors}
        actions={actions}
        name="updateStatus"
        dismissFlag={dismissFlag}
        appearance="error"
      />
      <ListWrapper>
        <PageHeaderList
          actionsContent={ActionsContentCaseList(updateStatus, list, search, addFinalCompensation)}
          breadcrumbs={<Breadcrumbs text="Dossiers" key="DossiersKey" location="/dossiers?paginateSize=50" />}
          bottomBar={(
            <BarContentFilters
              initList={initList}
              list={list}
              jurisdiction
              status
              airline
              start
              end
              sla
              listSize={listSize}
              start_status
              end_status
              log_status
              claimant_country
              med30
              source
              copyPaste
              copyPasteFn={copyToClipboard}
              copyToClipboardSaisineAll={copyToClipboardSaisineAll}
              copyToClipboardCloseByAh={copyToClipboardCloseByAh}
              contentCopyPaste="Dossier Nom + Prénom"
              contentCopyPasteSaisineAll="Dossier Nom + Prénom - CA"
              contentCopyPasteClosedByAH="Dossier Nom + Prénom - AH ID"
              copyToClipboardDocsExcel={copyToClipboardDocsExcel}
              contentCopyToClipboardDocsExcel="Dossier Prenom + Nom - Booking ref - Numero de vol - Date de vol"
            />
          )}
          title="Dossiers"
        />
      </ListWrapper>
      <ListWrapper>
        <DynamicTable
          head={createHeadTable(true, checkAllCase)}
          rows={createRowsTable(list, selectElemInList, search)}
          loadingSpinnerSize="large"
          isLoading={loading}
          isFixedSize
          paginationi18n={{
            prev: 'Précédent',
            next: 'Suivant',
          }}
        />
        <LoadMore list={list} listSize={listSize} search={search} push={push} pathname={pathname} />
      </ListWrapper>
    </GlobalContainerDashboard>
  );
};

const LoadMore = ({ ...props }) => {
  const {
    search, push, pathname, list, listSize,
  } = props;

  function createValidQueryString(pathname, freshSearch) {
    return `${pathname}?${qs.stringify(freshSearch)}`;
  }

  function load() {
    const query = qs.parse(search);
    if ('page' in query) {
      query.page = parseInt(query.page, 10) + 1;
    } else {
      query.page = 1;
    }
    push(createValidQueryString(pathname, query));
  }

  return (
    <ListWrapper>
      <ButtonContainer>
        <ButtonCustom
          background="blue"
          onClick={() => load()}
          disabled={list.length >= listSize}
          text="Voir plus"
          endIcon={<VisibilityIcon />}
        />
      </ButtonContainer>
    </ListWrapper>
  );
};

const PageSize = ({
  pushInUrl, search, pathname, initList, list,
}) => {
  const [pageSize, setPageSize] = useState({ label: 0, value: 0 });

  function handlePageSizeUrl(elem) {
    if (elem.value === pageSize.value) return;
    setPageSize(elem);
    const query = qs.parse(search);
    const fresh = { ...query };
    fresh.paginateSize = elem.value;
    delete fresh.page;
    const querystring = `${pathname}?${qs.stringify(fresh)}`;
    initList();
    pushInUrl(querystring);
  }

  useEffect(() => {
    const query = qs.parse(search);
    const fresh = { ...query };
    if (!fresh.hasOwnProperty('paginateSize')) {
      fresh.paginateSize = 50;
      handlePageSizeUrl({ label: fresh.paginateSize, value: fresh.paginateSize });
    }
    if (fresh.paginateSize > pageSize.value) {
      handlePageSizeUrl({ label: fresh.paginateSize, value: fresh.paginateSize });
    }
  }, []);

  return (
    <div>
      {list && (
        <Field
          id="size"
          name="list-size"
          defaultValue={pageSize}
        >
          {({ fieldProps }) => (
            <Select
              {...fieldProps}
              options={[
                { label: '50', value: 50 },
                { label: '100', value: 100 },
                { label: '150', value: 150 },
                { label: '200', value: 200 },
                { label: '250', value: 250 },
                { label: '300', value: 300 },
              ]}
              value={pageSize}
              onChange={(e) => handlePageSizeUrl(e, setPageSize, pushInUrl, search, pathname)}
            />
          )}
        </Field>
      )}
    </div>
  );
};

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

export {
  ListCasesContainer,
  LoadMore,
  PageSize,
};