import React from 'react';
import Select from '@atlaskit/select';
import { DatePicker } from '@atlaskit/datetime-picker';
import * as qs from 'query-string';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { PageSize } from '../listcases/listcasescontainer';
import { CopyPaste } from '../../../../common/copypaste/copypaste';
import { retrieveStatusOption } from '../listcases/tableutils';
import { ButtonCustom } from '../../../../common/button/button';
import { Lozenge } from '../../../../common/lozenge/Lozenge';
import {
  MyLayoutFilters, WrapperFilters, WrapperInfoCaseList, WrapperPaginationSize,
} from '../../../../common/layout/LayoutFilters';

const { data } = require('./dataFilters');

function FillFilters(search, activeFilters) {
  const params = qs.parse(search);
  let filters = { ...activeFilters };
  for (const i in params) {
    if (i === 'status') {
      const current = typeof params[i] === 'string' ? [retrieveStatusOption(Number(params[i]))] : params[i].map((status) => retrieveStatusOption(Number(status)));
      filters = {
        ...filters, status: [...activeFilters.status, ...current],
      };
    }
    if (i === 'oag') {
      const current = params[i];
      filters = {
        ...filters, oag: [current],
      };
    }
    if (i === 'source') {
      const current = params[i];
      filters = {
        ...filters, source: [Number(current)],
      };
    }
    if (i === 'med30') {
      const current = params[i];
      filters = {
        ...filters, med30: [current],
      };
    }
    if (i === 'sla') {
      const current = data.SLA[params[i]];
      filters = {
        ...filters, sla: [...activeFilters.sla, current],
      };
    }
    if (i === 'log_status') {
      const current = retrieveStatusOption(params[i]);
      filters = {
        ...filters, log_status: [...activeFilters.log_status, current],
      };
    }
    if (i === 'end') {
      const current = params[i];
      filters = {
        ...filters, end: current,
      };
    }
    if (i === 'start') {
      const current = params[i];
      filters = {
        ...filters, start: current,
      };
    }
    if (i === 'ca_name_operated') {
      let current = []
      if (Array.isArray(params[i])) {
        current = params[i].map(p => ({
          label: p, value: p
        }))
      } else {
        current = [{
          label: params[i],
          value: params[i]
        }]
      }


      filters = {
        ...filters,
        ca_name_operated: [...activeFilters.ca_name_operated, ...current],
      };
    }
    if (i === 'claimant_country') {
      const current = params[i];
      filters = {
        ...filters, claimant_country: [current],
      };
    }
    if (i === 'ocr_confidence') {
      const current = params[i];
      filters = {
        ...filters, ocr_confidence: current,
      };
    }
    if (i === 'jurisdiction') {
      const current = params[i];
      filters = {
        ...filters, jurisdiction: current,
      };
    }
    if (i === 'cancelling') {
      const current = data.DESISTEMENT[params[i]];
      filters = {
        ...filters, cancelling: [current],
      };
    }
    if (i === 'cancelling') {
      const current = data.DESISTEMENT[params[i]];
      filters = {
        ...filters, cancelling: [current],
      };
    }
    if (i === 'selected_lawyer') {
      const current = data.AVOCAT.find((e) => e.value === params[i]);
      filters = {
        ...filters, selected_lawyer: [current],
      };
    }
    if (i === 'start_status') {
      const current = params[i];
      filters = {
        ...filters, start_status: current,
      };
    }
    if (i === 'end_status') {
      const current = params[i];
      filters = {
        ...filters, end_status: current,
      };
    }
    if (i === 'passengers_number') {
      const current = data.PASSENGERS_NUMBER[params.passengers_number - 1];
      filters = {
        ...filters, passengers_number: [current],
      };
    }
  }

  return filters;
}

export class BarContentFiltersContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFilters: {
        sla: [],
        status: [],
        oag: [],
        start: [],
        end: [],
        ca_name_operated: [],
        claimant_country: [],
        ocr_confidence: [],
        jurisdiction: [],
        cancelling: [],
        start_status: [],
        end_status: [],
        log_status: [],
        selected_lawyer: [],
        passengers_number: [],
        med30: [],
        source: [],
      }, defaultPageSize: 50,
    };
  }

  componentDidMount() {
    const { retrieve_operated_airlines, retrieve_jurisdictions, search } = this.props;
    retrieve_operated_airlines();
    retrieve_jurisdictions();
    const { activeFilters } = this.state;
    const params = FillFilters(search, activeFilters);
    this.setState({ activeFilters: params });
  }

  // eslint-disable-next-line react/sort-comp
  render() {
    const { activeFilters = null, defaultPageSize } = this.state;
    const {
      jurisdiction,
      status,
      oag,
      airline,
      start,
      end,
      sla,
      operating_airlines,
      jurisdictions,
      listSize,
      push,
      pathname,
      search,
      initList,
      cancelling,
      selected_lawyer,
      list,
      start_status,
      end_status,
      log_status,
      copyPaste,
      copyPasteFn,
      contentCopyPaste,
      noInitFilters = false,
      passengers_number,
      copyToClipboardSaisineAll,
      contentCopyPasteSaisineAll,
      copyToClipboardCloseByAh,
      contentCopyPasteClosedByAH,
      contentCopyToClipboardDocsExcel,
      copyToClipboardDocsExcel,
      claimant_country,
      ocr_confidence,
      med30,
      source
    } = this.props;

    return (<div>
      <MyLayoutFilters style={{ display: 'flex' }}>
        <WrapperFilters style={{ minWidth: '200px' }}>
          {status && (<SelectFilter
            isMulti
            style={{ minWidth: '200px' }}
            placeholder="Choisir un status"
            type="STATUS"
            value={this.state.activeFilters.status}
            handleChange={(event) => {
              if (!event) {
                activeFilters.status = [];
              } else {
                activeFilters.status = [...event];
              }
              this.retrieveFilteredData(activeFilters);
            }}
          />)}
          {oag && (<SelectFilter
            isMulti={false}
            placeholder="OAG"
            type="OAG"
            value={this.renderCorrectValueForAog()}
            handleChange={(event) => {
              if (!event) {
                activeFilters.oag = [];
                return this.retrieveFilteredData(activeFilters);
              }
              const { value } = event;
              activeFilters.oag = [value];
              this.retrieveFilteredData(activeFilters);
            }}
          />)}
          {log_status && (<SelectFilter
            style={{ minWidth: '200px' }}
            isMulti
            placeholder="Filtrer un log"
            type="STATUS"
            value={this.state.activeFilters.log_status}
            handleChange={(event) => {
              if (!event) {
                activeFilters.log_status = [];
              } else {
                activeFilters.log_status = [...event];
              }
              this.retrieveFilteredData(activeFilters);
            }}
          />)}
          {sla && (<SelectFilter
            isMulti={false}
            placeholder="Choisir un état"
            type="SLA"
            value={this.state.activeFilters.sla}
            handleChange={(event) => {
              if (!event) {
                activeFilters.sla = [];
                return this.retrieveFilteredData(activeFilters);
              }
              const { value } = event;
              activeFilters.sla = [data.SLA[value]];
              this.retrieveFilteredData(activeFilters);
            }}
          />)}
          {start && (<DatePicker
            value={this.state.activeFilters.start.length > 0 ? this.state.activeFilters.start : ''}
            selectProps={{
              placeholder: 'Date de début',
            }}
            onChange={(value) => {
              if (value === '') {
                activeFilters.start = [];
                return this.retrieveFilteredData(activeFilters);
              }
              activeFilters.start = value;
              return this.retrieveFilteredData(activeFilters);
            }}
            id="datepicker-filter-1"
            locale="fr-FR"
            testId="datePicker-datepicker-filter-1"
          />)}
          {end && (<DatePicker
            selectProps={{
              placeholder: 'Date de fin',
            }}
            value={this.state.activeFilters.end.length > 0 ? this.state.activeFilters.end : ''}
            onChange={(value) => {
              if (value === '') {
                activeFilters.end = [];
                return this.retrieveFilteredData(activeFilters);
              }
              activeFilters.end = value;
              return this.retrieveFilteredData(activeFilters);
            }}
            id="datepicker-filter-2"
            locale="fr-FR"
            testId="datePicker-datepicker-filter-2"
          />)}
          {airline && operating_airlines && (<SelectFilter
            isMulti
            placeholder="Choisir une CA"
            type="OPERATING_AIRLINES"
            value={this.state.activeFilters.ca_name_operated}
            operating_airlines={operating_airlines}
            handleChange={(event) => {
              if (!event) {
                activeFilters.ca_name_operated = [];
              } else {
                activeFilters.ca_name_operated = [...event];
              }
              this.retrieveFilteredData(activeFilters);
            }}
          />)}
          {jurisdiction && jurisdictions && (<SelectFilter
            isMulti={false}
            placeholder="Choisir une juridiction"
            type="JURISDICTIONS"
            value={this.renderCorrectValueForJurisdisdiction()}
            jurisdictions={jurisdictions}
            handleChange={(event) => {
              if (!event) {
                activeFilters.jurisdiction = [];
                return this.retrieveFilteredData(activeFilters);
              }
              const { value } = event;
              activeFilters.jurisdiction = [value];
              this.retrieveFilteredData(activeFilters);
            }}
          />)}
          {cancelling && (<SelectFilter
            isMulti={false}
            placeholder="Choisir un désistement"
            type="DESISTEMENT"
            value={this.state.activeFilters.cancelling}
            handleChange={(event) => {
              if (!event) {
                activeFilters.cancelling = [];
                return this.retrieveFilteredData(activeFilters);
              }
              const { value } = event;
              activeFilters.cancelling = [data.DESISTEMENT[value]];
              this.retrieveFilteredData(activeFilters);
            }}
          />)}
          {selected_lawyer && (<SelectFilter
            style={{ minWidth: '200px' }}
            isMulti={false}
            placeholder="Choisir un avocat"
            type="AVOCAT"
            value={this.state.activeFilters.selected_lawyer}
            handleChange={(event) => {
              if (!event) {
                activeFilters.selected_lawyer = [];
                return this.retrieveFilteredData(activeFilters);
              }
              const { index } = event;
              activeFilters.selected_lawyer = [data.AVOCAT[index]];
              this.retrieveFilteredData(activeFilters);
            }}
          />)}
          {start_status && (<DatePicker
            selectProps={{
              placeholder: 'Date de début de passage à un status',
            }}
            value={this.state.activeFilters.start_status.length > 0 ? this.state.activeFilters.start_status : ''}
            onChange={(value) => {
              if (value === '') {
                activeFilters.start_status = [];
                return this.retrieveFilteredData(activeFilters);
              }
              activeFilters.start_status = value;
              return this.retrieveFilteredData(activeFilters);
            }}
            id="datepicker-filter-3"
            locale="fr-FR"
            testId="datePicker-datepicker-filter-3"
          />)}
          {end_status && (<DatePicker
            selectProps={{
              placeholder: 'Date de fin de passage à un status',
            }}
            value={this.state.activeFilters.end_status.length > 0 ? this.state.activeFilters.end_status : ''}
            onChange={(value) => {
              if (value === '') {
                activeFilters.end_status = [];
                return this.retrieveFilteredData(activeFilters);
              }
              activeFilters.end_status = value;
              return this.retrieveFilteredData(activeFilters);
            }}
            id="datepicker-filter-4"
            locale="fr-FR"
            testId="datePicker-datepicker-filter-4"
          />)}
          {passengers_number && (<SelectFilter
            style={{ minWidth: '200px' }}
            isMulti={false}
            placeholder="Nombre de passager"
            type="PASSENGERS_NUMBER"
            value={this.state.activeFilters.passengers_number}
            handleChange={(event) => {
              if (!event) {
                activeFilters.passengers_number = [];
                return this.retrieveFilteredData(activeFilters);
              }
              const { value } = event;
              activeFilters.passengers_number = [data.PASSENGERS_NUMBER[value - 1]];
              this.retrieveFilteredData(activeFilters);
            }}
          />)}
          {claimant_country && (<SelectFilter
            isMulti={false}
            placeholder="Choisir un pays"
            type="CLAIMANT_COUNTRY"
            value={this.renderCorrectValueForClaimantCountry()}
            handleChange={(event) => {
              if (!event) {
                activeFilters.claimant_country = [];
                return this.retrieveFilteredData(activeFilters);
              }
              const { value } = event;
              activeFilters.claimant_country = [value];
              this.retrieveFilteredData(activeFilters);
            }}
          />)}
          {ocr_confidence && (<SelectFilter
            isMulti={false}
            placeholder="OCR"
            type="OCR"
            value={this.renderCorrectValueForOcrConfidence()}
            handleChange={(event) => {
              if (!event) {
                activeFilters.ocr_confidence = [];
                return this.retrieveFilteredData(activeFilters);
              }
              const { value } = event;
              activeFilters.ocr_confidence = [value];
              this.retrieveFilteredData(activeFilters);
            }}
          />)}
          {med30 && (<SelectFilter
            isMulti={false}
            placeholder="Med30"
            type="MED_30"
            value={this.renderCorrectValueForMed30()}
            handleChange={(event) => {
              if (!event) {
                activeFilters.med30 = [];
                return this.retrieveFilteredData(activeFilters);
              }
              const { value } = event;
              activeFilters.med30 = [value];
              this.retrieveFilteredData(activeFilters);
            }}
          />)}
          {source && (<SelectFilter
            isMulti={false}
            placeholder="Source"
            type="SOURCE"
            value={this.renderCorrectValueForSource(this.state.activeFilters.source)}
            handleChange={(event) => {
              if (!event) {
                activeFilters.source = [];
                return this.retrieveFilteredData(activeFilters);
              }
              const { value } = event;
              activeFilters.source = [value];
              this.retrieveFilteredData(activeFilters);
            }}
          />)
          }
        </WrapperFilters>
      </MyLayoutFilters>
      <WrapperInfoCaseList>
        {listSize > 0 && <Lozenge blue text={`Nb éléments: ${listSize}`} />}
        {copyPaste && copyPasteFn && <CopyPaste content={contentCopyPaste} onClick={() => copyPasteFn()} />}
        {copyToClipboardSaisineAll && (<CopyPaste
          content={contentCopyPasteSaisineAll}
          onClick={() => copyToClipboardSaisineAll()}
        />)}
        {copyToClipboardCloseByAh && (<CopyPaste
          content={contentCopyPasteClosedByAH}
          onClick={() => copyToClipboardCloseByAh()}
        />)}
        {copyToClipboardDocsExcel && (<CopyPaste
          content={contentCopyToClipboardDocsExcel}
          onClick={() => copyToClipboardDocsExcel()}
        />)}
        {!noInitFilters && (<ButtonCustom
          background="orange"
          startIcon={<FilterListOffIcon />}
          text="Réinitialiser les filtres"
          onClick={() => this.resetFilters()}
        />)}
        <WrapperPaginationSize>
          <PageSize
            pushInUrl={push}
            pathname={pathname}
            search={search}
            initList={initList}
            defaultPageSize={defaultPageSize}
            list={list}
          />
        </WrapperPaginationSize>
      </WrapperInfoCaseList>
    </div>);
  }

  resetFilters() {
    this.setState({
      activeFilters: {
        sla: [],
        status: [],
        oag: [],
        ocr_confidence: [],
        start: [],
        end: [],
        ca_name_operated: [],
        claimant_country: [],
        jurisdiction: [],
        cancelling: [],
        start_status: [],
        end_status: [],
        log_status: [],
        selected_lawyer: [],
        passengers_number: [],
        med30: [],
        source: [],
      },
    }, () => {
      this.retrieveFilteredData(this.state.activeFilters, true);
    });
  }

  renderCorrectValueForJurisdisdiction() {
    return this.state.activeFilters.jurisdiction[0] ? {
      label: this.state.activeFilters.jurisdiction, value: this.state.activeFilters.jurisdiction,
    } : [];
  }

  retrieveFilteredData(activeFilters, resetList = true) {
    this.setState({ activeFilters }, () => {
      const {
        pathname, push, search, initList,
      } = this.props;
      if (resetList) {
        initList();
      }

      const query = qs.parse(search);
      const fresh = { ...query };

      delete fresh.page;

      const value = {
        ...fresh,
        status: this.state.activeFilters.status.map((e) => e.value),
        sla: this.state.activeFilters.sla.map((e) => e.value),
        ca_name_operated: this.state.activeFilters.ca_name_operated.map((e) => e.value),
        claimant_country: this.state.activeFilters.claimant_country,
        oag: this.state.activeFilters.oag,
        ocr_confidence: this.state.activeFilters.ocr_confidence,
        jurisdiction: this.state.activeFilters.jurisdiction,
        start: this.state.activeFilters.start,
        end: this.state.activeFilters.end,
        cancelling: this.state.activeFilters.cancelling.map((e) => e.value),
        selected_lawyer: this.state.activeFilters.selected_lawyer.map((e) => e.value),
        start_status: this.state.activeFilters.start_status,
        end_status: this.state.activeFilters.end_status,
        log_status: this.state.activeFilters.log_status.map((e) => e.value),
        passengers_number: this.state.activeFilters.passengers_number.map((e) => e.value),
        med30: this.state.activeFilters.med30,
        source: this.state.activeFilters.source,
      };
      const searchString = `${pathname}?${qs.stringify(value)}`;
      push(searchString);
    });
  }

  renderCorrectValueForClaimantCountry() {
    return this.state.activeFilters.claimant_country[0] ? {
      label: data.CLAIMANT_COUNTRY.find((e) => e.value === this.state.activeFilters.claimant_country[0])?.label,
      value: this.state.activeFilters.claimant_country,
    } : [];
  }

  renderCorrectValueForMed30() {
    return this.state.activeFilters.med30[0] ? {
      label: data.MED_30.find((e) => e.value === this.state.activeFilters.med30[0])?.label,
      value: this.state.activeFilters.med30,
    } : [];
  }

  renderCorrectValueForSource() {
    return this.state.activeFilters.source.length > 0 ? {
      label: data.SOURCE.find((e) => e.value === this.state.activeFilters.source[0])?.label,
      value: this.state.activeFilters.source,
    } : [];
  }

  renderCorrectValueForAog() {
    return this.state.activeFilters.oag[0] ? {
      label: data.OAG.find((e) => e.value === this.state.activeFilters.oag[0])?.label,
      value: this.state.activeFilters.oag,
    } : [];
  }

  renderCorrectValueForOcrConfidence() {
    return this.state.activeFilters.ocr_confidence[0] ? {
      label: data.OCR.find((e) => e.value === this.state.activeFilters.ocr_confidence[0])?.label,
      value: this.state.activeFilters.ocr_confidence,
    } : [];
  }

  renderCorrectValueForAirlines() {
    return this.state.activeFilters.ca_name_operated.map(e => ({
      label: e,
      value: e
    })) || [];
  }
}

const SelectFilter = ({
  placeholder, isMulti, type, handleChange, operating_airlines, jurisdictions, value,
}) => {
  // eslint-disable-next-line global-require
  const { data } = require('./dataFilters');

  const retrieveOptions = () => {
    if (type === 'OPERATING_AIRLINES') {
      return operating_airlines;
    }
    if (type === 'JURISDICTIONS') {
      return jurisdictions;
    }
    return data[type];
  };

  return (<Select
    isClearable
    spacing="compact"
    placeholder={placeholder}
    aria-label={placeholder}
    isMulti={isMulti}
    isSearchable
    onChange={handleChange}
    value={value}
    options={retrieveOptions()}
  />);
};
