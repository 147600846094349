import React from 'react';
import * as qs from 'query-string';
import { ButtonActionGroup } from './buttonactiongroup';
import { data } from '../filterCaseList/dataFilters';
import { ButtonGenerateExcel } from './generateExcel';
import { ButtonGenerateExcelRib } from './generateExcelRIb';
import { ButtonGenerateExcelJuridiction } from './generateExcelJuridiction';
import { ButtonGenerateExcelAccounting } from "./generateEcxelAccounting";
import { ButtonCustom } from "../../../../common/button/button";
import BalanceIcon from '@mui/icons-material/Balance';

const ActionsContentCaseList = (update, list, search, addFinalCompensation) => {
  const listSelected = list.filter((p) => p.selected);
  const airlineSelectInFilter = qs.parse(search).ca_name_operated;
  const juridictionSelectInFilter = qs.parse(search).jurisdiction;
  const status = qs.parse(search).status;

  return (
    <>
      {list && <ButtonCustom onClick={() => addFinalCompensation(listSelected)} disabled={listSelected.length === 0}
                             text="Compta" startIcon={<BalanceIcon/>}/>}
      {list && <ButtonGenerateExcelAccounting list={listSelected} status={status}/>}
      {list && <ButtonGenerateExcelJuridiction list={listSelected} Juridiction={juridictionSelectInFilter}/>}
      {list && <ButtonGenerateExcel list={listSelected} airline={airlineSelectInFilter}/>}
      {list && <ButtonGenerateExcelRib list={listSelected} airline={airlineSelectInFilter}/>}
      {list && <ButtonActionGroup disabled={listSelected.length === 0} update={update} data={data} type="STATUS"
                                  title="Changer statut"/>}
    </>
  );
};

export {
  ActionsContentCaseList,
};
