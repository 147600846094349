import React, { useState } from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';
import { Alert, Snackbar } from '@mui/material';
import { GlobalWrapper } from '../common/GlobalWrapper';
import { PageHeaderList } from '../../../../../common/pageheaderlist/pageheaderlist';
import { createHeadTableDocument, createRowsTableDocument } from '../tableutils';
import { ActionsContentCaseDocumentList } from './header/header';
import { Breadcrumbs } from '../../../../../common/breadcrumbs/breadcrumbs';
import { CopyPaste } from '../../../../../common/copypaste/copypaste';

const PDFViewer = ({ url }) => (
  <embed src={url} width="100%" height="800" type="application/pdf" />
);

const DocummentsContainer = ({
  folder,
  retrieveCase,
  search,
  selectDocuments,
  selectAllDocs,
  generateDocuments,
  removeSelectedDocuments,
  documentLoading,
}) => {
  const [snackMessage, setSnackMessage] = useState(null);
  const [fileLoader, setFileLoader] = useState(false)
  const handleChange = (event) => {
    const { files, name } = event.target;
    const formData = new FormData();
    for (const file in files) {
      if (files.hasOwnProperty(file)) {
        formData.append(`${folder._id}`, files[file]);
      }
    }

    // eslint-disable-next-line no-undef
    fetch(`${process.env.REACT_APP_API_URL}/api/upload/uploadDocument?type=${name}`, {
      method: 'POST',
      body: formData,
      headers: {
        'x-access-token': localStorage.getItem('admin-token'),
        'Accept-Encoding': 'gzip, deflate',
      },
    }).then(async (response) => {
      if (response) {
        retrieveCase(search);
        setSnackMessage({ text: `Le fichier ${files[0].name} a bien été importé !`, type: 'success' });
        setFileLoader(false)
      }
    }).catch((error) => {
      setSnackMessage({ text: error.message, type: 'error' });
    });
  };

  const copyToClipboard = () => {
    const passenger = folder.passengers.find((e) => e.principal);
    const clipboard = `Dossier ${passenger.fullName().toUpperCase()} c./ ${folder.airline.display_name.toUpperCase()}`;
    copy(clipboard);
  };

  if (!folder) return null;
  const pdf = folder.documents.find((d) => d.type === 'pdf') || null;

  const head = createHeadTableDocument(true, selectAllDocs);
  const rows = createRowsTableDocument(folder.documents, selectDocuments);



  return (
    <GlobalWrapper>
      <ListWrapper>
        <PageHeaderList
          actionsContent={(
            <ActionsContentCaseDocumentList
              fileLoader={fileLoader}
              setFileLoader={setFileLoader}
              documentLoading={documentLoading}
              retrieveFiles={handleChange}
              generateDocuments={generateDocuments}
              folder={folder}
              removeSelectedDocuments={removeSelectedDocuments}
              removeIsDisabled={!folder.documents.find((e) => e.selected)}
            />
          )}
          breadcrumbs={(
            <Breadcrumbs
              text="Dossiers"
              key="DossiersKey"
              location="/dossiers"
              fullName={folder.fullNameMainPassenger()}
              from_id={folder.from_id}
            />
          )}
          bottomBar={(
            <CopyPaste
              content="Copier : Nom du passager principal c./ Nom de la compagnie aérienne"
              onClick={() => copyToClipboard()}
            />
          )}
        />
      </ListWrapper>
      <ListWrapper>
        <DynamicTableStateless
          isLoading={documentLoading}
          head={head}
          rows={rows}
          loadingSpinnerSize="large"
          isFixedSize
          sortOrder="DESC"
        />
        {pdf && pdf.location && <PDFViewer url={pdf.location} />}
      </ListWrapper>
      {snackMessage && (
        <Snackbar
          open
          onClose={() => {
            setSnackMessage(null);
          }}
          autoHideDuration={6000}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Alert
            variant="filled"
            severity={snackMessage.type}
          >
            {snackMessage.text}
          </Alert>
        </Snackbar>
      )}
    </GlobalWrapper>
  );
};

const ListWrapper = styled.div`
  min-width: 600px;
  width: 100%;
  padding: 0;
`;

export {
  DocummentsContainer,
};
